export const environment = {
    serverURL: 'https://artiox.com/',
    serverAPI: 'artiox_api/',
    serverAdminURL: 'https://artiox.com/',
    serverAdminAPI: 'artiox_api/',
    socketURL: 'https://artiox.com/',
    socketAPI: '/socket.io/',
    assetsURL: 'https://artiox.com/',
    assetsAPI: 'assets/',
    version: '2.1.0',
    production: true,
    test: false,
    proxy: false,
    app: false,
    // platform: 'web',
};
