<div *ngIf="!managerService.environment.app | managerPipe" class="d-flex flex-column w-100 h-100" style="min-height: 420px;">
  <div class="w-100 d-flex flex-column" style=" background-color: #fcfdff; box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5); margin-bottom: 4px;">

    <div class="mt-4 mb-2 ml-3"
         style="font-family: Roboto, sans-serif;font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
      <span>
        <svg style="height: 24px; width: 24px; margin-top: -4px;">
          <use [attr.xlink:href]='"./assets/images/security.svg#security"' fill="#272a3b"/>
        </svg>
      </span>
      {{managerService.config.SECURITY.HEADING_MOBILE | managerPipe}}
    </div>

    <div>
      <button (click)="managerService.active_security_panel = managerService.active_security_panel_template.CHANGE_PASSWORD"
              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD"
              class="btn-native btn-account-navigator" style="width: 33%; font-size: 13px;  letter-spacing: normal;"
              type="button">
        <div [innerHTML]="managerService.config.SECURITY.CHANGE_PASSWORD.HEADING_MOBILE | managerPipe"></div>
        <div [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD)" class="mx-auto"
             style="width: 90px; border-bottom: 1px solid var(--artiox-blue); margin-top: 2px"></div>
      </button>
      <button (click)="managerService.active_security_panel = managerService.active_security_panel_template.TWO_FACTOR_AUTHENTICATOR"
              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR"
              class="btn-native btn-account-navigator" style="width: 33%; font-size: 13px;  letter-spacing: normal;"
              type="button">
        <div [innerHTML]="managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.HEADING_MOBILE | managerPipe"></div>
        <div [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR)" class="mx-auto"
             style="width: 90px; border-bottom: 1px solid var(--artiox-blue); margin-top: 2px"></div>
      </button>
<!--      <button (click)="managerService.active_security_panel = managerService.active_security_panel_template.IDENTITY_VERIFICATION"-->
<!--              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION"-->
<!--              class="btn-native btn-account-navigator" style="width: 33%; font-size: 13px;  letter-spacing: normal;"-->
<!--              type="button">-->
<!--        <div [innerHTML]="managerService.config.SECURITY.IDENTITY_VERIFICATION.HEADING_MOBILE | managerPipe"></div>-->
<!--        <div [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION)" class="mx-auto"-->
<!--             style="width: 90px; border-bottom: 1px solid var(&#45;&#45;artiox-blue); margin-top: 2px"></div>-->
<!--      </button>-->
      <button (click)="managerService.navigationManager({url: '/download-app', current_component_index: 0})"
              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION"
              class="btn-native btn-account-navigator" style="width: 33%; font-size: 13px;  letter-spacing: normal;"
              type="button">
        <div [innerHTML]="managerService.config.SECURITY.IDENTITY_VERIFICATION.HEADING_MOBILE | managerPipe"></div>
        <div [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION)" class="mx-auto"
             style="width: 90px; border-bottom: 1px solid var(--artiox-blue); margin-top: 2px"></div>
      </button>
    </div>
  </div>

  <app-change-password-mobile [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD)"></app-change-password-mobile>
  <app-authenticator-mobile
    [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR)"></app-authenticator-mobile>
  <app-identity-verification-mobile
    *ngIf="(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION)"></app-identity-verification-mobile>
</div>

<!--<div *ngIf="managerService.environment.app | managerPipe" class="d-flex flex-column w-100 h-100"-->
<!--     [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE">-->
<!--  <div class="w-100 d-flex flex-column" style="background-color: #fcfdff; box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5); margin-bottom: 4px;">-->

<!--    <div class="mt-4 mb-2 ml-3"-->
<!--         style="font-family: Roboto, sans-serif;font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">-->
<!--      <span>-->
<!--        <svg style="height: 24px; width: 24px; margin-top: -4px;">-->
<!--          <use [attr.xlink:href]='"./assets/images/security.svg#security"' fill="#272a3b"/>-->
<!--        </svg>-->
<!--      </span>-->
<!--      {{managerService.config.SECURITY.HEADING_MOBILE | managerPipe}}-->
<!--    </div>-->

<!--    <div>-->
<!--      <button (click)="managerService.active_security_panel = managerService.active_security_panel_template.CHANGE_PASSWORD"-->
<!--              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD"-->
<!--              class="btn-native btn-account-navigator" style="width: 50%; font-size: 13px; letter-spacing: normal;"-->
<!--              type="button">-->
<!--        <div [innerHTML]="managerService.config.SECURITY.CHANGE_PASSWORD.HEADING_MOBILE | managerPipe"></div>-->
<!--        <div [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD)" class="mx-auto"-->
<!--             style="width: 90px; border-bottom: 1px solid var(&#45;&#45;artiox-blue); margin-top: 2px"></div>-->
<!--      </button>-->
<!--      <button (click)="managerService.active_security_panel = managerService.active_security_panel_template.TWO_FACTOR_AUTHENTICATOR"-->
<!--              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR"-->
<!--              class="btn-native btn-account-navigator" style="width: 50%; font-size: 13px; letter-spacing: normal;"-->
<!--              type="button">-->
<!--        <div [innerHTML]="managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.HEADING_MOBILE | managerPipe"></div>-->
<!--        <div [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR)" class="mx-auto"-->
<!--             style="width: 90px; border-bottom: 1px solid var(&#45;&#45;artiox-blue); margin-top: 2px"></div>-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->

<!--  <app-change-password-mobile [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD)"></app-change-password-mobile>-->
<!--  <app-authenticator-mobile-->
<!--    [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR)"></app-authenticator-mobile>-->
<!--  <app-identity-verification-mobile-->
<!--    [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION)"></app-identity-verification-mobile>-->
<!--</div>-->
