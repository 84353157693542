import {Component} from '@angular/core';
import {ManagerService} from "../../../services/manager.service";

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrl: './download-app.component.css'
})
export class DownloadAppComponent {
  constructor(public managerService: ManagerService) {

  }
}
