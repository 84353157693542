import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';

import {CookieService} from 'ngx-cookie-service';
import {io} from 'socket.io-client';
import {UtilityService} from './utility.service';
import {BillingAddress, CurrencyOperation, CurrencyWallet, DeliveryAddress, UserService} from './user.service';
import {CurrencyPair, Pair, PairListSortingDirectionTemplate, PairListSortingTypeTemplate, PairService} from './pair.service';
import {Order, OrderBookService} from './order-book.service';
import {ActiveTradeKindPanelTemplate, ActiveTradeTypePanelTemplate, TradeHistoryService} from './trade-history.service';
import {MarketingService} from './marketing.service';

import {ModalService} from '../modules/modal/modal.service';
import {SnackBarService} from '../modules/snack-bar/snack-bar.service';

import {config_default} from '../configs/config_default';
import {config_tr} from '../configs/config_tr';
import {config_en} from '../configs/config_en';

import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import {TooltipDirectionTemplate, TooltipService} from '../modules/tooltip/tooltip.service';
import {environment} from '../../environments/environment';
import {CorporatePageTemplate, DepositPageTemplate, NavigationService, ServicePageTemplate, WithdrawalPageTemplate} from './navigation.service';
import {Location} from '@angular/common';
import {first} from 'rxjs/operators';
import {OperationsService} from './operations.service';
import {AuctionCatalog, AuctionProduct, AuctionService} from './auction.service';
import {BidService} from './bid.service';
import {Clipboard} from '@angular/cdk/clipboard';
// import {DatabaseService} from "./database.service";

// declare const gtag: any;
// declare const fbq: any;
declare const cordova: any;
declare const FirebasePlugin: any;
declare const facebookConnectPlugin: any;

export enum ActiveMobileNavbarPanelTemplate {
  HOME,
  BASIC_TRADE,
  ADVANCED_TRADE,
  AUCTION,
  ACCOUNT,
}

export enum ActiveBalancePanelTemplate {
  SUMMARY,
  USER_TRADE_HISTORY,
  USER_OPERATION_HISTORY
}

export enum ActiveAccountAuctionPanelTemplate {
  MY_BIDS,
  MY_FOLLOWINGS,
  MY_WONS,
}

export enum ActiveUserInformationPanelTemplate {
  PROFILE,
  AUCTION_ADDRESS,
}

export enum ActiveSecurityPanelTemplate {
  CHANGE_PASSWORD,
  TWO_FACTOR_AUTHENTICATOR,
  IDENTITY_VERIFICATION,
}

export enum ActiveNavigationBarPanelTemplate {
  ADMIN,
  DASHBOARD,
  USER_INFORMATION,
  BALANCE,
  DEPOSIT,
  WITHDRAWAL,
  AUCTION,
  SECURITY
}

export enum ActiveOrderBookPanelTemplate {
  USER_BALANCE_CHART,
  ORDER_BOOK,
  TRADE_HISTORY
}

export enum ActiveUserOperationPanelTemplate {
  USER_OPEN_ORDERS,
  USER_TRADE_HISTORY
}

export enum ActiveCurrencyConverterPanelTemplate {
  BUY,
  SELL
}

export enum ActiveAuctionInfoPanelTemplate {
  ARTIST,
  LAST_BIDS,
}

export enum ManagerPipeArgumentTemplate {
  RENDER_MOBILE,
  RENDER_DESKTOP,
  ACTIVE_MOBILE_NAVBAR_PANEL_HOME,
  ACTIVE_MOBILE_NAVBAR_PANEL_BASIC_TRADE,
  ACTIVE_MOBILE_NAVBAR_PANEL_ADVANCED_TRADE,
  ACTIVE_MOBILE_NAVBAR_PANEL_AUCTION,
  ACTIVE_MOBILE_NAVBAR_PANEL_ACCOUNT,
  GET_FEATURED_PAIR_LIST,
  GET_LISTED_PAIR_LIST,
  GET_NOT_LISTED_PAIR_LIST,
  ART_PRODUCT_IMAGE_DESKTOP,
  ART_PRODUCT_IMAGE_MOBILE,
  ART_PRODUCT_IMAGE_BACKGROUND_DESKTOP,
  ART_PRODUCT_IMAGE_BACKGROUND_MOBILE,
  SLIDER_BY_VW,
  SLIDER_BY_PIXEL,
  ART_PRODUCT_NAME,
  ART_PRODUCT_NAME_UPPERCASE,
  ART_PRODUCT_ARTIST_NAME,
  ART_PRODUCT_ARTIST_BORN_DEATH,
  ART_PRODUCT_SIGNATURE,
  ART_PRODUCT_MEDIUM,
  ART_PRODUCT_SIZE,
  ART_PRODUCT_YEAR,
  SLIDER_BUTTON,
  SALE_STATE_BUTTON,
  TEXT_LIMIT,
  ASPECT_RATIO_16_9,
  ACTIVE_ART_PRODUCT_NAME,
  CHECK_ACTIVE_PAIR,
  CHECK_ACTIVE_FEATURED_PAIR,
  ACTIVE_PAIR_SYMBOL,
  ACTIVE_PAIR_PRICE,
  ACTIVE_PAIR_VALUE,
  ACTIVE_PAIR_VOLUME,
  ACTIVE_PAIR_CHANGE,
  PAIR_CHANGE_COLOR,
  ACTIVE_ART_PRODUCT_IMAGE_DESKTOP,
  ACTIVE_ART_PRODUCT_IMAGE_MOBILE,
  ACTIVE_ART_PRODUCT_ARTIST_NAME,
  ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH,
  ACTIVE_ART_PRODUCT_SYMBOL,
  EVALUATE_STRING,
  ACTIVE_TRADE_PANEL_BUY,
  ACTIVE_TRADE_PANEL_SELL,
  ACTIVE_ART_PRODUCT_ARTIST_ABOUT,
  ACTIVE_PAIR_INFO,
  ACTIVE_PAIR_ROUTER_LINK,
  USER_ACTIVE_ART_PRODUCT_AVAILABLE_AMOUNT,
  USER_ACTIVE_CURRENCY_AVAILABLE_AMOUNT,
  USER_INORDER_AMOUNT,
  GET_CURRENCY_NAME_BY_ID,
  GET_CURRENCY_SYMBOL_BY_ID,
  ROOT_CURRENCY_SYMBOL,
  ACTIVE_CURRENCY_SYMBOL,
  ACTIVE_CURRENCY_NAME,
  ACTIVE_QUOTE_CURRENCY_NAME,
  ACTIVE_QUOTE_CURRENCY_SYMBOL,
  ACTIVE_BASE_CURRENCY_SYMBOL,
  ACTIVE_BASE_CURRENCY_NAME,
  ACTIVE_BASE_CURRENCY_DECIMAL,
  ACTIVE_CURRENCY_PAIR_PRICE,
  ACTIVE_ART_PRODUCT_SIGNATURE,
  ACTIVE_ART_PRODUCT_MEDIUM,
  ACTIVE_ART_PRODUCT_SIZE,
  ACTIVE_PAIR_YEAR,
  ACTIVE_PAIR_CONDITION,
  ACTIVE_PAIR_EXPERT_REPORT,
  ACTIVE_PAIR_INSURANCE_REPORT,
  ACTIVE_PAIR_EXHIBITION_REPORT,
  ART_PRODUCT_SYMBOL,

  // AUCTION_PRODUCT_ARRAY,
  ACTIVE_CATALOG_AUCTION_PRODUCT_ARRAY,
  ACTIVE_CATALOG_STARTED_AUCTION_PRODUCT_ARRAY,
  AUCTION_CATALOG_ARRAY_LIVE,
  AUCTION_CATALOG_ARRAY_PAST,

  // GET_USER_BIDS,
  GET_USER_DELIVERY_ADDRESS,
  GET_USER_BILLING_ADDRESS,
  GET_USER_WON,
  GET_USER_WON_DELIVERY_INFO,
  // GET_USER_WON_DELIVERY_INNERHTML,
  GET_USER_WON_DELIVERY_HEADER,
  GET_USER_WON_DELIVERY_HEADER_COLOR,
  GET_USER_WON_DELIVERY_TEXT,
  GET_USER_WON_DELIVERY_IMAGE,
  GET_USER_WON_DELIVERY_STATUS,
  GET_USER_LATEST_BIDS,
  GET_FILTERED_SORTED_AUCTION_PRODUCT_LIST,
  // GET_FOLLOWING_PRODUCT_LIST,
  AUCTION_PRODUCT_ARTIST_NAME,
  AUCTION_PAYMENT_DESCRIPTION,
  AUCTION_PRODUCT_BY_ID,
  AUCTION_PRODUCT_NAME,
  AUCTION_PRODUCT_LOT,
  AUCTION_PRODUCT_TEXT,
  AUCTION_CATALOG_THUMBNAIL,
  AUCTION_CATALOG_NAME,
  AUCTION_CATALOG_TITLE,
  AUCTION_CATALOG_TEXT,
  AUCTION_CATALOG_START_DATE,
  AUCTION_CATALOG_END_DATE,
  AUCTION_CATALOG_SORT_OPTIONS,
  AUCTION_CATALOG_ACTIVE_SORT_OPTION,
  AUCTION_PRODUCT_THUMBNAIL,
  AUCTION_PRODUCT_OPENING_PRICE,
  AUCTION_PRODUCT_ACTIVE_BID,
  AUCTION_PRODUCT_CURRENT_VALUE,
  AUCTION_PRODUCT_NEXT_BIDS,
  AUCTION_PRODUCT_TIME_STYLE,
  AUCTION_PRODUCT_COUNTDOWN_BAR,
  AUCTION_PRODUCT_REMAIN_TIME,
  AUCTION_PRODUCT_COUNTDOWN,
  AUCTION_PRODUCT_TIME_INCREMENT_TEXT,
  AUCTION_PRODUCT_END_DATE,
  AUCTION_PRODUCT_OWNERSHIP_STATUS,
  AUCTION_PRODUCT_OPENING_VALUE,
  ACTIVE_AUCTION_CATALOG_NAME,
  ACTIVE_AUCTION_LOT,
  // ACTIVE_AUCTION_LOT_NAME,
  ACTIVE_AUCTION_PRODUCT_STATE,
  ACTIVE_AUCTION_PRODUCT_NAME,
  ACTIVE_AUCTION_PRODUCT_ARTIST_NAME,
  ACTIVE_AUCTION_PRODUCT_TEXT,
  ACTIVE_AUCTION_PRODUCT_STATEMENT,
  ACTIVE_AUCTION_PRODUCT_IMAGES,
  ACTIVE_AUCTION_PRODUCT_END_DATE,
  ACTIVE_AUCTION_PRODUCT_TIME_INCREMENT_TEXT,
  ACTIVE_AUCTION_PRODUCT_OPENING_PRICE,
  ACTIVE_AUCTION_PRODUCT_CURRENT_PRICE,
  ACTIVE_AUCTION_PRODUCT_TOTAL_PRICE,
  AUCTION_PRODUCT_CURRENT_PRICE,
  AUCTION_PRODUCT_TOTAL_PRICE,
  ACTIVE_AUCTION_PRODUCT_MIN_BID,
  ACTIVE_AUCTION_PRODUCT_NEXT_BIDS,
  ACTIVE_AUCTION_PRODUCT_PRICE_ESTIMATION,
  ACTIVE_AUCTION_PRODUCT_ARTIST_TITLE,
  ACTIVE_AUCTION_PRODUCT_ARTIST_TEXT,
  ACTIVE_AUCTION_PRODUCT_COUNTDOWN,
  ACTIVE_AUCTION_PRODUCT_COUNTDOWN_BAR,
  ACTIVE_AUCTION_PRODUCT_REMAIN_TIME,
  ACTIVE_AUCTION_TIME_STYLE,
  AUCTION_PRODUCT_STATUS_STYLE,
  ACTIVE_AUCTION_CATALOG_PRODUCTS,
  AUCTION_PRODUCT_FILTER,
  GET_LAST_BIDS,
  GET_LAST_BID_COUNT,
  GET_BID_TICKERS,
  // ACTIVE_BID_TAX_PIPE,
  // ACTIVE_BID_TOTAL_PIPE,
  ACTIVE_BID_PRICE,
  ACTIVE_BID_COMMISSION,
  ACTIVE_BID_TAX,
  ACTIVE_BID_TOTAL,
  FANCY_NUMBER,
  FANCY_NUMBER_NO_DECIMAL,
  FANCY_NUMBER_DECIMAL,
  PAIR_VALUE,
  SALE_STATE_TIME_HEADING,
  SALE_STATE_TIME_COUNTDOWN,
  TIME_FORMAT,
  GET_DAYS,
  GET_HOURS,
  GET_MINUTES,
  GET_SECONDS,
  ART_PRODUCT_TOTAL_SUPPLY,
  ART_PRODUCT_CIRCULATING_SUPPLY,
  ART_PRODUCT_PRE_SALE_CIRCULATING_SUPPLY,
  ART_PRODUCT_PUBLIC_SALE_CIRCULATING_SUPPLY,
  IS_PAIR_FAVORITE,
  IS_AUCTION_PRODUCT_FAVOURITE,
  GET_SORTED_LISTED_PAIR_LIST,
  GET_SORTED_LISTED_CURRENCY_PAIR_LIST,
  GET_TRADE_CHART_DATA_SVG,
  GET_BUY_ORDER_BOOK,
  GET_SELL_ORDER_BOOK,
  GET_BUY_ORDER_BOOK_BACKGROUND_COLOR,
  GET_SELL_ORDER_BOOK_BACKGROUND_COLOR,
  LAST_PRICE_COLOR,
  GET_TRADE_HISTORY,
  GET_SWAP_HISTORY,
  GET_TRADE_HISTORY_BACKGROUND_COLOR,
  // TRADE_TIME_FORMAT,
  GET_USER_OPEN_ORDERS,
  PAIR_SYMBOL,
  TRANSACTION_SYMBOL,
  USER_OPEN_ORDER_TYPE,
  USER_TRADE_TYPE,
  GET_USER_OPEN_ORDERS_BACKGROUND_COLOR,
  GET_USER_TRADE_BACKGROUND_COLOR,
  // USER_OPEN_ORDER_TIME,
  GET_USER_TRADE_HISTORY,
  GET_USER_TRANSACTION_HISTORY,
  ACTIVE_PAIR_LOW,
  ACTIVE_PAIR_HIGH,
  GET_USER_ASSETS,
  GET_USER_OWNED_ASSETS,
  GET_USER_CURRENCY_LIST,
  GET_USER_FIAT_CURRENCY_LIST,
  GET_USER_CRYPTO_CURRENCY_LIST,
  GET_USER_ART_PRODUCT_LIST,
  GET_USER_ASSETS_SYMBOL,
  GET_USER_OWNED_ART_PRODUCT_LIST,
  GET_USER_ASSETS_COLOR,
  ACTIVE_NAVIGATION_BAR_PANEL_ADMIN,
  ACTIVE_NAVIGATION_BAR_PANEL_DASHBOARD,
  ACTIVE_NAVIGATION_BAR_PANEL_USER_INFORMATION,
  ACTIVE_NAVIGATION_BAR_PANEL_BALANCE,
  ACTIVE_NAVIGATION_BAR_PANEL_DEPOSIT,
  ACTIVE_NAVIGATION_BAR_PANEL_WITHDRAWAL,
  ACTIVE_NAVIGATION_BAR_PANEL_AUCTION,
  ACTIVE_NAVIGATION_BAR_PANEL_SECURITY,
  GET_USER_ACTIVITY_RECORDS,
  CHECK_ACCOUNT_LEVEL_EQUALS_TO,
  CHECK_ACCOUNT_LEVEL_GREATER_THAN,
  GET_USER_ACCOUNT_LEVEL,
  CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN,
  ACTIVE_BALANCE_PANEL_SUMMARY,
  ACTIVE_BALANCE_PANEL_OPERATION_HISTORY,
  ACTIVE_BALANCE_PANEL_ORDER_HISTORY,
  ACTIVE_ACCOUNT_AUCTION_PANEL_MY_BIDS,
  ACTIVE_ACCOUNT_AUCTION_PANEL_MY_FOLLOWINGS,
  ACTIVE_ACCOUNT_AUCTION_PANEL_MY_WONS,
  ACTIVE_USER_INFORMATION_PANEL_PROFILE,
  ACTIVE_USER_INFORMATION_PANEL_AUCTION_ADDRESS,
  GET_USER_ASSETS_VALUE,
  GET_USER_TOTAL_ART_PRODUCT_TYPE_OF_CURRENCY,
  GET_USER_TOTAL_CURRENCY_TYPE_OF_CURRENCY,
  GET_USER_TOTAL_FIAT_CURRENCY_TYPE_OF_CURRENCY,
  GET_USER_TOTAL_CRYPTO_CURRENCY_TYPE_OF_CURRENCY,
  GET_USER_CRYPTO_CURRENCY_TYPE_OF_CURRENCY,
  IS_USER_ASSETS_ART_PRODUCT,
  IS_USER_ASSETS_CURRENCY,
  IS_BASE_ASSET_IN_PAIR,
  IS_BASE_ASSET_IN_CURRENCY_PAIR,
  IS_CURRENCY_ASSET_CRYPTO,
  IS_CURRENCY_ASSET_FIAT,
  GET_USER_OPERATION_HISTORY,
  GET_CURRENCY_LIST,
  CURRENCY_SYMBOL,
  GET_USER_OPERATION_BACKGROUND_COLOR,
  GET_USER_OPERATION_TYPE,
  GET_USER_OPERATION_INFO,
  GET_USER_OPERATION_INFO_STYLE,
  GET_USER_OPERATION_ICON,
  GET_BANK_ACCOUNT_IMG_SRC,
  GET_CRYPTO_ACCOUNT_IMG_SRC,
  GET_CRYPTO_TOKEN_IMG_SRC,
  GET_WITHDRAWAL_RULES_STYLE,
  GET_WITHDRAWAL_ACCOUNT_INFO,
  ACTIVE_FEATURED_ART_PRODUCT_NAME,
  ACTIVE_FEATURED_ART_PRODUCT_SYMBOL,
  ACTIVE_FEATURED_CURRENCY_SYMBOL,
  ACTIVE_FEATURED_PAIR_SYMBOL,
  ACTIVE_FEATURED_PAIR_PRICE,
  ACTIVE_FEATURED_PAIR_VALUE,
  ACTIVE_FEATURED_PAIR_VOLUME,
  ACTIVE_FEATURED_PAIR_CHANGE,
  ACTIVE_FEATURED_ART_PRODUCT_IMAGE_DESKTOP,
  ACTIVE_FEATURED_ART_PRODUCT_IMAGE_MOBILE,
  ACTIVE_FEATURED_ART_PRODUCT_ARTIST_NAME,
  ACTIVE_FEATURED_ART_PRODUCT_ARTIST_BORN_DEATH,
  ACTIVE_FEATURED_ART_PRODUCT_ARTIST_ABOUT,
  ACTIVE_FEATURED_ART_PRODUCT_ARTIST_ABOUT_UPPERCASE,
  ACTIVE_FEATURED_PAIR_INFO,
  ACTIVE_FEATURED_PAIR_ROUTER_LINK,
  ACTIVE_FEATURED_ART_PRODUCT_SIGNATURE,
  ACTIVE_FEATURED_ART_PRODUCT_MEDIUM,
  ACTIVE_FEATURED_ART_PRODUCT_SIZE,
  ACTIVE_FEATURED_PAIR_YEAR,
  ACTIVE_FEATURED_PAIR_CONDITION,
  ACTIVE_FEATURED_PAIR_EXPERT_REPORT,
  ACTIVE_FEATURED_PAIR_INSURANCE_REPORT,
  ACTIVE_FEATURED_PAIR_EXHIBITION_REPORT,
  ACTIVE_FEATURED_ART_PRODUCT_TOKEN_LEFT,
  ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_TOKEN_LEFT,
  ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_TOKEN_LEFT,
  ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO,
  ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_SUPPLY_RATIO,
  ACTIVE_FEATURED_ART_PRODUCT_CIRCULATING_SUPPLY_RATIO,
  ACTIVE_FEATURED_ART_PRODUCT_LOCKED_SUPPLY_RATIO,
  ACTIVE_FEATURED_ART_PRODUCT_CIRCULATING_SUPPLY,
  TOKEN_LEFT_TITLE,
  LOCKED_SUPPLY_BAR,
  PRE_SALE_SUPPLY_BAR,
  PUBLIC_SALE_SUPPLY_BAR,
  PRE_SALE_BUY_BUTTON,
  ACTIVE_FEATURED_ART_PRODUCT_TOTAL_SUPPLY,
  ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_CIRCULATING_SUPPLY,
  ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_CIRCULATING_SUPPLY,
  ACTIVE_FEATURED_ART_PRODUCT_SALE_STATE_TIME_HEADING,
  ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_PRICE,
  ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_PRICE,
  ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_DATE,
  ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE,
  ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE_MOBILE,
  ACTIVE_FEATURED_ART_PRODUCT_LISTING_DATE,
  ACTIVE_FEATURED_ART_PRODUCT_CONTRACT_ADDRESS,
  ACTIVE_FEATURED_ART_PRODUCT_CONTRACT_ADDRESS_LINK,
  ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING,
  ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE,
  ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE,
  ACTIVE_FEATURED_ART_PRODUCT_SALE_LISTING,
  ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_START_DATE,
  ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_DATE_INTERVAL,
  ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE_INTERVAL,
  GET_BUY_ORDER_BOOK_BACKGROUND_COLOR_MOBILE,
  GET_SELL_ORDER_BOOK_BACKGROUND_COLOR_MOBILE,
  RETURN_INDEX,
  GET_USER_BANK_ACCOUNT_LIST,
  GET_USER_CRYPTO_ACCOUNT_LIST,
  USER_TRY_CURRENCY_AVAILABLE_AMOUNT,
  USER_ACTIVE_BASE_CURRENCY_AVAILABLE_AMOUNT,
  USER_ACTIVE_QUOTE_CURRENCY_AVAILABLE_AMOUNT,
  USER_CRYPTO_CURRENCY_AVAILABLE_AMOUNT,
  ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD,
  ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR,
  ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION,
  GET_BIRTH_YEAR_LIST,
  SORT_BY_CREATION_TIME,
  FILE_INPUT_TEXT_TRANSFORM,
  GET_USER_SUMMARY_FILTER_BACKGROUND_COLOR,
  GET_USER_ORDER_HISTORY_FILTER_BACKGROUND_COLOR,
  GET_USER_OPERATION_HISTORY_FILTER_BACKGROUND_COLOR,
  GET_MIN_BODY_HEIGHT,
  GET_FILLING_BODY_HEIGHT,
  GET_MIN_BODY_HEIGHT_OBSOLETE,
  // GET_BODY_HEIGHT,
  SET_HEIGHT,
  GET_USER_ACTIVITY_TITLE,
  GET_LANGUAGE_NAME,
  GET_USER_EMAIL_SHORTENED,
  SET_HREF_CLASS,
  SET_HREF_SOURCE,
  SET_ASSET_SOURCE,
  LOCAL_IMAGE_MANAGER,
  LOCAL_IMAGE_MANAGER_TO_BE,
  // LOCAL_ASSET_MANAGER,
  SET_CURRENT_YEAR,
  TO_LOCAL_UPPER_CASE,
  CHECK_LENGTH_SHORTER_THAN,
  CHECK_TIME_ARRIVED,
  // BYPASS_SECURITY_TRUST_HTML,
  // BYPASS_SECURITY_TRUST_RESOURCE_URL,
  ADMIN_GET_CACHED_USER,
  ADMIN_GET_CACHED_USER_INFO,
  ADMIN_GET_USER_PLATFORM,
  ENTRIES,
  VALUES,
  CALLBACK,
  CALL_METHOD,
  COMPARE,
  IDENTITY,
  RANGE,
  COUNTDOWN,
  CLOSE_MODAL,
  FORMAT_EUROPEAN_TIME,
  FORMAT_EUROPEAN_DATE,
  FORMAT_EUROPEAN_DATE_TIME,
  FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME,
  FORMAT_PHONE_NUMBER,
  GET_CITIES_LIST,
  GET_DISTRICT_LIST,
  SIGN_UP_AGREEMENTS,
  USER_OPERATION_HISTORY_AMOUNT,
  USER_ORDER_HISTORY_AMOUNT,
  USER_BALANCE_SUMMARY_AMOUNT,
  USER_BALANCE_SUMMARY_TOTAL_AMOUNT,
  SET_CURRENCY_CONVERTER_DECIMAL,
  // HEADER_CONFIG_EVALUATOR,
  VALUE_TYPE,
}

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  public language: string = 'TR';
  public language_map: object = {TR: config_tr, EN: config_en};
  public view_port: string = 'desktop';

  public config_tr = config_tr;
  public config_en = config_en;

  public config = config_default;
  public config_ready: boolean = false;

  public client_ip: string = '';
  public client_info: {} = {};

  public mpa_template = ManagerPipeArgumentTemplate;

  public moment = moment;

  // system //
  public inner_width: number = 0;
  // public notification_menu_state: string = 'close';  // obsolete
  // public notification_status: string = 'passive';  // obsolete
  public open_announcement_modal: boolean = false;
  public art_product_info_display_status: boolean = false;
  public art_product_token_info_display_status: boolean = false;
  public account_navbar_menu_display_status: boolean = false;

  public active_order_book_panel: ActiveOrderBookPanelTemplate = ActiveOrderBookPanelTemplate.ORDER_BOOK;
  public active_order_book_panel_template = ActiveOrderBookPanelTemplate;
  public active_user_operation_panel: ActiveUserOperationPanelTemplate = ActiveUserOperationPanelTemplate.USER_OPEN_ORDERS;
  public active_user_operation_template = ActiveUserOperationPanelTemplate;

  public pair_list_sorting_type: PairListSortingTypeTemplate = PairListSortingTypeTemplate.PAIR;
  public pair_list_sorting_type_template = PairListSortingTypeTemplate;
  public pair_list_sorting_direction: PairListSortingDirectionTemplate = PairListSortingDirectionTemplate.UP;
  public pair_list_sorting_direction_template = PairListSortingDirectionTemplate;

  public active_trade_type_panel: ActiveTradeTypePanelTemplate = ActiveTradeTypePanelTemplate.BUY;
  public active_trade_type_panel_template = ActiveTradeTypePanelTemplate;

  public active_trade_kind_panel: ActiveTradeKindPanelTemplate = ActiveTradeKindPanelTemplate.LIMIT;
  public active_trade_kind_panel_template = ActiveTradeKindPanelTemplate;

  public active_mobile_navbar_panel: ActiveMobileNavbarPanelTemplate = ActiveMobileNavbarPanelTemplate.HOME;
  public active_mobile_navbar_panel_template = ActiveMobileNavbarPanelTemplate;

  public active_navigation_bar_panel: ActiveNavigationBarPanelTemplate = ActiveNavigationBarPanelTemplate.DASHBOARD;
  public active_navigation_bar_panel_template = ActiveNavigationBarPanelTemplate;

  public active_balance_panel: ActiveBalancePanelTemplate = ActiveBalancePanelTemplate.SUMMARY;
  public active_balance_panel_template = ActiveBalancePanelTemplate;

  public active_account_auction_panel: ActiveAccountAuctionPanelTemplate = ActiveAccountAuctionPanelTemplate.MY_BIDS;
  public active_account_auction_panel_template = ActiveAccountAuctionPanelTemplate;

  public active_user_information_panel: ActiveUserInformationPanelTemplate = ActiveUserInformationPanelTemplate.PROFILE;
  public active_user_information_panel_template = ActiveUserInformationPanelTemplate;

  public active_security_panel: ActiveSecurityPanelTemplate = ActiveSecurityPanelTemplate.CHANGE_PASSWORD;
  public active_security_panel_template = ActiveSecurityPanelTemplate;

  public active_currency_converter_panel: ActiveCurrencyConverterPanelTemplate = ActiveCurrencyConverterPanelTemplate.BUY;
  public active_currency_converter_panel_template = ActiveCurrencyConverterPanelTemplate;

  public active_auction_info_panel: ActiveAuctionInfoPanelTemplate = ActiveAuctionInfoPanelTemplate.ARTIST;
  public active_auction_info_panel_template = ActiveAuctionInfoPanelTemplate;

  public tooltip_direction_template = TooltipDirectionTemplate;

  @Output() notify_admin: EventEmitter<any> = new EventEmitter();
  @Output() language_changed: EventEmitter<any> = new EventEmitter();
  @Output() active_order_book_panel_changed: EventEmitter<any> = new EventEmitter();
  // @Output() active_trade_panel_changed: EventEmitter<any> = new EventEmitter();
  @Output() active_trade_kind_panel_changed: EventEmitter<any> = new EventEmitter();
  @Output() user_logged_in: EventEmitter<any> = new EventEmitter();
  @Output() admin_logged_in: EventEmitter<any> = new EventEmitter();
  // @Output() set_form_buy_price: EventEmitter<any> = new EventEmitter();
  // @Output() set_form_sell_price: EventEmitter<any> = new EventEmitter();
  @Output() set_form_price: EventEmitter<any> = new EventEmitter();
  @Output() test_environment_accepted_changed: EventEmitter<any> = new EventEmitter();
  @Output() config_loaded: EventEmitter<any> = new EventEmitter();
  @Output() active_currency_converter_panel_changed: EventEmitter<any> = new EventEmitter();
  @Output() report_bid_modal: EventEmitter<any> = new EventEmitter();

  // render pipes //
  public user_pipe: boolean = false;
  public user_info_pipe: boolean = false;
  public user_art_product_operation_history_pipe: boolean = false;
  public user_currency_operation_history_pipe: boolean = false;
  public user_open_orders_history_pipe: boolean = false;
  public user_trade_history_pipe: boolean = false;
  public user_swap_history_pipe: boolean = false;
  public user_bids_pipe: boolean = false;
  public user_balance_history_pipe: boolean = false;
  public user_bank_accounts_pipe: boolean = false;
  public user_crypto_accounts_pipe: boolean = false;
  public user_activity_records_pipe: boolean = false;
  public user_deliveries_pipe: boolean = false;
  public art_product_pipe: boolean = false;
  public currency_pipe: boolean = false;
  public pair_pipe: boolean = false;
  public currency_pair_pipe: boolean = false;
  public order_book_pipe: boolean = false;
  public depth_book_pipe: boolean = false;
  public trade_history_pipe: boolean = false;
  public swap_history_pipe: boolean = false;
  public trade_chart_pipe: boolean = false;
  public admin_pipe: boolean = false;
  public language_pipe: boolean = false;

  public logged_in: boolean = false;

  public use_broadcast_channel: boolean = true;
  public socket_io_broadcast_channel: any = null;

  public client_socket: any = null;


  // app specific
  public cordova: any;
  public firebase_plugin: any;
  public facebook_connect_plugin: any;
  public app_tracking_transparency: boolean = true;

  public show_sign_up_or_login: boolean = true;
  // public app_interrupted: boolean = false;
  public maintenance: boolean = false;

  public show_connection_problem_modal: boolean = false;
  public socket_connection_checker: any;

  public window: any = window;
  public navigator: any = navigator;

  public environment = environment;
  public local_storage_error = false;
  public cookie: any = {};

  constructor(private httpClient: HttpClient, private cookieService: CookieService, public router: Router, public clipboard: Clipboard,
              public pairService: PairService, public orderBookService: OrderBookService, public tradeHistoryService: TradeHistoryService,
              public userService: UserService, public operationsService: OperationsService, public utilityService: UtilityService,
              public modalService: ModalService, public snackBarService: SnackBarService, public tooltipService: TooltipService,
              public navigationService: NavigationService, private route: ActivatedRoute, private _location: Location,
              public marketingService: MarketingService, public auctionService: AuctionService, public bidService: BidService
              // public databaseService: DatabaseService
  ) {

    this.controlLocalStore();
    this.configHandler();

    this.setViewPort();
    this.moment.locale(this.language);

    if (environment.proxy) {
      this.client_socket = io(this.environment.socketURL, {
        path: this.environment.socketAPI,
        transports: ['websocket'],
        upgrade: true,
        autoConnect: true,
        forceNew: true
      });
    } else {
      this.client_socket = io({
        path: this.environment.socketAPI,
        transports: ['websocket'],
        upgrade: true,
        autoConnect: true,
        forceNew: true
      });
    }

    this.route.queryParams.subscribe(params => {
      this.handleRouting(params);
    });

    const socket_io_global_event_callbacks = {
      update_art_product_list: () => {
        new Promise((resolve, reject) => {
          this.pairService.refreshArtProductList(resolve);
        }).then(() => {
          this.art_product_pipe = !this.art_product_pipe;
        });
      },
      update_currency_list: () => {
        new Promise((resolve, reject) => {
          this.pairService.refreshCurrencyList(resolve);
        }).then(() => {
          this.currency_pipe = !this.currency_pipe;
        });
      },
      update_pair_list: () => {
        new Promise((resolve, reject) => {
          this.pairService.refreshPairList(resolve);
        }).then(() => {
          this.pair_pipe = !this.pair_pipe;
        });
      },
      update_currency_pair_list: (data: any) => {
        new Promise((resolve, reject) => {
          this.pairService.refreshCurrencyPairList(resolve);
        }).then(() => {
          this.currency_pair_pipe = !this.currency_pair_pipe;
        });
      },
      update_auction_product_list: () => {
        new Promise((resolve, reject) => {
          this.auctionService.refreshAuctionProductList(resolve);
        }).then(() => {
          this.setActiveAuctionProduct(this.auctionService.auction_product_list.getAuctionProductByHash(this.auctionService.active_auction_product.hash));
        });
      },
      update_art_product: (data: any) => {
        new Promise((resolve, reject) => {
          this.pairService.refreshArtProduct(data.art_product_id, resolve);
        }).then(() => {
          this.art_product_pipe = !this.art_product_pipe;
        });
      },
      update_currency: (data: any) => {
        new Promise((resolve, reject) => {
          this.pairService.refreshCurrency(data.currency_id, resolve);
        }).then(() => {
          this.currency_pipe = !this.currency_pipe;
        });
      },
      update_pair: (data: any) => {
        new Promise((resolve, reject) => {
          this.pairService.refreshPair(data.pair_id, resolve);
        }).then(() => {
          this.userService.user_balance_pie_chart_changed.emit();
          this.userService.user_balance_history_chart_changed.emit();
          this.pair_pipe = !this.pair_pipe;
        });
      },
      update_currency_pair: (data: any) => {
        new Promise((resolve, reject) => {
          this.pairService.refreshCurrencyPair(data.currency_pair_id, resolve);
        }).then(() => {
          this.currency_pair_pipe = !this.currency_pair_pipe;
        });
      },
      update_auction_product: (data: any) => {
        new Promise((resolve, reject) => {
          this.auctionService.refreshAuctionProduct(data.auction_product_id, resolve);
        }).then(() => {
          if (this.auctionService.active_auction_product.id === data.auction_product_id) {
            this.setActiveAuctionProduct(this.auctionService.auction_product_list.getAuctionProductById(data.auction_product_id));
          }

          this.bidService.refreshBidTickers();
        });
      },
      update_order_book: (data: any) => {
        if (this.pairService.getActivePair()?.id[0] === data.pair_id[0] && this.pairService.getActivePair()?.id[1] === data.pair_id[1]) {
          new Promise((resolve, reject) => {
            this.orderBookService.refreshOrderBook(data.pair_id, resolve);
          }).then(() => {
            this.order_book_pipe = !this.order_book_pipe;
          });
        }
      },
      update_depth_book: (data: any) => {
        if (this.pairService.getActivePair()?.id[0] === data.pair_id[0] && this.pairService.getActivePair()?.id[1] === data.pair_id[1]) {
          new Promise((resolve, reject) => {
            this.tradeHistoryService.refreshDepthBookData(data.pair_id, resolve);
          }).then(() => {
            this.depth_book_pipe = !this.depth_book_pipe;
          });
        }
      },
      get_last_trade: (data: any) => {
        if (this.pairService.getActivePair()?.id[0] === data.pair_id[0] && this.pairService.getActivePair()?.id[1] === data.pair_id[1]) {
          this.tradeHistoryService.addTrade(JSON.parse(data.trade));
          this.trade_history_pipe = !this.trade_history_pipe;
        }
      },
      get_last_swap: (data: any) => {
        if (this.pairService.getActiveCurrencyPair()?.id[0] === data.currency_pair_id[0] && this.pairService.getActiveCurrencyPair()?.id[1] === data.currency_pair_id[1]) {
          this.tradeHistoryService.addSwap(JSON.parse(data.swap));
          this.swap_history_pipe = !this.swap_history_pipe;
        }
      },
      get_last_trade_chart_datum: (data: any) => {
        if (this.pairService.getActivePair()?.id[0] === data.pair_id[0] && this.pairService.getActivePair()?.id[1] === data.pair_id[1] &&
          data.zoom_level === this.tradeHistoryService.zoom_level) {
          if (data.update) {
            this.tradeHistoryService.mergeTradeChartDatum(JSON.parse(data.trade_chart_datum));
            this.trade_chart_pipe = !this.trade_chart_pipe;
          } else {
            this.tradeHistoryService.addTradeChartDatum(JSON.parse(data.trade_chart_datum));
            this.trade_chart_pipe = !this.trade_chart_pipe;
          }
        }
      },
      force_disconnect: () => {
        this.cookieService.deleteAll('/');
        this.logout();
        this.logged_in = false;
        this.user_pipe = !this.user_pipe;
        this.user_art_product_operation_history_pipe = !this.user_art_product_operation_history_pipe;
        this.user_currency_operation_history_pipe = !this.user_currency_operation_history_pipe;
        this.user_open_orders_history_pipe = !this.user_open_orders_history_pipe;
        this.user_trade_history_pipe = !this.user_trade_history_pipe;
        this.user_balance_history_pipe = !this.user_balance_history_pipe;
        this.user_bank_accounts_pipe = !this.user_bank_accounts_pipe;
        // this.navigationManager({url: '/landing-page', current_component_index: 0});
        if (this.utilityService.checkReleaseUrl()) {
          this.navigateToTrArtiox();
        } else {
          this.navigationManager({url: '/home', current_component_index: 0});
        }
        this.navigationService.clearNavigationHistory();
      },
      force_refresh: () => {
        // if (environment.app) {
        //     this.openModal('loading-modal');
        //     setTimeout(() => {
        //         const art_product_promise = new Promise((resolve, reject) => {
        //             this.pairService.refreshArtProductList(resolve);
        //         });
        //
        //         const currency_promise = new Promise((resolve, reject) => {
        //             this.pairService.refreshCurrencyList(resolve);
        //         });
        //
        //         Promise.all([art_product_promise, currency_promise]).then(() => {
        //             this.art_product_pipe = !this.art_product_pipe;
        //             this.currency_pipe = !this.currency_pipe;
        //             new Promise((resolve, reject) => {
        //                 this.pairService.refreshPairList(resolve);
        //             }).then(() => {
        //                 setTimeout(() => {
        //                     this.pair_pipe = !this.pair_pipe;
        //                     this.pairService.active_featured_pair_pipe = !this.pairService.active_featured_pair_pipe;
        //                     this.closeModal('loading-modal');
        //                 }, 50);
        //             });
        //         });
        //     }, 200);
        // } else {
        location.reload();
        // }
      },
    };

    const socket_io_user_event_callbacks = {
      update_user: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUser(resolve);
          }).then(() => {
            this.user_pipe = !this.user_pipe;
          });
        }
      },
      update_user_information: (data: any) => {
        new Promise((resolve, reject) => {
          this.userService.refreshUserInfo(resolve);
        }).then(() => {
          this.user_info_pipe = !this.user_info_pipe;
        });
      },
      update_user_art_product_operation_history: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserArtProductOperationHistory(resolve);
          }).then(() => {
            this.user_art_product_operation_history_pipe = !this.user_art_product_operation_history_pipe;
          });
        }
      },
      update_user_currency_operation_history: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserCurrencyOperationHistory(resolve);
          }).then(() => {
            this.user_currency_operation_history_pipe = !this.user_currency_operation_history_pipe;
          });
        }
      },
      update_user_open_orders: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserOpenOrders(resolve);
          }).then(() => {
            this.user_open_orders_history_pipe = !this.user_open_orders_history_pipe;
          });
        }
      },
      update_user_trade_history: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserTradeHistory(resolve);
          }).then(() => {
            this.user_trade_history_pipe = !this.user_trade_history_pipe;
          });
        }
      },
      update_user_swap_history: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserSwapHistory(resolve);
          }).then(() => {
            this.user_swap_history_pipe = !this.user_swap_history_pipe;
          });
        }
      },
      update_user_deliveries: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserDeliveryInfo(resolve);
          }).then(() => {
            this.user_deliveries_pipe = !this.user_deliveries_pipe;
          });
        }
      },
      update_user_bids: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserBids(resolve);
          }).then(() => {
            this.user_bids_pipe = !this.user_bids_pipe;
          });
        }
      },
      update_user_balance_history: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserBalanceHistory(resolve);
          }).then(() => {
            this.user_balance_history_pipe = !this.user_balance_history_pipe;
          });
        }
      },
      update_user_bank_accounts: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserBankAccounts(resolve);
          }).then(() => {
            this.user_bank_accounts_pipe = !this.user_bank_accounts_pipe;
            this.userService.user_bank_accounts_changed.emit();
          });
        }
      },
      update_user_crypto_accounts: () => {
        if (this.logged_in) {
          new Promise((resolve, reject) => {
            this.userService.refreshUserCryptoAccounts(resolve);
          }).then(() => {
            this.user_crypto_accounts_pipe = !this.user_crypto_accounts_pipe;
            this.userService.user_crypto_account_added.emit();
          });
        }
      },
      pre_sale_notifier: (data: any) => {
        // @ts-ignore
        const content = this.config.RESPONSE_TABLE[data.response.code];
        this.popSnackBar(content.is_okay, this.codeHandler(data.response));
      },
      order_notifier: (data: any) => {
        // @ts-ignore
        const content = this.config.RESPONSE_TABLE[data.response.code];
        this.popSnackBar(content.is_okay, this.codeHandler(data.response));
      },
      bid_notifier: (data: any) => {
        // @ts-ignore
        const content = this.config.RESPONSE_TABLE[data.response.code];
        this.popSnackBar(content.is_okay, this.codeHandler(data.response));

        // if (content.code === 508){
        //     this.openModal('auction-bid-approved-modal');
        // } else if (509){
        //     // todo this.openModal('auction-bid-exceeded-modal');
        // }
      },
      logout: () => {
        if (this.logged_in) {
          this.logout();
        }
      },
    };

    this.use_broadcast_channel = !(this.environment.app || this.runningOnSafari() || this.runningOnIPhone());
    if (this.use_broadcast_channel) {
      this.socket_io_broadcast_channel = new BroadcastChannel('artiox_socket_io_broadcast_channel');
      this.socket_io_broadcast_channel.onmessage = ((event: any) => {
        // @ts-ignore
        socket_io_user_event_callbacks[event.data.event](event.data.arg);
      });
    }

    this.client_socket.on('connect', () => {
      Object.keys(socket_io_global_event_callbacks).forEach((key) => {
        this.client_socket.on(key, (data: any) => {
          // @ts-ignore
          socket_io_global_event_callbacks[key](data);
        });
      });

      Object.keys(socket_io_user_event_callbacks).forEach((key) => {
        this.client_socket.on(key, (data: any) => {
          // @ts-ignore
          socket_io_user_event_callbacks[key](data);
          if (this.use_broadcast_channel) {
            this.socket_io_broadcast_channel.postMessage({event: key, arg: data});
          }
        });
      });

      this.loggedInResolver();
    });

    this.client_socket.on('disconnect', () => {
      this.client_socket.off('update_user');
      this.client_socket.off('update_user_information');
      this.client_socket.off('update_user_art_product_operation_history');
      this.client_socket.off('update_user_currency_operation_history');
      this.client_socket.off('update_user_trade_history');
      this.client_socket.off('update_user_swap_history');
      this.client_socket.off('update_user_balance_history');
      this.client_socket.off('update_user_open_orders');
      this.client_socket.off('update_user_bank_accounts');
      this.client_socket.off('update_user_crypto_accounts');
      this.client_socket.off('update_user_deliveries');
      this.client_socket.off('update_art_product_list');
      this.client_socket.off('update_currency_list');
      this.client_socket.off('update_pair_list');
      this.client_socket.off('update_art_product');
      this.client_socket.off('update_currency');
      this.client_socket.off('update_pair');
      this.client_socket.off('update_currency_pair');
      this.client_socket.off('update_order_book');
      this.client_socket.off('update_depth_book');
      this.client_socket.off('get_last_trade');
      this.client_socket.off('get_last_swap');
      this.client_socket.off('get_last_trade_chart_datum');
      this.client_socket.off('pre_sale_notifier');
      this.client_socket.off('order_notifier');
      this.client_socket.off('bid_notifier');
      this.client_socket.off('force_disconnect');
      this.client_socket.off('force_refresh');
    });

    window.onpopstate = (event: PopStateEvent) => {
      setTimeout(() => {
        if (this.navigationService.navigation_history.length) {
          if (this.router.url.startsWith(this.navigationService.navigation_history[this.navigationService.navigation_history.length - 1].url)) {
            this.navigationService.reverseNavigation();
          } else {
            const object = {
              url: this.router.url,
              current_component_index: this.navigationService.current_component_index
            };
            this.navigationService.current_routing = object;
            this.navigationService.raw_current_routing = JSON.stringify(object);
          }
        } else {
          const object = {
            url: this.router.url,
            current_component_index: this.navigationService.current_component_index
          };
          this.navigationService.current_routing = object;
          this.navigationService.raw_current_routing = JSON.stringify(object);
        }
      });


    };

    // if (this.environment.app) {
    //     document.addEventListener('deviceready', () => {
    //
    //         document.addEventListener('backbutton', () => {
    //             this.navigationService.reverseNavigation();
    //         });
    //
    //         // document.addEventListener('resume', () => {
    //         //     setTimeout(() => {
    //         //         this.app_interrupted = false;
    //         //     }, 1000);
    //         // });
    //         //
    //         // document.addEventListener('pause', () => {
    //         //     this.app_interrupted = true;
    //         // });
    //
    //         setTimeout(() => {
    //             this.appTrackingTransparencyChecker();
    //         }, 10000);
    //
    //     });
    // }

    this.navigationService.navigation_requested.subscribe((object) => {
      this.navigationManager(object);
    });

    this.navigationService.operation_announcement_index_changed.subscribe((index) => {
      this.toggleText(index);
    });

    this.navigationService.operation_artist_index_changed.subscribe((index) => {
      this.toggleText(index);
    });

    this.userService.user_bank_account_added.subscribe(() => {
      this.userService.user_bank_accounts_changed.pipe(first()).subscribe(() => {
        const user_bank_accounts = this.userService.getUserBankAccounts();
        this.operationsService.selectWithdrawBankAccount(user_bank_accounts[user_bank_accounts.length - 1]);
      });
    });

    this.pairService.currency_list_changed.subscribe(() => {
      this.operationsService.user_crypto_currency_wallet_list = [];
      this.pairService.currency_list.filter(e => e.type === 'crypto').map(e => e.id)
        .forEach(i => this.operationsService.user_crypto_currency_wallet_list.push(this.userService.user?.currency_list[i] as CurrencyWallet));
    });

    window.indexedDB.deleteDatabase('artiox_art_works');  // todo rm
  }

  // appTrackingTransparencyChecker(): void {
  //     if (this.environment.platform === 'ios') {
  //         this.cordova = cordova;
  //         this.cordova.plugins.idfa.getInfo().then((info: any) => {
  //             if (info.trackingPermission === undefined && info.trackingLimited === false) {
  //                 this.firebase_plugin = FirebasePlugin;
  //                 this.facebook_connect_plugin = facebookConnectPlugin;
  //                 this.window.plugins.appsFlyer.initSdk(
  //                     {
  //                         devKey: 'SWBAnfEsKsSqYE6yYbzBJT',
  //                         isDebug: !this.environment.production,
  //                         appId: '1579037676',
  //                         waitForATTUserAuthorization: 15,
  //                     }
  //                 );
  //             } else if (info.trackingPermission === 3) {
  //                 this.firebase_plugin = FirebasePlugin;
  //                 this.facebook_connect_plugin = facebookConnectPlugin;
  //                 this.window.plugins.appsFlyer.initSdk(
  //                     {
  //                         devKey: 'SWBAnfEsKsSqYE6yYbzBJT',
  //                         isDebug: !this.environment.production,
  //                         appId: '1579037676',
  //                         waitForATTUserAuthorization: 15,
  //                     }
  //                 );
  //             } else if (info.trackingPermission === 0) {
  //                 setTimeout(() => {
  //                     this.appTrackingTransparencyChecker();
  //                 }, 10000);
  //                 // } else if (info.trackingPermission === 2) {
  //                 //   this.app_tracking_transparency = false;
  //             } else {
  //                 this.firebase_plugin = FirebasePlugin;
  //                 this.facebook_connect_plugin = facebookConnectPlugin;
  //                 this.window.plugins.appsFlyer.initSdk(
  //                     {
  //                         devKey: 'SWBAnfEsKsSqYE6yYbzBJT',
  //                         isDebug: !this.environment.production,
  //                         appId: '1579037676',
  //                         waitForATTUserAuthorization: 15,
  //                     }
  //                 );
  //             }
  //         });
  //     } else if (this.environment.platform === 'android') {
  //         this.cordova = cordova;
  //         this.firebase_plugin = FirebasePlugin;
  //         this.facebook_connect_plugin = facebookConnectPlugin;
  //         this.window.plugins.appsFlyer.initSdk(
  //             {
  //                 devKey: 'SWBAnfEsKsSqYE6yYbzBJT',
  //                 isDebug: !this.environment.production,
  //                 appId: '1579037676',
  //                 waitForATTUserAuthorization: 15,
  //             }
  //         );
  //     }
  // }

  cancelUserOrder(order: Order): void {
    new Promise((resolve, reject) => {
      this.userService.cancelUserOrder(order, resolve);
    }).then((response: any) => {
      this.user_open_orders_history_pipe = !this.user_open_orders_history_pipe;
      // @ts-ignore
      const content = this.config.RESPONSE_TABLE[response.code];
      this.popSnackBar(content.is_okay, this.codeHandler(response));
    });
  }

  cancelUserOrders(): void {
    new Promise((resolve, reject) => {
      this.userService.cancelUserOrders(resolve);
    }).then(() => {
      this.user_open_orders_history_pipe = !this.user_open_orders_history_pipe;
    });
  }

  exchangeResolver(): void {
    const art_product_promise = new Promise((resolve, reject) => {
      this.pairService.refreshArtProductList(resolve);
    });
    const auction_product_promise = new Promise((resolve, reject) => {
      this.auctionService.refreshAuctionProductList(resolve);
    });
    const currency_promise = new Promise((resolve, reject) => {
      this.pairService.refreshCurrencyList(resolve);
    });

    Promise.all([art_product_promise, auction_product_promise, currency_promise]).then(() => {
      this.art_product_pipe = !this.art_product_pipe;
      this.currency_pipe = !this.currency_pipe;
      new Promise((resolve, reject) => {
        this.pairService.refreshPairList(resolve);
      }).then(() => {
        this.pair_pipe = !this.pair_pipe;
        new Promise((resolve, reject) => {
          this.orderBookService.refreshOrderBook(this.pairService.getActivePair()?.id, resolve);
        }).then(() => {
          this.order_book_pipe = !this.order_book_pipe;
        });
        new Promise((resolve, reject) => {
          this.tradeHistoryService.refreshTradeHistory(this.pairService.getActivePair()?.id, resolve);
        }).then(() => {
          this.trade_history_pipe = !this.trade_history_pipe;
        });
        new Promise((resolve, reject) => {
          this.tradeHistoryService.setZoomLevel(this.config.MISC.ZOOM_LEVEL);
          this.tradeHistoryService.refreshTradeChartData(this.pairService.getActivePair()?.id, resolve);
        }).then(() => {
          this.trade_chart_pipe = !this.trade_chart_pipe;
        });
        new Promise((resolve, reject) => {
          this.tradeHistoryService.refreshDepthBookData(this.pairService.getActivePair()?.id, resolve);
        }).then(() => {
          this.depth_book_pipe = !this.depth_book_pipe;
        });
      });
      new Promise((resolve, reject) => {
        this.pairService.refreshCurrencyPairList(resolve);
      }).then(() => {
        this.currency_pair_pipe = !this.currency_pair_pipe;
        new Promise((resolve, reject) => {
          this.tradeHistoryService.refreshSwapHistory(this.pairService.getActiveCurrencyPair()?.id, resolve);
        }).then(() => {
          this.swap_history_pipe = !this.swap_history_pipe;
        });
      });

      // new Promise((resolve, reject) => {  // do not wait pairs
      //     this.auctionService.refreshAuctionProductList(resolve);
      // }).then(r => {
      const started_auction_product_list_uncatalogued = this.auctionService.started_auction_product_list_uncatalogued;
      if (started_auction_product_list_uncatalogued.length) {
        this.setActiveAuctionProduct(started_auction_product_list_uncatalogued[started_auction_product_list_uncatalogued.length - 1]);
      }

      this.bidService.refreshBidTickers();
      // });
    });
  }

  loggedInResolver(force?: boolean, cookie?: {}): void {
    if ((this.cookieService.check('loggedIn') && JSON.parse(this.cookieService.get('loggedIn')) === true) || this.logged_in) {
      this.logged_in = true;
      new Promise((resolve, reject) => {
        this.userService.refreshUser(resolve, reject);
      })
        .then(() => {
          new Promise((resolve, reject) => {
            this.userService.refreshUserInfo(resolve);
          }).then(() => {
            this.user_info_pipe = !this.user_info_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserDeliveryInfo(resolve);
          }).then(() => {
            this.user_deliveries_pipe = !this.user_deliveries_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserArtProductOperationHistory(resolve);
          }).then(() => {
            this.user_art_product_operation_history_pipe = !this.user_art_product_operation_history_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserCurrencyOperationHistory(resolve);
          }).then(() => {
            this.user_currency_operation_history_pipe = !this.user_currency_operation_history_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserOpenOrders(resolve);
          }).then(() => {
            this.user_open_orders_history_pipe = !this.user_open_orders_history_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserTradeHistory(resolve);
          }).then(() => {
            this.user_trade_history_pipe = !this.user_trade_history_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserSwapHistory(resolve);
          }).then(() => {
            this.user_swap_history_pipe = !this.user_swap_history_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserBids(resolve);
          }).then(() => {
            this.user_bids_pipe = !this.user_bids_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserBalanceHistory(resolve);
          }).then(() => {
            this.user_balance_history_pipe = !this.user_balance_history_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserBankAccounts(resolve);
          }).then(() => {
            this.user_bank_accounts_pipe = !this.user_bank_accounts_pipe;
            this.userService.user_bank_account_added.emit();
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserCryptoAccounts(resolve);
          }).then(() => {
            this.user_crypto_accounts_pipe = !this.user_crypto_accounts_pipe;
            this.userService.user_crypto_account_added.emit();
          });
          new Promise((resolve, reject) => {
            this.userService.refreshUserActivityRecords(resolve);
          }).then(() => {
            this.user_activity_records_pipe = !this.user_activity_records_pipe;
          });
          new Promise((resolve, reject) => {
            this.userService.checkAdmin(resolve, reject);
          }).then(() => {
            this.admin_pipe = !this.admin_pipe;
            this.admin_logged_in.emit(true);
          }, () => {
            this.admin_pipe = !this.admin_pipe;
            this.admin_logged_in.emit(false);
          }).then(() => {
            this.admin_pipe = !this.admin_pipe;
          });
          this.checkUserAgreementAccepted();
          this.user_logged_in.emit(true);
          this.user_pipe = !this.user_pipe;
          this.show_sign_up_or_login = false;

          this.userService.deleteUserLoginData();
          this.marketingService.logEvent('login', {});
        }, () => {
          this.logout();
        });
    } else {
      this.user_logged_in.emit(false);
    }
  }

  getUserInfo(resolve?: any): void {
    if (this.cookieService.check('clientIP') && this.cookieService.check('clientInfo')) {
      this.client_ip = this.cookieService.get('clientIP');
      this.client_info = JSON.parse(this.cookieService.get('clientInfo'));
      resolve();
    } else {
      this.httpClient.get<any>('https://ipinfo.io/?token=042a9b7695d05e').subscribe(response => {
        this.client_ip = response.ip;
        this.client_info = {
          ip: response.ip,
          city: response.city,
          country: response.country,
          loc: response.loc
        };
        this.cookieService.set('clientIP', this.client_ip);
        this.cookieService.set('clientInfo', JSON.stringify(this.client_info));
        this.cookieService.set('platform', 'web');
        if (resolve !== undefined) {
          resolve();
        }
      });
    }
  }

  logout(): void {
    this.logoutResolver();
    this.socket_io_broadcast_channel?.postMessage({event: 'logout', arg: undefined});  // safari does not support :(

    this.httpClient.post<any>(this.environment.serverAPI + 'user_logout_service', {withCredentials: true}).subscribe(response => {
      this.logoutResolver();
      this.navigationService.clearNavigationHistory();
      // this.navigationManager({url: '/landing-page', current_component_index: 0});
      setTimeout(() => {
        if (this.utilityService.checkReleaseUrl()) {
          this.navigateToTrArtiox();
        } else {
          this.navigationManager({url: '/home', current_component_index: 0});
        }
      }, 50);
    });

    setTimeout(() => {
      this.logoutResolver();
      this.navigationService.clearNavigationHistory();
      setTimeout(() => {
        if (this.utilityService.checkReleaseUrl()) {
          this.navigateToTrArtiox();
        } else {
          this.navigationManager({url: '/home', current_component_index: 0});
        }
      }, 50);
    }, 1000);
  }

  logoutResolver(): void {
    this.cookieService.deleteAll('/');
    localStorage.removeItem('auction_agreement_checked');
    localStorage.removeItem('user_agreement_checked');
    this.userService.userLogout();
    this.userService.is_admin = false;
    this.logged_in = false;
    this.show_sign_up_or_login = true;
  }

  preciseRound(num: number | null | undefined, dec: number = 2): number {
    return this.utilityService.preciseRound(num, dec);
  }

  fancyNumber(num: number, decimals: number = 2, dec_point: string = '.', thousands_point: string = ','): string {
    return this.utilityService.fancyNumber(num, decimals, dec_point, thousands_point);
  }

  toFixedInt(num: number, digits: number = 2): string {
    return this.utilityService.toFixedInt(num, digits);
  }

  openModal(id: string): void {
    this.modalService.open(id);
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }

  toggleTooltip(id: string): void {
    this.tooltipService.toggle(id);
  }

  checkUserAgreementAccepted(): void {
    if (localStorage.getItem('user_agreement_checked') === 'true') {
      return;
    }

    new Promise((resolve, reject) => {
      this.userService.isUserAgreementAccepted(resolve, reject);
    }).then(() => {
      localStorage.setItem('user_agreement_checked', 'true');
    }, () => {
      this.modalService.open('user-agreement-modal');
    });
  }

  setActivePair(pair: Pair): void {
    if (pair.sale_state === 'delisted') {
      return;
    }

    this.marketingService.logEvent('pair_change', {pair: pair.symbol});
    this.pairService.setActivePair(pair);
    this.pairService.active_pair_selected.emit();
    this.pair_pipe = !this.pair_pipe;
    new Promise((resolve, reject) => {
      this.orderBookService.refreshOrderBook(this.pairService.getActivePair()?.id, resolve);
    }).then(() => {
      this.order_book_pipe = !this.order_book_pipe;
    });
    new Promise((resolve, reject) => {
      this.tradeHistoryService.refreshTradeHistory(this.pairService.getActivePair()?.id, resolve);
    }).then(() => {
      this.trade_history_pipe = !this.trade_history_pipe;
    });
    new Promise((resolve, reject) => {
      this.tradeHistoryService.refreshTradeChartData(this.pairService.getActivePair()?.id, resolve);
    }).then(() => {
      this.trade_chart_pipe = !this.trade_chart_pipe;
    });
    new Promise((resolve, reject) => {
      this.tradeHistoryService.refreshDepthBookData(this.pairService.getActivePair()?.id, resolve);
    }).then(() => {
      this.depth_book_pipe = !this.depth_book_pipe;
    });

    if (this.config.DELISTING.ART_PRODUCTS.includes(pair.symbol)) {
      this.openModal('delisting-modal');
    }
  }

  setActivePairByArtProductId(art_product_id: number): void {
    this.setActivePair(this.pairService.pair_list.filter((x) => (x.id[0] === art_product_id))[0]);
  }

  setActiveCurrencyPair(currency_pair: CurrencyPair): void {
    this.marketingService.logEvent('currency_pair_change', {currency_pair: currency_pair.symbol});
    this.pairService.setActiveCurrencyPair(currency_pair);
    this.pairService.active_currency_pair_selected.emit();
    this.currency_pair_pipe = !this.currency_pair_pipe;
    new Promise((resolve, reject) => {
      this.tradeHistoryService.refreshSwapHistory(this.pairService.getActiveCurrencyPair()?.id, resolve);
    }).then(() => {
      this.swap_history_pipe = !this.swap_history_pipe;
    });
  }

  setActiveCurrencyPairByCurrencyId(currency_id: number): void {
    this.setActiveCurrencyPair(this.pairService.currency_pair_list.filter((x) => (x.id[0] === currency_id))[0]);
  }

  setActiveAuctionProduct(auction_product: AuctionProduct, refresh_bid_list: boolean = true): void {
    const prev_id = this.auctionService.active_auction_product.id;

    this.auctionService.setActiveAuctionProduct(auction_product);
    this.setActiveAuctionProductConfig();


    this.auctionService.active_auction_product_changed.emit();
    this.auctionService.active_auction_product_pipe = !this.auctionService.active_auction_product_pipe;

    if (refresh_bid_list) {
      this.bidService.refreshBidList(this.auctionService.active_auction_product.id);
    }

    if (auction_product.id !== prev_id) {
      // this.marketingService.logEvent('auction_product_change', {auction_product: auction_product.hash});  // todo
    }
  }

  setActiveAuctionProductConfig(): void {
    this.auctionService.active_auction_product_config = this.getAuctionProductConfig(this.auctionService.active_auction_product.hash);
    this.auctionService.active_auction_catalog_config = this.getAuctionCatalogConfig(this.auctionService.active_auction_product_config.CATALOG_HASH);
    this.auctionService.active_auction_product_artist_config = this.getAuctionArtistConfig(this.auctionService.active_auction_product_config.ARTIST_HASH);
  }


  setActiveAuctionCatalog(auctionCatalog: AuctionCatalog): void {
    this.auctionService.setActiveAuctionCatalog(auctionCatalog);
    // this.auctionService.active_auction_catalog_config = this.getAuctionCatalogConfig(this.auctionService.active_auction_product_config.CATALOG_HASH);
    this.auctionService.active_auction_catalog_config = this.getAuctionCatalogConfig(auctionCatalog.hash);

    this.auctionService.auction_product_list_changed.emit();
    this.auctionService.auction_product_list_pipe = !this.auctionService.auction_product_list_pipe;
  }

  openFiatWithdrawalRequestModal(amount: number): void {
    if (!this.operationsService.selected_user_bank_account_ready) {
      this.popSnackBar(false, this.config.WITHDRAWAL.PLEASE_SELECT_BANK_ACCOUNT);
      return;
    }
    if (amount < 100) {
      this.popSnackBar(false, this.config.WITHDRAWAL.MINIMUM_FIAT_WITHDRAWAL_AMOUNT);
      return;
    }
    this.operationsService.requested_amount = amount;
    this.operationsService.active_withdrawal_type = 'fiat';
    this.operationsService.prepareWithdrawalRequestData(this.operationsService.selected_user_bank_account);
    this.operationsService.currencyWithdrawalRequest();
    this.operationsService.currency_withdrawal_request_completed.pipe(first()).subscribe((response: any) => {
      if (response.is_okay) {
        this.utilityService.openTimer();
        this.openModal('fiat-withdrawal-request-modal');
      } else {
        this.popResponseSnackbar(response);
      }
    });
  }

  closeFiatWithdrawalRequestModal(): void {
    this.utilityService.closeTimer();
    this.closeModal('fiat-withdrawal-request-modal');
  }

  openCryptocurrencyWithdrawalRequestModal(amount: number): void {
    if (!this.operationsService.selected_user_crypto_account_ready) {
      this.popSnackBar(false, this.config.WITHDRAWAL.PLEASE_SELECT_CRYPTO_ACCOUNT);
      return;
    }
    if (amount < 50) {
      this.popSnackBar(false, this.config.WITHDRAWAL.MINIMUM_CRYPTO_WITHDRAWAL_AMOUNT);
      return;
    }
    this.operationsService.requested_amount = amount;
    this.operationsService.active_withdrawal_type = 'crypto';
    this.operationsService.prepareWithdrawalRequestData(this.operationsService.selected_user_crypto_account);
    this.operationsService.currencyWithdrawalRequest();
    this.operationsService.currency_withdrawal_request_completed.pipe(first()).subscribe((response: any) => {
      if (response.is_okay) {
        this.utilityService.openTimer();
        this.openModal('crypto-withdrawal-request-modal');
      } else {
        this.popResponseSnackbar(response);
      }
    });
  }

  openEthereumWithdrawalRequestModal(amount: number): void {
    if (!this.operationsService.selected_user_crypto_account_ready) {
      this.popSnackBar(false, this.config.WITHDRAWAL.PLEASE_SELECT_CRYPTO_ACCOUNT);
      return;
    }
    if (amount < 0.005) {
      this.popSnackBar(false, this.config.WITHDRAWAL.MINIMUM_ETHEREUM_WITHDRAWAL_AMOUNT);
      return;
    }
    this.operationsService.requested_amount = amount;
    this.operationsService.active_withdrawal_type = 'crypto';
    this.operationsService.prepareWithdrawalRequestData(this.operationsService.selected_user_crypto_account);
    this.operationsService.currencyWithdrawalRequest();
    this.operationsService.currency_withdrawal_request_completed.pipe(first()).subscribe((response: any) => {
      if (response.is_okay) {
        this.utilityService.openTimer();
        this.openModal('ethereum-withdrawal-request-modal');
      } else {
        this.popResponseSnackbar(response);
      }
    });
  }

  closeCryptocurrencyWithdrawalRequestModal(): void {
    this.utilityService.closeTimer();
    this.closeModal('crypto-withdrawal-request-modal');
  }

  closeEthereumWithdrawalRequestModal(): void {
    this.utilityService.closeTimer();
    this.closeModal('ethereum-withdrawal-request-modal');
  }

  // setActiveOperationCurrency(currency_id: number): void {
  //   const currency = this.pairService.getCurrencyById(currency_id);
  //   if (currency && this.userService.user) {
  //     this.operationsService.active_currency = currency;
  //     this.operationsService.active_user_currency_wallet = this.userService.user.currency_list[currency_id];
  //   }
  // }

  setUserFiatWalletList(): void {
    if (this.pairService.currency_list_ready && this.userService.user) {
      this.operationsService.user_fiat_currency_wallet_list = [];
      this.pairService.currency_list.filter(e => e.type === 'fiat').map(e => e.id)
        .forEach(i => this.operationsService.user_fiat_currency_wallet_list.push(this.userService.user?.currency_list[i] as CurrencyWallet));
    } else {
      this.pairService.currency_list_changed.pipe(first()).subscribe(() => {
        this.operationsService.user_fiat_currency_wallet_list = [];
        this.pairService.currency_list.filter(e => e.type === 'fiat').map(e => e.id)
          .forEach(i => this.operationsService.user_fiat_currency_wallet_list.push(this.userService.user?.currency_list[i] as CurrencyWallet));
      });
    }
  }

  setUserCryptoWalletList(): void {
    if (this.pairService.currency_list_ready && this.userService.user) {
      this.operationsService.user_crypto_currency_wallet_list = [];
      this.pairService.currency_list.filter(e => e.type === 'crypto').map(e => e.id)
        .forEach(i => this.operationsService.user_crypto_currency_wallet_list.push(this.userService.user?.currency_list[i] as CurrencyWallet));
    } else {
      this.pairService.currency_list_changed.pipe(first()).subscribe(() => {
        this.operationsService.user_crypto_currency_wallet_list = [];
        this.pairService.currency_list.filter(e => e.type === 'crypto').map(e => e.id)
          .forEach(i => this.operationsService.user_crypto_currency_wallet_list.push(this.userService.user?.currency_list[i] as CurrencyWallet));
      });
    }
  }

  checkURLPrefix(url: string): boolean {
    return this.router.url.substr(0, url.length) === url;
  }

  evaluateString(_string: string, tokens: string[], replacements: any[]): string {
    for (let i = 0; i < tokens.length; i++) {
      _string = _string.replace(tokens[i], replacements[i]);
    }
    return _string;
  }

  preSaleBuyCost(amount: number): number {
    if (this.pairService.active_featured_pair?.sale_state === 'pre_sale') {
      return this.preciseRound(amount * this.pairService.active_featured_pair?.pre_sale_price, 2);
    } else if (this.pairService.active_featured_pair?.sale_state === 'public_sale') {
      return this.preciseRound(amount * this.pairService.active_featured_pair?.public_sale_price, 2);
    }
    return 0;
  }

  checkPreSaleBuyCost(amount: number): number {
    if (amount < 0) {
      return 0;
    } else if (amount > this.getUserActiveCurrencyAvailableAmount()) {
      return this.preciseRound(this.getUserActiveCurrencyAvailableAmount());
    } else {
      if (amount <= 0.01 && amount >= 0.0001) {
        return this.preciseRound(amount, 4);
      } else {
        return this.preciseRound(amount);
      }
    }
  }

  marketBuyAverageCost(amount: number): number {
    let market_buy_average_cost = 0;
    for (const sell_order of this.orderBookService.sell_order_book) {
      if (sell_order.amount > amount) {
        market_buy_average_cost += amount * sell_order.price;
        break;
      } else {
        amount = amount - sell_order.amount;
        market_buy_average_cost += sell_order.amount * sell_order.price;
      }
    }
    return this.preciseRound(market_buy_average_cost, 2);
  }

  marketSellAverageCost(amount: number): number {
    let sell_market_average_cost = 0;
    for (const buy_order of this.orderBookService.buy_order_book) {
      if (buy_order.amount > amount) {
        sell_market_average_cost += amount * buy_order.price;
        break;
      } else {
        amount = amount - buy_order.amount;
        sell_market_average_cost += buy_order.amount * buy_order.price;
      }
    }
    return this.preciseRound(sell_market_average_cost, 2);
  }

  checkMarketBuyAverageCost(amount: number): number {
    if (amount < 0) {
      return 0;
    } else if (amount > this.getUserActiveCurrencyAvailableAmount()) {
      return this.preciseRound(this.getUserActiveCurrencyAvailableAmount());
    } else {
      return this.preciseRound(amount);
    }
  }

  checkMarketSellAverageCost(amount: number): number {
    if (amount < 0) {
      return 0;
    } else if (amount > this.getUserActiveArtProductAvailableAmount()) {
      return this.preciseRound(this.getUserActiveArtProductAvailableAmount());
    } else {
      return this.preciseRound(amount);
    }
  }

  checkMarketBuyByAmount(amount: number): [amount: number, total: number] {
    if (amount < 0) {
      return [0, 0];
    } else {
      let newAmount = 0;
      let totalCurrency = this.getUserActiveCurrencyAvailableAmount();
      for (const sell_order of this.orderBookService.sell_order_book) {
        const rowTotal = sell_order.amount * sell_order.price;
        if (amount > 0.01) {
          if (totalCurrency > 0.01 * sell_order.price) {
            if (amount > sell_order.amount) {
              if (totalCurrency > rowTotal) {
                totalCurrency -= rowTotal;
                newAmount += sell_order.amount;
                amount -= sell_order.amount;
              } else {
                const subAmount = this.preciseRound(totalCurrency / sell_order.price);
                totalCurrency -= subAmount * sell_order.price;
                newAmount += subAmount;
                break;
              }
            } else {
              if (totalCurrency >= amount * sell_order.price) {
                totalCurrency -= amount * sell_order.price;
                newAmount += amount;
                break;
              } else {
                const subAmount = this.preciseRound(totalCurrency / sell_order.price);
                totalCurrency -= subAmount * sell_order.price;
                newAmount += subAmount;
                break;
              }
            }
          } else {
            break;
          }
        } else {
          break;
        }
      }
      return [Number(newAmount.toFixed(4)), Number((this.getUserActiveCurrencyAvailableAmount() - totalCurrency).toFixed(4))];
    }
  }

  checkMarketSellByAmount(amount: number): [amount: number, total: number] {
    if (amount < 0) {
      return [0, 0];
    } else {
      if (amount > this.getUserActiveArtProductAvailableAmount()) {
        amount = this.getUserActiveArtProductAvailableAmount();
      }
      let subAmount = amount;
      let newTotal = 0;
      for (const buy_order of this.orderBookService.buy_order_book) {
        if (subAmount > 0.01) {
          if (subAmount > buy_order.amount) {
            newTotal += buy_order.amount * buy_order.price;
            subAmount -= buy_order.amount;
          } else {
            newTotal += subAmount * buy_order.price;
            break;
          }
        } else {
          break;
        }
      }
      return [Number(amount.toFixed(4)), Number(newTotal.toFixed(4))];
    }
  }

  checkMarketBuyByTotal(total: number): [amount: number, total: number] {
    if (total < 0) {
      return [0, 0];
    } else {
      let newAmount = 0;
      let newTotal = 0;
      if (total > this.getUserActiveCurrencyAvailableAmount()) {
        total = this.getUserActiveCurrencyAvailableAmount();
      }
      for (const sell_order of this.orderBookService.sell_order_book) {
        const rowTotal = sell_order.amount * sell_order.price;
        if (total > sell_order.price * 0.01) {
          if (total > rowTotal) {
            newAmount += sell_order.amount;
            newTotal += rowTotal;
            total -= rowTotal;
          } else {
            const subAmount = this.preciseRound(total / sell_order.price);
            newAmount += subAmount;
            newTotal += subAmount * sell_order.price;
            break;
          }
        } else {
          break;
        }
      }
      newAmount = this.preciseRound(newAmount);
      newTotal = this.preciseRound(newTotal);
      return [newAmount, newTotal];
    }
  }

  checkMarketSellByTotal(total: number): [amount: number, total: number] {
    if (total < 0) {
      return [0, 0];
    } else {
      let amount = 0;
      let remainAmount = this.getUserActiveArtProductAvailableAmount();
      let newTotal = 0;
      for (const buy_order of this.orderBookService.buy_order_book) {
        const subTotal = buy_order.price * buy_order.amount;
        if (total > buy_order.price * 0.01) {
          if (total > subTotal) {
            if (remainAmount <= buy_order.amount) {
              amount += remainAmount;
              newTotal += remainAmount * buy_order.price;
              break;
            } else {
              amount += buy_order.amount;
              remainAmount -= buy_order.amount;
              newTotal += subTotal;
              total -= subTotal;
            }
          } else {
            const subAmount = this.preciseRound(total / buy_order.price);
            if (remainAmount <= subAmount) {
              amount += remainAmount;
              newTotal += remainAmount * buy_order.price;
              break;
            } else {
              amount += subAmount;
              remainAmount -= subAmount;
              newTotal += subAmount * buy_order.price;
              total -= subAmount * buy_order.price;
              break;
            }
          }
        } else {
          break;
        }
      }
      return [Number(amount.toFixed(4)), Number(newTotal.toFixed(4))];
    }
  }

  checkLimitBuyAmount(price: number, amount: number): number {
    if (amount < 0) {
      return 0;
    } else if (price * amount > this.getUserActiveCurrencyAvailableAmount()) {
      return this.preciseRound(this.getUserActiveCurrencyAvailableAmount() / price);
    } else {
      return this.preciseRound(amount);
    }
  }

  checkLimitSellAmount(price: number, amount: number): number {
    if (amount < 0) {
      return 0;
    } else if (amount > this.getUserActiveArtProductAvailableAmount()) {
      return this.preciseRound(this.getUserActiveArtProductAvailableAmount());
    } else {
      return this.preciseRound(amount);
    }
  }

  checkLimitBuyTotal(price: number, total: number): number {
    if (total < 0) {
      return 0;
    } else if (total > this.getUserActiveCurrencyAvailableAmount()) {
      const amount = this.preciseRound(this.getUserActiveCurrencyAvailableAmount() / price);
      return this.preciseRound(amount * price, 4);
    } else {
      return this.preciseRound(total, 4);
    }
  }

  checkLimitSellTotal(price: number, total: number): number {
    if (total < 0) {
      return 0;
    } else if (total > this.getUserActiveArtProductAvailableAmount() * price) {
      return this.preciseRound(this.getUserActiveArtProductAvailableAmount() * price);
    } else {
      return this.preciseRound(total);
    }
  }

  checkMarketBuyTotal(total: number): number {
    if (total < 0) {
      return 0;
    } else if (total > this.getUserActiveCurrencyAvailableAmount()) {
      return this.preciseRound(this.getUserActiveCurrencyAvailableAmount());
    } else {
      return this.preciseRound(total);
    }
  }

  amountPercentage(amount: number): number {
    if (this.preciseRound(amount / this.preciseRound(this.getUserActiveArtProductAvailableAmount()) * 100)) {
      return this.preciseRound(amount / this.preciseRound(this.getUserActiveArtProductAvailableAmount()) * 100);
    } else {
      return 0;
    }
  }

  totalPercentage(total: number): number {
    if (this.preciseRound(total / this.preciseRound(this.getUserActiveCurrencyAvailableAmount()) * 100)) {
      return this.preciseRound(total / this.preciseRound(this.getUserActiveCurrencyAvailableAmount()) * 100);
    } else {
      return 0;
    }
  }

  setActiveTradeKindPanel(trade_kind_panel: ActiveTradeKindPanelTemplate): void {
    this.active_trade_kind_panel = trade_kind_panel;
    this.active_trade_kind_panel_changed.emit();
  }

  setSliderBgByRatio(element: any, start_color: string, end_color: string, ratio: number): void {
    element.nativeElement.style.background = `linear-gradient(to right, ${start_color}, ${start_color} ${ratio}%, ${end_color} ${ratio}%, ${end_color} 100%)`;
  }

  getUserActiveArtProductAvailableAmount(): number {
    if (this.userService.user !== undefined) {
      for (const art_product_wallet of this.userService.user.art_product_list) {
        if (art_product_wallet.art_product_id === this.pairService.getActivePair()?.id[0]) {
          return art_product_wallet.available_amount;
        }
      }
    }
    return 0;
  }

  getUserActiveCurrencyAvailableAmount(): number {
    if (this.userService.user !== undefined) {
      for (const currency_wallet of this.userService.user.currency_list) {
        if (currency_wallet.currency_id === this.pairService.getActivePair()?.id[1]) {
          return currency_wallet.available_amount;
        }
      }
    }
    return 0;
  }

  getUserRootCurrencyAvailableAmount(): number {
    if (this.userService.user !== undefined) {
      for (const currency_wallet of this.userService.user.currency_list) {
        if (currency_wallet.currency_id === this.pairService.root_currency?.id) {
          return currency_wallet.available_amount;
        }
      }
    }
    return 0;
  }

  checkFiatWithdrawalAmount(amount: number): number {
    if (amount < 0) {
      return 0;
    } else if (amount > this.getUserRootCurrencyAvailableAmount()) {
      return this.preciseRound(this.getUserRootCurrencyAvailableAmount());
    } else {
      return this.preciseRound(amount);
    }
  }

  checkCryptoWithdrawalAmount(amount: number, currency_id: number, decimal: number = 2): number {
    if (!this.userService.user) {
      return 0;
    }
    if (amount < 0) {
      return 0;
    } else if (amount > this.userService.user.getCryptocurrencyAvailableAmount(currency_id)) {
      return this.preciseRound(this.userService.user.getCryptocurrencyAvailableAmount(currency_id), decimal);
    } else {
      return this.preciseRound(amount, decimal);
    }
  }


  popSnackBar(status: boolean, message: string): void {
    this.snackBarService.push(status, status ? this.config.SNACK_BAR.SUCCESS : this.config.SNACK_BAR.FAIL, message);
  }

  public popResponseSnackbar = (response: any) => {
    // @ts-ignore
    const content = this.config.RESPONSE_TABLE[response.code];
    this.popSnackBar(content.is_okay, this.codeHandler(response));
  }


  copyToClipboard(copy_text: string, success_text: string = ''): void {
    this.clipboard.copy(copy_text);  // navigator.clipboard or this.clipboard ?
    if (success_text) {
      this.popSnackBar(true, success_text);
    }
  }

  pasteFromClipboard(callback: ((text: string) => void)): void {
    // if (this.environment.app) {
    //     this.cordova.plugins.clipboard.paste((text: any) => {
    //             callback(text);
    //         }
    //     );
    // } else {
    navigator.clipboard.readText().then((text) => {  // navigator.clipboard or this.clipboard ?
      callback(text);
    });  // navigator.clipboard or this.clipboard ?
    // }
  }

  setUserFavoritePair(pair: Pair): void {
    if (this.logged_in) {
      if (this.userService.getUser()?.isPairFavorite(pair) === undefined) {
        return;
      }
      const is_fav = this.userService.getUser()?.isPairFavorite(pair);
      if (is_fav === undefined) {
        return;
      }

      const data = {pair_id: pair.id, is_favorite: !is_fav};
      this.httpClient.post<any>(this.environment.serverAPI + 'set_user_favorite_pair_service', data).subscribe(response => {
        this.marketingService.logEvent('pair_favorite', {pair_id: pair.id, is_favorite: !is_fav});
      });
    }
  }

  setUserFavoriteAuctionProduct(auction_product: AuctionProduct): void {
    if (this.logged_in) {
      const is_fav = this.userService.user?.isAuctionProductFavorite(auction_product);
      if (is_fav === undefined) {
        return;
      }

      const data = {auction_product_id: auction_product.id, is_favorite: !is_fav};
      this.httpClient.post<any>(this.environment.serverAPI + 'set_user_favorite_auction_product_service', data).subscribe(response => {
        this.marketingService.logEvent('auction_product_favorite', {
          auction_product_id: auction_product.id,
          is_favorite: !is_fav
        });
      });
    }
  }

  setPairListSortingType(pair_list_sorting_type: PairListSortingTypeTemplate): void {
    if (!this.logged_in && pair_list_sorting_type === PairListSortingTypeTemplate.FAVORITE) {
      return;
    }
    if (this.pair_list_sorting_type !== pair_list_sorting_type) {
      this.pair_list_sorting_type = pair_list_sorting_type;
      this.pair_list_sorting_direction = PairListSortingDirectionTemplate.UP;
    } else {
      if (this.pair_list_sorting_direction !== PairListSortingDirectionTemplate.DOWN) {
        this.pair_list_sorting_direction = PairListSortingDirectionTemplate.DOWN;
      } else {
        this.pair_list_sorting_direction = PairListSortingDirectionTemplate.UP;
      }
    }
  }

  sortPairList(): Pair[] {
    if (this.pair_list_sorting_type === PairListSortingTypeTemplate.PAIR) {
      if (this.pair_list_sorting_direction !== PairListSortingDirectionTemplate.DOWN) {
        return this.pairService.getListedPairList().sort((x, y) => {
          return x.symbol.localeCompare(y.symbol);
        });
      } else {
        return this.pairService.getListedPairList().sort((x, y) => {
          return y.symbol.localeCompare(x.symbol);
        });
      }

    } else if (this.pair_list_sorting_type === PairListSortingTypeTemplate.PRICE) {
      if (this.pair_list_sorting_direction !== PairListSortingDirectionTemplate.DOWN) {
        return this.pairService.getListedPairList().sort((x, y) => {
          return x.price - y.price;
        });
      } else {
        return this.pairService.getListedPairList().sort((x, y) => {
          return y.price - x.price;
        });
      }
    } else if (this.pair_list_sorting_type === PairListSortingTypeTemplate.CHANGE) {
      if (this.pair_list_sorting_direction !== PairListSortingDirectionTemplate.DOWN) {
        return this.pairService.getListedPairList().sort((x, y) => {
          return x.change - y.change;
        });
      } else {
        return this.pairService.getListedPairList().sort((x, y) => {
          return y.change - x.change;
        });
      }

    } else if (this.logged_in && this.pair_list_sorting_type === PairListSortingTypeTemplate.FAVORITE) {
      return this.pairService.getListedPairList().filter((x) => {
        return this.pair_list_sorting_direction !== PairListSortingDirectionTemplate.UP || this.userService.getUser()?.isPairFavorite(x);
      });
    } else {
      return this.pairService.getListedPairList();
    }
  }

  sortCurrencyPairList(): CurrencyPair[] {
    return this.pairService.getCurrencyPairList().sort((x, y) => {
      return y.symbol.localeCompare(x.symbol);
    });
  }

  artProductToRootCurrencyMultiplier(art_product_id: number): number {
    const pair = this.pairService.pair_list.filter((x) => (x.id[0] === art_product_id))[0];
    return pair.price * this.currencyToRootCurrencyMultiplier(pair.id[1]);
  }

  currencyToRootCurrencyMultiplier(currency_id: number): number {
    if (currency_id === this.pairService.root_currency?.id) {
      return 1;
    } else {
      return this.pairService.currency_pair_list.filter((x) => x.id[0] === currency_id)[0].price;
    }
  }

  getUserTotalArtProductTypeOfRootCurrency(): number {
    if (this.userService.user !== undefined) {
      let total_art_product_type_of_root_currency_value = 0;
      for (const art_product_wallet of this.userService.user.art_product_list) {
        total_art_product_type_of_root_currency_value = total_art_product_type_of_root_currency_value +
          (art_product_wallet.total_amount * this.artProductToRootCurrencyMultiplier(art_product_wallet.art_product_id));
      }
      return total_art_product_type_of_root_currency_value;
    }
    return 0;
  }

  getUserTotalCurrencyTypeOfRootCurrency(): number {
    if (this.userService.user !== undefined) {
      let total_currency_type_of_root_currency_value = 0;
      for (const currency_wallet of this.userService.user.currency_list) {
        total_currency_type_of_root_currency_value = total_currency_type_of_root_currency_value +
          (currency_wallet.total_amount * this.currencyToRootCurrencyMultiplier(currency_wallet.currency_id));
      }
      return total_currency_type_of_root_currency_value;
    }
    return 0;
  }

  getUserTotalFiatCurrencyTypeOfRootCurrency(): number {
    if (this.userService.user !== undefined) {
      this.setUserFiatWalletList();

      let total_currency_type_of_root_currency_value = 0;
      for (const currency_wallet of this.operationsService.user_fiat_currency_wallet_list) {
        total_currency_type_of_root_currency_value = total_currency_type_of_root_currency_value +
          (currency_wallet.total_amount * this.currencyToRootCurrencyMultiplier(currency_wallet.currency_id));
      }
      return total_currency_type_of_root_currency_value;
    }
    return 0;
  }

  getUserTotalCryptoCurrencyTypeOfRootCurrency(): number {
    if (this.userService.user !== undefined) {
      this.setUserCryptoWalletList();

      let total_currency_type_of_root_currency_value = 0;
      for (const currency_wallet of this.operationsService.user_crypto_currency_wallet_list) {
        total_currency_type_of_root_currency_value = total_currency_type_of_root_currency_value +
          (currency_wallet.total_amount * this.currencyToRootCurrencyMultiplier(currency_wallet.currency_id));
      }
      return total_currency_type_of_root_currency_value;
    }
    return 0;
  }

  getUserCryptoCurrencyTypeOfRootCurrency(currency_id: number): number {
    if (this.userService.user !== undefined) {
      const currency_wallet = this.userService.user.currency_list.filter(x => x.currency_id === currency_id)[0];
      return currency_wallet.total_amount * this.currencyToRootCurrencyMultiplier(currency_wallet.currency_id);
    }
    return 0;
  }

  prepareUserBalancePieChartData(ctx: any, values: any[], colors: any[], labels: any[]): any {
    if (this.userService.user !== undefined && this.pairService.pair_list_ready && this.pairService.currency_pair_list_ready) {
      const art_product_amount_list = this.userService.user.art_product_list.map(x => x.total_amount);
      const currency_amount_list = this.userService.user.currency_list.map(x => x.total_amount);
      const amount_list = art_product_amount_list.concat(currency_amount_list);

      const art_product_value_list = this.pairService.art_product_list.filter(x => x.id !== -1).map(x => this.artProductToRootCurrencyMultiplier(x.id));
      const currency_value_list = this.pairService.currency_list.filter(x => x.id !== -1).map(x => this.currencyToRootCurrencyMultiplier(x.id));
      const value_list = art_product_value_list.concat(currency_value_list);

      const art_product_label_list = this.pairService.art_product_list.filter(x => x.id !== -1).map(x => x.symbol);
      const currency_label_list = this.pairService.currency_list.filter(x => x.id !== -1).map(x => x.symbol);
      const label_list = art_product_label_list.concat(currency_label_list);

      const art_product_color_list = this.pairService.art_product_list.filter(x => x.id !== -1).map(x => x.color);
      const currency_color_list = this.pairService.currency_list.filter(x => x.id !== -1).map(x => x.color);
      const color_list = art_product_color_list.concat(currency_color_list);

      for (let index = 0; index < amount_list.length; index++) {
        values.push(this.preciseRound(amount_list[index] * value_list[index]));
        const color = ctx.createLinearGradient(0, 0, 200, 200);
        color.addColorStop(0, color_list[index][0]);
        color.addColorStop(1, color_list[index][1]);
        colors.push(color);
        labels.push(label_list[index]);
      }
      return true;
    }
    return false;
  }

  prepareUserBalancePieChartValues(values: any[]): any {
    if (this.userService.user !== undefined && this.pairService.pair_list_ready && this.pairService.currency_pair_list_ready) {
      const art_product_amount_list = this.userService.user.art_product_list.map(x => x.total_amount);
      const currency_amount_list = this.userService.user.currency_list.map(x => x.total_amount);
      const amount_list = art_product_amount_list.concat(currency_amount_list);

      const art_product_value_list = this.pairService.art_product_list.map(x => this.artProductToRootCurrencyMultiplier(x.id));
      const currency_value_list = this.pairService.currency_list.map(x => this.currencyToRootCurrencyMultiplier(x.id));
      const value_list = art_product_value_list.concat(currency_value_list);

      values.length = 0; // clear array with out losing ref
      for (let index = 0; index < amount_list.length; index++) {
        values.push(this.preciseRound(amount_list[index] * value_list[index]));
      }
      return true;
    }
    return false;
  }

  prepareUserBalanceHistoryChartData(labels: any[], values: any[]): boolean {
    if (this.userService.user !== undefined && this.userService.user_balance_history_ready) {
      for (const user_balance_history_entry of this.userService.user_balance_history) {
        labels.push(user_balance_history_entry.creation_time);
        values.push(this.preciseRound(user_balance_history_entry.total_balance));
      }
      return true;
    }
    return false;
  }

  prepareUserBalanceHistoryChartValues(labels: any[], values: any[]): boolean {
    labels.length = 0; // clear array with out losing ref
    values.length = 0; // clear array with out losing ref
    if (this.userService.user !== undefined && this.userService.user_balance_history_ready) {
      for (const user_balance_history_entry of this.userService.user_balance_history) {
        labels.push(user_balance_history_entry.creation_time);
        values.push(this.preciseRound(user_balance_history_entry.total_balance));
      }
      return true;
    }
    return false;
  }

  // getAuctionCatalogState(catalog: AuctionCatalog): 'catalog'| 'live' | 'knockdown'  {
  //     const now = UtilityService.currentTimestamp();
  //
  //     if (now < catalog.auction_start_date + this.utilityService.global_timezone_offset) {
  //         return 'catalog';
  //     } else if (now > catalog.auction_end_date + this.utilityService.global_timezone_offset) {
  //         return 'knockdown';
  //     } else {
  //         return 'live';
  //     }
  // }

  navigationManager(object: any = {}): void {
    if (!object.url) {
      return;
    }

    if (object.is_inner !== undefined || object.target !== undefined) {
      this.navigationService.navigateNewPage(this.environment.app, object);
      return;
    }

    if (JSON.stringify(object) === this.navigationService.raw_current_routing) {
      return;
    }

    this.navigationService.raw_current_routing = JSON.stringify(object);
    if (object.url.startsWith('/auction')) {
      if (localStorage.getItem('auction_agreement_checked') !== 'true') {
        if (this.userService.user_ready) {
          new Promise((resolve, reject) => {
            this.userService.isAuctionRulesAccepted(resolve, reject);
          }).then(() => {
            localStorage.setItem('auction_agreement_checked', 'true');
          }, () => {
            this.modalService.open('auction-agreement-modal');
          });
        } else {
          this.userService.user_changed.pipe(first()).subscribe(() => {
            new Promise((resolve, reject) => {
              this.userService.isAuctionRulesAccepted(resolve, reject);
            }).then(() => {
              localStorage.setItem('auction_agreement_checked', 'true');
            }, () => {
              this.modalService.open('auction-agreement-modal');
            });
          });
        }
      }
    } else if (object.url.startsWith('/one-pager')) {
      setTimeout(() => {
        this.openModal('one-pager-modal');
      }, 300);
    } else if (object.url.startsWith('/about-artist')) {
      this.navigationService.active_corporate_page = CorporatePageTemplate.ANNOUNCEMENT;
      this.navigationService.artist_announcement_active = true;
      this.navigationService.operation_announcement_active = false;
      if (object.artist_announcement_index !== undefined) {
        this.navigationService.setActiveArtist(object.artist_announcement_index);
      } else {
        this.navigationService.resetActiveArtist();
      }
    } else if (object.url.startsWith('/corporate')) {
      if (this.config.ZENDESK_ACTIVE) {
        if (object.url.startsWith('/corporate/about')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/articles/4414402456465-K%C4%B1saca-Biz',
            is_inner: false,
            target: '_self'
          };
        } else if (object.url.startsWith('/corporate/announcement')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/categories/4414300043025-Duyurular',
            is_inner: false,
            target: '_self'
          };
        } else if (object.url.startsWith('/corporate/general-announcements')) {
          object = {url: 'https://support.artiox.com/hc/tr', is_inner: false, target: '_self'};
        } else if (object.url.startsWith('/corporate/about-artist')) {
          this.navigationService.active_corporate_page = CorporatePageTemplate.ANNOUNCEMENT;
          this.navigationService.artist_announcement_active = true;
          this.navigationService.operation_announcement_active = false;
          this.navigationService.resetActiveArtist();  // todo artist_announcement_index
          object = {url: '/about-artist', current_component_index: 0};
        } else if (object.url.startsWith('/corporate/career')) {
          object = {url: '/career', current_component_index: 0};
        } else if (object.url.startsWith('/corporate/contact')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/articles/4423184358161-%C4%B0leti%C5%9Fim',
            is_inner: false,
            target: '_self'
          };
        } else {
          object = {url: 'https://support.artiox.com/hc/tr', is_inner: false, target: '_self'};
        }

        this.navigationManager(object);
      } else {
        if (object.url.startsWith('/corporate/announcement')) {
          this.navigationService.active_corporate_page = CorporatePageTemplate.ANNOUNCEMENT;
          this.navigationService.operation_announcement_active = false;
          this.navigationService.artist_announcement_active = false;
          this.navigationService.resetAnnouncements();
        } else if (object.url.startsWith('/corporate/general-announcements')) {
          this.navigationService.active_corporate_page = CorporatePageTemplate.ANNOUNCEMENT;
          this.navigationService.operation_announcement_active = true;
          this.navigationService.artist_announcement_active = false;
        } else if (object.url.startsWith('/corporate/about-artist')) {
          this.navigationService.active_corporate_page = CorporatePageTemplate.ANNOUNCEMENT;
          this.navigationService.artist_announcement_active = true;
          this.navigationService.operation_announcement_active = false;
          this.navigationService.resetActiveArtist();  // todo artist_announcement_index
        } else if (object.url.startsWith('/corporate/career')) {
          this.navigationService.active_corporate_page = CorporatePageTemplate.CAREER;
        } else if (object.url.startsWith('/corporate/contact')) {
          this.navigationService.active_corporate_page = CorporatePageTemplate.CONTACT;
        } else {
          this.navigationService.active_corporate_page = CorporatePageTemplate.ABOUT;
        }
      }
    } else if (object.url.startsWith('/service')) {
      if (this.config.ZENDESK_ACTIVE) {
        if (object.url.startsWith('/service/commissions')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/articles/4414483870865-Komisyonlar',
            is_inner: false,
            target: '_self'
          };
        } else if (object.url.startsWith('/service/rules')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/articles/4414505009681-Limitler-ve-Kurallar',
            is_inner: false,
            target: '_self'
          };
        } else {
          object = {url: 'https://support.artiox.com/hc/tr', is_inner: false, target: '_self'};
        }
        this.navigationManager(object);
      } else {
        if (object.url.startsWith('/service/commissions')) {
          this.navigationService.active_service_page = ServicePageTemplate.COMMISSIONS;
        } else if (object.url.startsWith('/service/rules')) {
          this.navigationService.active_service_page = ServicePageTemplate.RULES;
        }
      }
    } else if (object.url.startsWith('/sign-up')) {
      object = {
        url: 'https://tr.artiox.com/indir',
        is_inner: false,
        target: '_self'
      };
      this.navigationManager(object);
    }

    Object.entries(object).forEach(([key, value]) => {
      if (key === 'active_pair_id') {
        // @ts-ignore
        let pair = this.pairService.getPairById(value) ?? this.pairService.getPairList()[this.pairService.getPairList().length - 1];
        const listed_pair_list = this.pairService.getListedPairList();
        if (listed_pair_list.includes(pair)) {
          object.pair = '/' + pair.symbol.replace('/', '_');
        } else {
          pair = listed_pair_list[listed_pair_list.length - 1];
        }
        this.setActivePair(pair);
      } else if (key === 'active_pair_symbol') {
        // @ts-ignore
        let pair = this.pairService.getPairBySymbol(value.replace('_', '/')) ?? this.pairService.getPairList()[this.pairService.getPairList().length - 1];
        const listed_pair_list = this.pairService.getListedPairList();
        if (listed_pair_list.includes(pair)) {
          object.pair = '/' + pair.symbol.replace('/', '_');
        } else {
          pair = listed_pair_list[listed_pair_list.length - 1];
        }
        this.setActivePair(pair);
      } else if (key === 'active_featured_pair_id') {
        // @ts-ignore
        let pair = this.pairService.getPairById(value) ?? this.pairService.getFeaturedPairList()[this.pairService.getFeaturedPairList().length - 1];
        const pair_list = this.pairService.getNotDelistedPairList();
        if (pair_list.includes(pair)) {
          object.pair = '/' + pair.symbol.replace('/', '_');
        } else {
          pair = pair_list[pair_list.length - 1];
        }
        this.pairService.setActiveFeaturedPair(pair);
      } else if (key === 'active_featured_pair_symbol') {
        // @ts-ignore
        let pair = this.pairService.getPairBySymbol(value.replace('_', '/')) ?? this.pairService.getFeaturedPairList()[this.pairService.getFeaturedPairList().length - 1];
        const pair_list = this.pairService.getNotDelistedPairList();
        if (pair_list.includes(pair)) {
          object.pair = '/' + pair.symbol.replace('/', '_');
        } else {
          pair = pair_list[pair_list.length - 1];
        }
        this.pairService.setActiveFeaturedPair(pair);
      } else if (key === 'active_auction_catalog_hash') {
        // @ts-ignore
        const auction_catalog = this.auctionService.auction_catalog_list[value];
        object.auction_catalog = '/' + value;
        this.setActiveAuctionCatalog(auction_catalog);
      } else if (key === 'active_auction_product_hash') {
        // @ts-ignore
        const auction_product = this.auctionService.auction_product_list.getAuctionProductByHash(value);
        object.auction_product = '/' + value;
        this.setActiveAuctionProduct(auction_product);
      } else if (key === 'active_deposit_page') {
        if (value === DepositPageTemplate.TURKISH_LIRA) {
          this.navigationService.active_deposit_page = DepositPageTemplate.TURKISH_LIRA;
        } else if (value === DepositPageTemplate.TETHER) {
          this.navigationService.active_deposit_page = DepositPageTemplate.TETHER;
        } else if (value === DepositPageTemplate.ETHEREUM) {
          this.navigationService.active_deposit_page = DepositPageTemplate.ETHEREUM;
        }
      } else if (key === 'active_withdrawal_page') {
        if (value === WithdrawalPageTemplate.TURKISH_LIRA) {
          this.navigationService.active_withdrawal_page = WithdrawalPageTemplate.TURKISH_LIRA;
        } else if (value === WithdrawalPageTemplate.TETHER) {
          this.navigationService.active_withdrawal_page = WithdrawalPageTemplate.TETHER;
        } else if (value === WithdrawalPageTemplate.ETHEREUM) {
          this.navigationService.active_withdrawal_page = WithdrawalPageTemplate.ETHEREUM;
        }
      } else if (key === 'active_account_auction_page') {
        if (value === ActiveAccountAuctionPanelTemplate.MY_BIDS) {
          this.active_account_auction_panel = ActiveAccountAuctionPanelTemplate.MY_BIDS;
        } else if (value === ActiveAccountAuctionPanelTemplate.MY_FOLLOWINGS) {
          this.active_account_auction_panel = ActiveAccountAuctionPanelTemplate.MY_FOLLOWINGS;
        } else if (value === ActiveAccountAuctionPanelTemplate.MY_WONS) {
          this.active_account_auction_panel = ActiveAccountAuctionPanelTemplate.MY_WONS;
        }
      } else if (key === 'active_security_panel') {
        if(value === ActiveSecurityPanelTemplate.IDENTITY_VERIFICATION){
          object = {url: '/download-app', current_component_index: 0};
        } else {
          // @ts-ignore
          this.active_security_panel = value;
        }
      } else if (key === 'artist_announcement_index') {
        this.navigationService.active_corporate_page = CorporatePageTemplate.ANNOUNCEMENT;
        this.navigationService.artist_announcement_active = true;
        this.navigationService.operation_announcement_active = false;
        // @ts-ignore
        this.navigationService.setActiveArtist(value);
      } else if (key === 'operation_announcement_index') {
        this.navigationService.active_corporate_page = CorporatePageTemplate.ANNOUNCEMENT;
        this.navigationService.operation_announcement_active = true;
        this.navigationService.artist_announcement_active = false;
        // @ts-ignore
        this.navigationService.setActiveOperation(value);
      } else if (key === 'verification_code') {
        this.userService.setUserAccountVerificationCredentials(object.user_email, object.verification_code);
        // this.userService.user_account_verification_credentials = new UserAccountVerificationCredentials({user_email: object.user_email, verification_code: object.verification_code});
      } else if (key === 'language') {
        // @ts-ignore
        this.setLanguage(value);
      }
    });
    this.navigationService.navigate(object);
  }

  handleRouting(params: any): void {
    const url = this._location.path();
    const path = url.split('/');
    const param = path[path.length - 1].split('?')[0];
    let object: any = null;

    if (url.startsWith('/exchange/basic')) {
      if (param === 'basic') {
        object = {url: '/exchange/basic', current_component_index: 0};
      } else {
        object = {url: '/exchange/basic', active_pair_symbol: param, current_component_index: 1};
      }
    } else if (url.startsWith('/exchange/advanced')) {
      if (param === 'advanced') {
        object = {url: '/exchange/advanced', current_component_index: 0};
      } else {
        object = {url: '/exchange/advanced', active_pair_symbol: param, current_component_index: 1};
      }
    } else if (url.startsWith('/presale')) {
      if (param === 'presale') {
        object = {url: '/presale', current_component_index: 0};
      } else {
        object = {url: '/presale', active_featured_pair_symbol: param, current_component_index: 0};
      }
    } else if (url.startsWith('/auction')) {
      if (url.startsWith('/auction/catalog')) {
        if (param === 'catalog') {
          object = {url: '/auction/catalog', current_component_index: 1};
        } else {
          object = {url: '/auction/catalog', active_auction_catalog_hash: param, current_component_index: 2};
        }
      } else if (url.startsWith('/auction/auction_product')) {
        if (param === 'auction_product') {
          object = {url: '/auction/auction_product', current_component_index: 3};
        } else {
          object = {
            url: '/auction/auction_product',
            active_auction_product_hash: param,
            current_component_index: 3
          };
        }
      } else {
        if (this.view_port === 'desktop') {
          object = {url: '/auction/catalog', current_component_index: 1};
        } else {
          object = {url: '/auction', current_component_index: 0};
        }
      }
    } else if (url.startsWith('/account')) {  // ?
      if (param === 'account') {
        object = {url: '/account', current_component_index: 0};
      } else if (url.startsWith('/account-verification')) {
        if (!params.email || !params.verification_code) {
          object = {url: '/login', current_component_index: 0};
        } else {
          object = {url: '/account-verification', user_email: params.email, verification_code: params.verification_code, current_component_index: 0};
        }
      } else {
        object = {url: '/account/' + param, current_component_index: 0};
      }
    } else if (url.startsWith('/payment/auction')) {
      object = {url: '/account/auction', active_account_auction_page: ActiveAccountAuctionPanelTemplate.MY_WONS, current_component_index: 0};  // exceptional, only reach with gui
      // this.userService.user_deliveries_changed.pipe(first()).subscribe(() => {  // todo
      //   object = {url: '/payment/auction', active_auction_product_hash: param, current_component_index: 0};
      //   this.navigationManager(object);
      // });
    } else if (url.startsWith('/sss')) {
      const currentComponentIndex = this.config_tr.SUPPORT.CATEGORIES.findIndex((category) => category.PATH === param
      )
      if (currentComponentIndex === -1) {
        object = {url: '/sss', current_component_index: 0};
      } else {
        object = {url: `/sss/${param}`, current_component_index: currentComponentIndex};
      }
    } else if (url.startsWith('/one-pager')) {
      setTimeout(() => {
        this.openModal('one-pager-modal');
      }, 300);
    } else if (url.startsWith('/about-artist')) {
      if (param === 'about-artist') {
        object = {url: '/about-artist', current_component_index: 0};
      } else {
        object = {
          url: '/about-artist',
          artist_announcement_index: parseInt(param, 10),
          current_component_index: 0
        };
      }
    } else if (url.startsWith('/corporate')) {
      if (this.config.ZENDESK_ACTIVE) {
        if (url.startsWith('/corporate/about')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/articles/4414402456465-K%C4%B1saca-Biz',
            is_inner: false,
            target: '_self'
          };
        } else if (url.startsWith('/corporate/announcement')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/categories/4414300043025-Duyurular',
            is_inner: false,
            target: '_self'
          };
        } else if (url.startsWith('/corporate/general-announcements')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/categories/4414300043025-Duyurular',
            is_inner: false,
            target: '_self'
          };
        } else if (url.startsWith('/corporate/about-artist')) {
          this.navigationService.active_corporate_page = CorporatePageTemplate.ANNOUNCEMENT;
          this.navigationService.artist_announcement_active = true;
          this.navigationService.operation_announcement_active = false;
          this.navigationService.resetActiveArtist();
          object = {url: '/about-artist', current_component_index: 0};
        } else if (url.startsWith('/corporate/career')) {
          object = {url: '/career', current_component_index: 0};
        } else if (url.startsWith('/corporate/contact')) {
          object = {url: 'https://support.artiox.com/hc/tr', is_inner: false, target: '_self'};
        } else {
          object = {
            url: 'https://support.artiox.com/hc/tr/articles/4423184358161-%C4%B0leti%C5%9Fim',
            is_inner: false,
            target: '_self'
          };
        }

        this.navigationManager(object);

      } else {
        if (param === 'about') {
          object = {...{url: '/corporate/about', current_component_index: 0}, ...params};
        } else if (param === 'announcements') {
          object = {...{url: '/corporate/announcements', current_component_index: 0}, ...params};
        } else if (path.includes('general-announcements')) {
          if (param === 'general-announcements') {
            object = {...{url: '/corporate/general-announcements', current_component_index: 0}, ...params};
          } else {
            const operation_announcement_index = this.config_tr.MISC.ANNOUNCEMENTS_TITLES.getAnnouncementIndex(param);
            object = {
              ...{
                url: '/corporate/general-announcements', ...operation_announcement_index,
                current_component_index: 0
              }, ...params
            };
          }
        } else if (param === 'about-artist') {
          object = {...{url: '/corporate/about-artist', current_component_index: 0}, ...params};
        } else if (param === 'career') {
          object = {...{url: '/corporate/career', current_component_index: 0}, ...params};
        } else if (param === 'contact') {
          object = {...{url: '/corporate/contact', current_component_index: 0}, ...params};
        } else {
          object = {...{url: '/corporate', current_component_index: 0}, ...params};
        }
      }
    } else if (url.startsWith('/service')) {
      if (this.config.ZENDESK_ACTIVE) {
        if (url.startsWith('/service/commissions')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/articles/4414483870865-Komisyonlar',
            is_inner: false,
            target: '_self'
          };
        } else if (url.startsWith('/service/rules')) {
          object = {
            url: 'https://support.artiox.com/hc/tr/articles/4414505009681-Limitler-ve-Kurallar',
            is_inner: false,
            target: '_self'
          };
        } else {
          object = {url: 'https://support.artiox.com/hc/tr', is_inner: false, target: '_self'};
        }

        this.navigationManager(object);

      } else {
        if (url.startsWith('/service/commissions')) {
          this.navigationService.active_service_page = ServicePageTemplate.COMMISSIONS;
        } else if (url.startsWith('/service/rules')) {
          this.navigationService.active_service_page = ServicePageTemplate.RULES;
        }
      }
    } else if (url.startsWith('/sign-up')) {
      object = {
        url: 'https://tr.artiox.com/indir',
        is_inner: false,
        target: '_self'
      };
    }

    if (object) {
      const art_product_list_promise = new Promise<void>((resolve, reject) => {
        this.pairService.active_featured_pair_selected.subscribe(() => {
          if (object.active_featured_pair_symbol) {
            this.pairService.active_featured_pair_pipe = !this.pairService.active_featured_pair_pipe;
          }
          if (object.active_pair_symbol) {
            this.pair_pipe = !this.pair_pipe;
          }
          resolve();
        });
      });

      const pair_list_promise = new Promise<void>((resolve, reject) => {
        this.pairService.pair_list_changed.subscribe(() => {
          resolve();
        });
      });

      const auction_product_list_promise = new Promise<void>((resolve, reject) => {
        this.auctionService.auction_product_list_changed.pipe(first()).subscribe(() => {
          if (object.active_auction_product_hash || object.active_auction_catalog_hash) {
            this.auctionService.active_auction_product_pipe = !this.auctionService.active_auction_product_pipe;
          }
          resolve();
        });
      });

      if (url.startsWith('/exchange/advanced')) {
        const order_book_promise = new Promise<void>((resolve, reject) => {
          this.orderBookService.order_book_changed.subscribe(() => {
            resolve();
          });
        });
        Promise.all([art_product_list_promise, pair_list_promise, auction_product_list_promise, order_book_promise]).then(() => {
          this.navigationService.prevent_navigation_history_push = true;
          this.navigationManager(object);
        });
      } else {
        Promise.all([art_product_list_promise, pair_list_promise, auction_product_list_promise]).then(() => {
          this.navigationService.prevent_navigation_history_push = true;
          this.navigationManager(object);
        });
      }
    }
  }

  // TODO not loggedInNavigationGuard should not check prod environment
  notLoggedInNavigationGuard(): boolean {
    if (!this.environment.production) {
      return false;
    }
    return (!this.cookieService.check('loggedIn') || JSON.parse(this.cookieService.get('loggedIn')) !== true);
  }

  navigateIfLoggedIn(object: any = {}): void {
    if (object.is_inner !== undefined || object.target !== undefined) {
      this.navigationService.navigateNewPage(this.environment.app, object);
      return;
    }

    if (this.notLoggedInNavigationGuard()) {
      this.navigationService.requested_routing = object;
      this.navigationManager({url: '/login', current_component_index: 0});
    } else {
      this.navigationManager(object);
    }

    this.account_navbar_menu_display_status = false;
  }

  openModalWithKyc(id: string, level: number): void {
    if (!this.logged_in) {
      this.navigationManager({url: '/login', current_component_index: 0});
      return;
    }
    if (this.userService.user !== undefined && this.userService.user.account_level >= level) {
      this.modalService.open(id);
    } else {
      this.modalService.open('account-level-modal');
    }
  }

  exchangeNavigate(pair: Pair | any, exchange_path: string = 'basic'): void {
    if (pair.sale_state === 'delisted') {
      return;
    }

    if (pair.sale_state !== 'listing') {
      this.navigateIfLoggedIn({url: '/presale', active_featured_pair_id: pair.id, current_component_index: 0});
    } else {
      this.navigateIfLoggedIn({
        url: '/exchange/' + exchange_path,
        active_pair_id: pair.id,
        current_component_index: 1
      });
    }
  }

  moreInfo(): void {
    if (this.pairService.active_pair) {
      this.navigationManager({
        url: '/presale',
        active_featured_pair_id: this.pairService.active_pair.id,
        current_component_index: 0
      });
    }
  }

  backToHome(): void {
    if (this.logged_in || !this.utilityService.checkReleaseUrl()) {
      this.navigationManager({url: '/home', current_component_index: 0});
    } else {
      this.navigateToTrArtiox();
    }
    this.navigationService.clearNavigationHistory();
  }

  navigateToTrArtiox(): void {
    if (environment.production && !environment.test) {
      window.open('https://tr.artiox.com/', '_self');
    }
  }

  userOperationTransactionInfoLink(user_operation: any): any {
    const block_chain_name = this.operationsService.block_chain_list[user_operation.block_chain_id]?.name ?? '';
    if (block_chain_name === 'Ethereum Main Net') {
      return {
        url: 'https://etherscan.io/tx/' + user_operation.transaction_hash,
        is_inner: false,
        target: '_blank'
      };
      // } else if (block_chain_name === 'Ethereum Goerli-Prater Test Net') {
      //   return {
      //     url: 'https://goerli.etherscan.io/tx/' + user_operation.transaction_hash,
      //     is_inner: false,
      //     target: '_blank'
      //   };
    } else if (block_chain_name === 'Ethereum Sepolia Test Net') {
      return {
        url: 'https://sepolia.etherscan.io/tx/' + user_operation.transaction_hash,
        is_inner: false,
        target: '_blank'
      };
    } else {
      return {};
    }
  }

  controlLocalStore(): void {
    const local_version_number: number = this.calculateVersionNumber(localStorage.getItem('version') ?? '');
    if (local_version_number !== -1) {
      if (local_version_number < this.calculateVersionNumber(this.environment.version)) {  // updated
        this.logoutResolver();
        setTimeout(() => {
          this.logout();
        }, 1000);
        localStorage.clear();
        localStorage.setItem('version', this.environment.version);
      }
    } else {
      localStorage.setItem('version', this.environment.version);
    }

    for (let i = 0; i < localStorage.length; i++) {
      try {
        if (localStorage.key(i) === 'version') {
          continue;
        }
        if (localStorage.key(i) === 'auction_agreement_checked') {
          continue;
        }
        if (localStorage.key(i) === 'user_agreement_checked') {
          continue;
        }
        if (localStorage.key(i) === 'user_verification_credentials') {
          continue;
        }

        // @ts-ignore
        const item = JSON.parse(localStorage.getItem(localStorage.key(i)));
        if (item.version !== environment.version) {
          // @ts-ignore
          localStorage.removeItem(localStorage.key(i));
        }
      } catch {
        // @ts-ignore
        localStorage.removeItem(localStorage.key(i));
      }
    }
  }

  // imageDatabaseManager(src: string, data: any): any {
  //     console.log('imageDatabaseManager', src, data);
  //     if (this.runningOnIPhone()) {
  //         if (src.includes('app_announcement/')) {
  //             src = src.replace('.webp', '.png');
  //
  //         } else {
  //             src = src.replace('.webp', '.jpg');
  //         }
  //     }
  //
  //     this.databaseService.put({src, version: environment.version, data});
  // }

  // fetchImageData(src: string): Promise<ArrayBuffer> {
  //     console.log('fetching image data from server:', src);
  //     return this.httpClient.get(src, { responseType: 'arraybuffer' }).toPromise();
  // }

  localImageStoreManager(src: string): string {

    if (this.local_storage_error) {
      return src;
    }
    if (this.runningOnIPhone()) {
      if (src.includes('app_announcement/')) {
        src = src.replace('.webp', '.png');

      } else {
        src = src.replace('.webp', '.jpg');
      }
    }
    try {
      //@ts-ignore
      const item = JSON.parse(localStorage.getItem(src));
      if (item.version === environment.version) {
        return 'data:image/jpg;base64,' + item.data;
      }

      // this.databaseService.get(src).then( (imageData: any) => {
      //     console.log(imageData)
      //         if (imageData) {
      //             console.log('Image data found in IndexedDB:', imageData);
      //             return imageData.data;
      //         } else {
      //             this.fetchImageData(src).then( (data: ArrayBuffer) => {
      //                     console.log('Image data fetched from server:', data);
      //                     this.databaseService.put({src,version: environment.version, data});
      //                 }
      //             )
      //
      //         }
      //     }
      // );
    } catch {
    }
    localStorage.removeItem(src);
    this.httpClient.get(src, {responseType: 'arraybuffer'}).subscribe(
      response => {
        const bytes = new Uint8Array(response);
        let base64: string = '';
        for (let i = 0; i < bytes.byteLength; i++) {
          base64 += String.fromCharCode(bytes[i]);
        }
        try {
          // this.imageDatabaseManager(src, bytes);
          // this.databaseService.put({src, version: environment.version, data: bytes});
          localStorage.setItem(src, JSON.stringify({version: environment.version, data: btoa(base64)}));
        } catch {
          this.local_storage_error = true;
        }
      }
    );
    return src;
  }

  calculateVersionNumber(version: string): number {
    const version_step_list = version.split('.').map(x => parseInt(x, 10));
    if (version_step_list.length !== 3) {
      return -1;
    }

    return version_step_list[0] * 10000 + version_step_list[1] * 100 + version_step_list[2];
  }

  // checkAppUpdate(): void {
  //     if (!this.environment.app) {
  //         return;
  //     }
  //
  //     if (this.environment.platform === 'android') {
  //         if (this.config.MISC.APP_ANDROID_VERSION === '' || this.calculateVersionNumber(this.config.MISC.APP_ANDROID_VERSION) <= this.calculateVersionNumber(this.environment.version)) {
  //             return;
  //         } else {
  //             this.openModal('update-app-modal');
  //         }
  //     } else if (this.environment.platform === 'ios') {
  //         if (this.config.MISC.APP_IOS_VERSION === '' || this.calculateVersionNumber(this.config.MISC.APP_IOS_VERSION) <= this.calculateVersionNumber(this.environment.version)) {
  //             return;
  //         } else {
  //             this.openModal('update-app-modal');
  //         }
  //     }
  // }

  configHandler(): void {
    this.config_loaded.pipe(first()).subscribe(() => {
      // if (this.config.GENERIC_MODAL.OPEN && this.environment.app) {
      //     this.openModal('generic-modal');
      // }
      if (this.config.MISC.ANNOUNCEMENT_ACTIVE) {
        this.pairService.announcement_active = this.config.MISC.ANNOUNCEMENT_ACTIVE;
      }
      this.exchangeResolver();
      this.utilityService.setLocalTime();
    });
  }

  photoSwipeOpen(pair: Pair | AuctionProduct | undefined, index: number = 0): void {
    const photoswipe = document.getElementById('photoswipe');

    let img_src_raw;
    if (pair instanceof Pair) {
      // @ts-ignore
      img_src_raw = this.config.ART_PRODUCTS[this.pairService.getArtProductById(pair.id[0])?.symbol].IMAGE_DESKTOP;
    } else if (pair instanceof AuctionProduct) {
      img_src_raw = this.auctionService.active_auction_product_config.IMAGE_DESKTOP;
    } else {
      return;
    }

    const items = img_src_raw.map((src: string) => {
      if (this.runningOnIPhone()) {
        src = src.replace('.webp', '.jpg');
      }
      const [w, h] = src.split('?')[1].split('&').map((x: string) => {
        return parseInt(x.split('=')[1], 10) * 2;
      });
      // if (this.environment.app) {
      //     src = this.environment.assetsURL + this.environment.assetsAPI + src.substr(2, src.length);
      // }
      return {src, w, h};
    });

    const options = {
      index
    };

    // @ts-ignore
    const gallery = new PhotoSwipe(photoswipe, PhotoSwipeUI_Default, items, options);
    gallery.init();
    this.navigationService.navigation_history.push(this.navigationService.current_routing);
  }

  trimText(obj: any, force?: boolean): void {
    if (this.view_port === 'mobile') {
      if (obj.TEXT_LIMIT_MOBILE !== -1) {
        obj.TEXT_LIMIT_SWAP = obj.TEXT_LIMIT_MOBILE;
        obj.TEXT_LIMIT_MOBILE = -1;
      } else {
        if (!force) {
          obj.TEXT_LIMIT_MOBILE = obj.TEXT_LIMIT_SWAP;
          obj.TEXT_LIMIT_SWAP = -1;
        }
      }
    } else if (this.view_port === 'desktop') {
      if (obj.TEXT_LIMIT_DESKTOP !== -1) {
        obj.TEXT_LIMIT_SWAP = obj.TEXT_LIMIT_DESKTOP;
        obj.TEXT_LIMIT_DESKTOP = -1;
      } else {
        if (!force) {
          obj.TEXT_LIMIT_DESKTOP = obj.TEXT_LIMIT_SWAP;
          obj.TEXT_LIMIT_SWAP = -1;
        }
      }
    }
  }

  toggleText(index: number): void {
    this.config_tr.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST[index].TOGGLE = !this.config_tr.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST[index].TOGGLE;
    this.config_en.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST[index].TOGGLE = !this.config_en.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST[index].TOGGLE;
  }

  resetToggleText(index: number): void {
    this.config_tr.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST[index].TOGGLE = false;
    this.config_en.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST[index].TOGGLE = false;
  }


  codeHandler(response: any): string {
    const copy_response = {...response};
    // @ts-ignore
    const content = this.config.RESPONSE_TABLE[copy_response.code];
    delete copy_response.code;
    delete copy_response.is_okay;
    delete copy_response.message;
    const tokens: string[] = Object.keys(copy_response);
    const replacements: string[] = Object.values(copy_response);

    const index_of_type: number = tokens.indexOf('$type');
    if (index_of_type !== -1) {
      if (replacements[index_of_type] === 'buy') {
        replacements[index_of_type] = this.config.MISC.BUY;
      } else if (replacements[index_of_type] === 'sell') {
        replacements[index_of_type] = this.config.MISC.SELL;
      } else {
        replacements[index_of_type] = '';
      }
    }

    return this.evaluateString(content.message, tokens, replacements);
  }

  setViewPort(): void {
    this.inner_width = this.window.innerWidth;
    if (this.inner_width >= 768) {
      this.view_port = 'desktop';
    } else {
      this.view_port = 'mobile';
    }
  }

  setLanguage(language: string): void {
    if (this.language !== language) {
      this.language = language;
      // @ts-ignore
      this.config = this.language_map[language];
      this.language_changed.emit();
      this.language_pipe = !this.language_pipe;
      this.moment.locale(this.language);
      this.setActiveAuctionProductConfig();
    }
  }

  switchLanguage(): void {
    if (this.language === 'TR') {
      this.language = 'EN';
      this.config = this.config_en;
      this.language_changed.emit();
      this.language_pipe = !this.language_pipe;
    } else if (this.language === 'EN') {
      this.language = 'TR';
      this.config = this.config_tr;
      this.language_changed.emit();
      this.language_pipe = !this.language_pipe;
    }
    this.moment.locale(this.language);
    this.setActiveAuctionProductConfig();
  }

  showConnectionFailModal(): void {
    clearInterval(this.socket_connection_checker);

    if (this.client_socket.connected && this.client_socket.id) {
      this.show_connection_problem_modal = false;
      return;
    }

    this.show_connection_problem_modal = true;
    this.socket_connection_checker = setInterval(() => {
      if (this.client_socket.connected && this.client_socket.id) {
        this.show_connection_problem_modal = false;
        clearInterval(this.socket_connection_checker);
      }
    }, 400);
  }

  setActiveCurrencyConverterPanel(cc: ActiveCurrencyConverterPanelTemplate): void {
    this.active_currency_converter_panel = cc;
    this.active_currency_converter_panel_changed.emit();
  }

  getCursorStyleFromUserOperation(user_operation: CurrencyOperation): string {
    if (user_operation.block_chain_id === -1 || user_operation.transaction_hash === '-') {
      return 'auto';
    }
    return 'pointer';
  }

  getAuctionProductConfig(hash: string): any {
    // @ts-ignore
    return this.config.AUCTION.AUCTION_PRODUCTS[hash];
  }

  getAuctionCatalogConfig(hash: string): any {
    // @ts-ignore
    return this.config.AUCTION.AUCTION_CATALOGS[hash];
  }

  getAuctionArtistConfig(hash: string): any {
    // @ts-ignore
    return this.config.AUCTION.AUCTION_ARTISTS[hash];
  }

  openAddDeliveryAddressModal(address: DeliveryAddress | null): void {
    this.userService.selected_user_address_operation = (address === null ? 'add' : 'update');
    this.userService.selected_user_delivery_address_changed.emit(address);
    this.openModal('add-delivery-address-modal');
  }

  openAddBillingAddressModal(address: BillingAddress | null): void {
    this.userService.selected_user_address_operation = (address === null ? 'add' : 'update');
    this.userService.selected_user_billing_address_changed.emit(address);
    this.openModal('add-billing-address-modal');
  }

  openDeleteAddressModal(address_id: any, type: 'delivery' | 'billing'): void {
    this.userService.selected_user_address_id = address_id;
    this.userService.selected_user_address_type = type;
    this.openModal('delete-address-modal');
  }

  addBeforeUnloadListener() {
    window.addEventListener("beforeunload", this.beforeUnloadHandler)
  }

  removeBeforeUnloadListener() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler)
  }

  // closeApp(): void {
  //     const _navigator: any = navigator;
  //     if (_navigator.app) {
  //         _navigator.app.exitApp();
  //     } else if (_navigator.device) {
  //         _navigator.device.exitApp();
  //     } else {
  //         window.close();
  //     }
  // }

  runningOnSafari(): boolean {
    const userAgent: string = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome');
  }

  runningOnIPhone(): boolean {
    const ios_platform_list = ['iPhone', 'iPad', 'iPhone Simulator', 'iPad Simulator'];
    // return this.environment.platform === 'ios' || ios_platform_list.includes(navigator.platform);
    return ios_platform_list.includes(navigator.platform);
  }

  runningOnAndroid(): boolean {
    const android_platform_list = ['Android', 'Linux armv6l', 'Linux armv7l', 'Linux armv8l', 'Linux i686', 'Linux i686 on x86_64'];
    // return this.environment.platform === 'android' || android_platform_list.includes(navigator.platform);
    return android_platform_list.includes(navigator.platform);
  }

  logAndReturn(arg: any): any {
    if (!this.environment.production) {
      console.log(arg);  // dev tool
    }
    return arg;
  }

  numberInputCheck(event: KeyboardEvent): void {
    if (['e', 'E', '+', '-'].includes(event.key)) {
      event.preventDefault();
    }
  }

  animatedInputFieldFocus(div: any): void {
    div.classList.add('focused');
  }

  animatedInputFieldBlur(div: any, input: any): void {
    if (input === '') {
      div.classList.remove('focused');
    }
  }

  auctionPaymentDescription(auction_prod: AuctionProduct): string {
    const text = this.getAuctionProductConfig(auction_prod.hash).ARTIST_NAME;
    return 'LOT ' + auction_prod.lot + ' - ' + text.split('(')[0];
  }

  stopProp(event: Event): void {
    event.stopPropagation();
  }

  // todo rename
  previousPageNavigation(): void {
    if (this.navigationService.navigation_history.length === 0) {
      if (this.logged_in || !this.utilityService.checkReleaseUrl()) {
        this.navigationManager({url: '/home', current_component_index: 0});
      } else {
        this.navigateToTrArtiox();
      }
      this.navigationService.clearNavigationHistory();
    } else {
      this.navigationService.reverseNavigation();
    }
  }

  private beforeUnloadHandler = (event: Event) => {
    event.preventDefault();  // Recommended
    event.returnValue = true;  // Included for legacy support, e.g. Chrome/Edge < 119
  }
}
