import {AfterViewInit, Component, HostListener, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActiveMobileNavbarPanelTemplate, ManagerService} from './services/manager.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {config_tr} from './configs/config_tr';
import {config_en} from './configs/config_en';
import {delay, retryWhen} from 'rxjs/operators';
import {Meta} from '@angular/platform-browser';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {environment} from '../environments/environment';
import {checkBudgets} from '@angular-devkit/build-angular/src/utils/bundle-calculator';

declare let gtag: any;  // obsolete
declare let fbq: any;  // obsolete

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('appGrow', [
            state('open', style({
                height: 74,
                transform: 'translateY(0)'
            })),
            state('close', style({
                height: 0,
                transform: 'translateY(-74px)'
            })),
            transition('open=>close', [
                animate('1s ease-in-out')]),
            transition('close=>open', [
                animate('1s 2s ease-in-out')]),
        ]),
    ]
})
export class AppComponent implements AfterViewInit, OnDestroy {
    title = 'artiox-gui';

    @ViewChild('cookie_popup') cookie_popup: any = undefined;
    @ViewChild('app_popup') app_popup: any = undefined;
    @ViewChild('body') body: any = undefined;

    @ViewChild('test_environment_warning') test_environment_warning: any = undefined;

    public subscription_list: Subscription[] = [];
    public navigation_ready: boolean = false;
    public app_grow: string = 'close';

    constructor(private httpClient: HttpClient, public managerService: ManagerService, private router: Router, private meta: Meta) {

        this.subscription_list.push(this.managerService.user_logged_in.subscribe((event) => {
            if (event) {
                if (this.managerService.navigationService.requested_routing.url) {
                    this.managerService.navigationManager(this.managerService.navigationService.requested_routing);
                } else if (this.managerService.environment.app) {
                    this.managerService.navigationManager({url: '/home', current_component_index: 0});
                } else if (this.managerService.checkURLPrefix('/landing-page') || this.managerService.checkURLPrefix('/sign-up') || this.managerService.checkURLPrefix('/forgot-password/change-password') || this.managerService.checkURLPrefix('/login') || this.managerService.checkURLPrefix('/forgot-password') || this.managerService.checkURLPrefix('/forgot-password')) {
                    this.managerService.navigationManager({url: '/home', current_component_index: 0});
                } else {
                    const raw_current_routing = JSON.parse(this.managerService.navigationService.raw_current_routing);
                    if (raw_current_routing.url) {
                        this.managerService.navigateIfLoggedIn(raw_current_routing);
                    } else if (this.managerService.notLoggedInNavigationGuard()) {
                        this.managerService.navigationManager({url: '/home', current_component_index: 0});
                    }
                }
                this.managerService.navigationService.clearNavigationHistory();
            }
        }));

        if (this.managerService.environment.proxy) {
            this.httpClient.get<JSON>(this.managerService.environment.assetsURL + this.managerService.environment.assetsAPI + 'config_tr.json', {headers: {'Cache-Control': 'no-cache'}})
                .pipe(retryWhen(errors => errors.pipe(delay(1200))))
                .subscribe(
                    response => {
                        // @ts-ignore
                        this.managerService.config_tr = response;
                        // @ts-ignore
                        this.managerService.config = response;
                        this.managerService.config_ready = true;
                        this.managerService.config_loaded.emit();
                        // this.managerService.checkAppUpdate();
                        // if (this.managerService.environment.platform !== 'web') {
                        //     const dynamic_script = document.createElement('script');
                        //     dynamic_script.src = this.managerService.environment.assetsURL + this.managerService.environment.assetsAPI + '/scripts/dynamic.js';
                        //     dynamic_script.async = true;
                        //     dynamic_script.type = 'text/javascript';
                        //     // @ts-ignore
                        //     dynamic_script.onload = () => window.dynamic_onload(this.managerService.environment);
                        //     document.head.appendChild(dynamic_script);
                        // }
                    });

            this.httpClient.get<JSON>(this.managerService.environment.assetsURL + this.managerService.environment.assetsAPI + '/config_en.json', {headers: {'Cache-Control': 'no-cache'}})
                .pipe(retryWhen(errors => errors.pipe(delay(1200))))
                .subscribe(response => {
                    // @ts-ignore
                    this.managerService.config_en = response;
                });
        } else {
            this.managerService.config = config_tr;
            this.managerService.config_tr = config_tr;
            this.managerService.config_en = config_en;
            this.managerService.config_ready = true;
            this.managerService.config_loaded.emit();
        }

        this.managerService.setViewPort();
        this.subscription_list.push(this.managerService.router.events.subscribe((_event) => {

                if (_event instanceof NavigationStart) {
                    if (_event.url === '/') {
                        if (this.managerService.notLoggedInNavigationGuard() && this.managerService.utilityService.checkReleaseUrl()) {
                            this.managerService.navigateToTrArtiox();
                        } else {
                            this.managerService.navigationManager({url: '/home', current_component_index: 0});
                        }
                    }
                    if (_event.url.startsWith('/landing-page') || _event.url.startsWith('/exchange') || (_event.url.startsWith('/account') && !_event.url.startsWith('/account-verification')) || _event.url.startsWith('/presale') || _event.url.startsWith('/auction')) {
                        if (this.managerService.notLoggedInNavigationGuard()) {
                            if (_event.url === '/landing-page?redirect=false') {
                                this.managerService.navigationManager({
                                    url: '/landing-page?redirect=false',
                                    current_component_index: 0
                                });
                            } else {
                                if(this.managerService.utilityService.checkReleaseUrl()){
                                    this.managerService.navigateToTrArtiox();
                                } else{
                                    this.managerService.navigationManager({url: '/home', current_component_index: 0});
                                }
                            }
                        }
                    }
                }

                if (_event instanceof NavigationEnd) {
                    if (this.managerService.checkURLPrefix('/landing-page')) {
                        this.managerService.active_mobile_navbar_panel = this.managerService.active_mobile_navbar_panel_template.HOME;
                    } else if (this.managerService.checkURLPrefix('/home')) {
                        this.managerService.active_mobile_navbar_panel = this.managerService.active_mobile_navbar_panel_template.HOME;
                    } else if (this.managerService.checkURLPrefix('/exchange/basic')) {
                        this.managerService.active_mobile_navbar_panel = this.managerService.active_mobile_navbar_panel_template.BASIC_TRADE;
                    } else if (this.managerService.checkURLPrefix('/exchange/advanced')) {
                        this.managerService.active_mobile_navbar_panel = this.managerService.active_mobile_navbar_panel_template.ADVANCED_TRADE;
                    } else if (this.managerService.checkURLPrefix('/auction')) {
                        this.managerService.active_mobile_navbar_panel = this.managerService.active_mobile_navbar_panel_template.AUCTION;
                    } else if (this.managerService.checkURLPrefix('/account')) {
                        this.managerService.active_mobile_navbar_panel = this.managerService.active_mobile_navbar_panel_template.ACCOUNT;
                    }
                    if (_event.id !== 1) {
                        this.managerService.marketingService.logEvent('router_change', {url: _event.urlAfterRedirects});
                    }
                    if (!this.navigation_ready) {
                        this.managerService.navigationService.current_routing = {url: this.router.url};
                        this.navigation_ready = true;
                    }
                }
            }
        ));

        if (this.managerService.environment.test) {
            this.subscription_list.push(this.managerService.test_environment_accepted_changed.subscribe((test_environment_accepted: any) => {
                this.test_environment_warning.close_disabled = !test_environment_accepted;
                this.managerService.closeModal('test-environment-warning');
            }));
        }

        if (this.managerService.environment.app || this.managerService.runningOnAndroid() || this.managerService.runningOnIPhone()) {
            this.meta.updateTag({
                name: 'viewport',
                content: 'width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-info-scalable=no, viewport-fit=cover'
            });
            const cordova_script = document.createElement('script');
            cordova_script.src = 'cordova.js';
            document.head.appendChild(cordova_script);
        }
    }

    ngAfterViewInit(): void {
        const android_platform_list = ['Android', 'Linux armv6l', 'Linux armv7l', 'Linux armv8l', 'Linux i686', 'Linux i686 on x86_64'];
        const ios_platform_list = ['iPhone', 'iPad', 'iPhone Simulator', 'iPad Simulator'];

        if (android_platform_list.includes(navigator.platform) || ios_platform_list.includes(navigator.platform)) {
            this.app_popup.nativeElement.classList.remove('d-none');
            this.app_grow = 'open';
        }
        new Promise((resolve, reject) => {
            this.managerService.getUserInfo(resolve);
        }).then(() => {
            if (!this.managerService.environment.app) {
                this.isCookiesAccepted();
            }
        });

    }

    @HostListener('window:resize', ['$event'])
    onResize($event: any): void {
        this.managerService.setViewPort();
    }

    isCookiesAccepted(): void {
        const data = {};
        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'is_cookies_accepted_service', data).subscribe(response => {
            if (!response.cookies_accepted) {
                this.cookie_popup.nativeElement.classList.remove('d-none');
            }
        }));
    }

    setCookiesAccepted(decision: boolean): void {
        if (decision) {
            const data = {};
            this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'set_cookies_accepted_service', data).subscribe(response => {
                this.cookie_popup.nativeElement.classList.add('d-none');
            }));
        } else {
            window.location.href = 'https://www.google.com';
        }
    }

    activateMobilNavbar(active_mobile_navbar_panel: ActiveMobileNavbarPanelTemplate): void {
        if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.HOME) {
            if (this.managerService.logged_in || !this.managerService.utilityService.checkReleaseUrl()) {
                this.managerService.navigationManager({url: '/home', current_component_index: 0});
            } else {
                this.managerService.navigateToTrArtiox();
            }
        } else if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.BASIC_TRADE) {
            this.managerService.navigateIfLoggedIn({url: '/exchange/basic', current_component_index: 0});
        } else if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.ADVANCED_TRADE) {
            this.managerService.navigateIfLoggedIn({url: '/exchange/advanced', current_component_index: 0});
        } else if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.AUCTION) {
            this.managerService.navigateIfLoggedIn({url: '/auction', current_component_index: 0});
        } else if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.ACCOUNT) {
            this.managerService.navigateIfLoggedIn({url: '/account/balance', current_component_index: 0});
        }

        if (this.managerService.logged_in) {
            this.managerService.active_mobile_navbar_panel = active_mobile_navbar_panel;
        }
        this.managerService.account_navbar_menu_display_status = false;
        if (this.managerService.logged_in || active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.HOME) {
            this.managerService.navigationService.clearNavigationHistory();
        }
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

}
