// import {test_auction_config_tr} from './test_config_tr';

const auction_products_config_tr = {
  "2023_DAILY_1": {
    "LOT_NAME": "LOT 1",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ali Atmaca (1947 - )",
    "ARTIST_HASH": "ALI_ATMACA",
    "NAME": "\"Soyut Figüratif\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>80 x 100 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_1/0.d.webp?w=1200&h=967",
      "./assets/auction_products/2023_DAILY_1/1.d.webp?w=1200&h=909"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_1/0.m.webp?w=559&h=450",
      "./assets/auction_products/2023_DAILY_1/1.m.webp?w=594&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_2": {
    "LOT_NAME": "LOT 2",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Mustafa Ayaz (1938 - )",
    "ARTIST_HASH": "MUSTAFA_AYAZ",
    "NAME": "\"Figüratif\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>30 x 25 cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_2/0.d.webp?w=900&h=1200",
      "./assets/auction_products/2023_DAILY_2/1.d.webp?w=800&h=1200",
      "./assets/auction_products/2023_DAILY_2/2.d.webp?w=900&h=1200",
      "./assets/auction_products/2023_DAILY_2/3.d.webp?w=1200&h=849"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_2/0.m.webp?w=337&h=450",
      "./assets/auction_products/2023_DAILY_2/1.m.webp?w=300&h=450",
      "./assets/auction_products/2023_DAILY_2/2.m.webp?w=338&h=450",
      "./assets/auction_products/2023_DAILY_2/3.m.webp?w=636&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_3": {
    "LOT_NAME": "LOT 3",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adem Genç (1944 - )",
    "ARTIST_HASH": "ADEM_GENC",
    "NAME": "\"Soyut Geometrik\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>63,5 x 77 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_3/0.d.webp?w=1200&h=1044",
      "./assets/auction_products/2023_DAILY_3/1.d.webp?w=1200&h=998"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_3/0.m.webp?w=450&h=391",
      "./assets/auction_products/2023_DAILY_3/1.m.webp?w=450&h=374"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_4": {
    "LOT_NAME": "LOT 4",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Alev Özas (1977 - )",
    "ARTIST_HASH": "ALEV_OZAS",
    "NAME": "\"İstanbul\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>100 x 80 cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_4/0.d.webp?w=963&h=1200",
      "./assets/auction_products/2023_DAILY_4/1.d.webp?w=999&h=1199"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_4/0.m.webp?w=361&h=450",
      "./assets/auction_products/2023_DAILY_4/1.m.webp?w=375&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_5": {
    "LOT_NAME": "LOT 5",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Fikret Mualla (1903 - 1967)",
    "ARTIST_HASH": "FIKRET_MUALLA",
    "NAME": "\"La Conversation\"",
    "TEXT": "İmzalı, Karton Üzerine Guaj Boya<br>19,5 x 24,5 cm<br>1955",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_5/0.d.webp?w=1200&h=1033",
      "./assets/auction_products/2023_DAILY_5/1.d.webp?w=1200&h=889",
      "./assets/auction_products/2023_DAILY_5/2.d.webp?w=1200&h=1089",
      "./assets/auction_products/2023_DAILY_5/3.d.webp?w=971&h=1200",
      "./assets/auction_products/2023_DAILY_5/4.d.webp?w=880&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_5/0.m.webp?w=523&h=450",
      "./assets/auction_products/2023_DAILY_5/1.m.webp?w=607&h=450",
      "./assets/auction_products/2023_DAILY_5/2.m.webp?w=497&h=450",
      "./assets/auction_products/2023_DAILY_5/3.m.webp?w=364&h=450",
      "./assets/auction_products/2023_DAILY_5/4.m.webp?w=330&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_6": {
    "LOT_NAME": "LOT 6",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ahmet Oran (1957 - )",
    "ARTIST_HASH": "AHMET_ORAN",
    "NAME": "\"Soyut\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>120 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_6/0.d.webp?w=946&h=1200",
      "./assets/auction_products/2023_DAILY_6/1.d.webp?w=942&h=1200",
      "./assets/auction_products/2023_DAILY_6/2.d.webp?w=970&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_6/0.m.webp?w=354&h=450",
      "./assets/auction_products/2023_DAILY_6/1.m.webp?w=353&h=450",
      "./assets/auction_products/2023_DAILY_6/2.m.webp?w=364&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_7": {
    "LOT_NAME": "LOT 7",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "İlyas Ergin İnan (1943 - )",
    "ARTIST_HASH": "ILYAS_ERGIN_INAN",
    "NAME": "\"Berlin Serisi\"",
    "TEXT": "İmzalı, Duralit Üzerine Yağlı Boya<br>70 x 100 cm<br>2004",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_7/0.d.webp?w=1200&h=806",
      "./assets/auction_products/2023_DAILY_7/1.d.webp?w=1200&h=777",
      "./assets/auction_products/2023_DAILY_7/2.d.webp?w=951&h=1200",
      "./assets/auction_products/2023_DAILY_7/3.d.webp?w=1200&h=807"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_7/0.m.webp?w=450&h=302",
      "./assets/auction_products/2023_DAILY_7/1.m.webp?w=695&h=450",
      "./assets/auction_products/2023_DAILY_7/2.m.webp?w=356&h=450",
      "./assets/auction_products/2023_DAILY_7/3.m.webp?w=450&h=303"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_8": {
    "LOT_NAME": "LOT 8",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Burhan Doğançay (1929 - 2013)",
    "ARTIST_HASH": "BURHAN_DOGANCAY",
    "NAME": "\"Ribbon\"",
    "TEXT": "İmzalı, Karton Üzerine Guaj Boya<br>75,8 x 56,5 cm<br>1978",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_8/0.d.webp?w=970&h=1200",
      "./assets/auction_products/2023_DAILY_8/1.d.webp?w=879&h=1200",
      "./assets/auction_products/2023_DAILY_8/2.d.webp?w=1119&h=1200",
      "./assets/auction_products/2023_DAILY_8/3.d.webp?w=819&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_8/0.m.webp?w=364&h=450",
      "./assets/auction_products/2023_DAILY_8/1.m.webp?w=329&h=450",
      "./assets/auction_products/2023_DAILY_8/2.m.webp?w=419&h=449",
      "./assets/auction_products/2023_DAILY_8/3.m.webp?w=307&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_9": {
    "LOT_NAME": "LOT 9",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ali Atmaca (1947 - )",
    "ARTIST_HASH": "ALI_ATMACA",
    "NAME": "\"Soyut Figüratif\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>70 x 130 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_9/0.d.webp?w=1200&h=739",
      "./assets/auction_products/2023_DAILY_9/1.d.webp?w=1200&h=711",
      "./assets/auction_products/2023_DAILY_9/2.d.webp?w=1200&h=668"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_9/0.m.webp?w=730&h=450",
      "./assets/auction_products/2023_DAILY_9/1.m.webp?w=759&h=450",
      "./assets/auction_products/2023_DAILY_9/2.m.webp?w=808&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_10": {
    "LOT_NAME": "LOT 10",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Hüseyin Feyzullah (1990 - )",
    "ARTIST_HASH": "HUSEYIN_FEYZULLAH",
    "NAME": "\"Natürmort\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>80 x 60 cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_10/0.d.webp?w=960&h=1200",
      "./assets/auction_products/2023_DAILY_10/1.d.webp?w=954&h=1200",
      "./assets/auction_products/2023_DAILY_10/2.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_10/0.m.webp?w=360&h=450",
      "./assets/auction_products/2023_DAILY_10/1.m.webp?w=358&h=450",
      "./assets/auction_products/2023_DAILY_10/2.m.webp?w=338&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_11": {
    "LOT_NAME": "LOT 11",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Javad Ghaffari (1980 - )",
    "ARTIST_HASH": "JAVAD_GHAFFARI",
    "NAME": "\"İstanbul\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>120 x 85 cm<br>",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_11/0.d.webp?w=872&h=1200",
      "./assets/auction_products/2023_DAILY_11/1.d.webp?w=961&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_11/0.m.webp?w=327&h=449",
      "./assets/auction_products/2023_DAILY_11/1.m.webp?w=360&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_12": {
    "LOT_NAME": "LOT 12",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Mahir Güven (1958 - )",
    "ARTIST_HASH": "MAHIR_GUVEN",
    "NAME": "\"Figüratif\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>70 x 60 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_12/0.d.webp?w=1075&h=1200",
      "./assets/auction_products/2023_DAILY_12/1.d.webp?w=1058&h=1200",
      "./assets/auction_products/2023_DAILY_12/2.d.webp?w=1084&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_12/0.m.webp?w=402&h=449",
      "./assets/auction_products/2023_DAILY_12/1.m.webp?w=397&h=450",
      "./assets/auction_products/2023_DAILY_12/2.m.webp?w=407&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_13": {
    "LOT_NAME": "LOT 13",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Tanju Demirci (1961 - 2016)",
    "ARTIST_HASH": "TANJU_DEMIRCI",
    "NAME": "\"Soyut\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>150 x 150 cm<br>2009",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_13/0.d.webp?w=1200&h=1194",
      "./assets/auction_products/2023_DAILY_13/1.d.webp?w=1200&h=1197"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_13/0.m.webp?w=450&h=448",
      "./assets/auction_products/2023_DAILY_13/1.m.webp?w=450&h=449"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_14": {
    "LOT_NAME": "LOT 14",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Müslüm Teke (1974 - )",
    "ARTIST_HASH": "MUSLUM_TEKE",
    "NAME": "\"Yüzler\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>100 x 80 cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_14/0.d.webp?w=994&h=1199",
      "./assets/auction_products/2023_DAILY_14/1.d.webp?w=963&h=1199"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_14/0.m.webp?w=373&h=450",
      "./assets/auction_products/2023_DAILY_14/1.m.webp?w=362&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_15": {
    "LOT_NAME": "LOT 15",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Müzisyenler\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>100 x 100 cm<br>2015",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_15/0.d.webp?w=1200&h=1171",
      "./assets/auction_products/2023_DAILY_15/1.d.webp?w=1200&h=1175",
      "./assets/auction_products/2023_DAILY_15/2.d.webp?w=858&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_15/0.m.webp?w=461&h=450",
      "./assets/auction_products/2023_DAILY_15/1.m.webp?w=459&h=450",
      "./assets/auction_products/2023_DAILY_15/2.m.webp?w=322&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_16": {
    "LOT_NAME": "LOT 16",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Şahin Paksoy (1952 - )",
    "ARTIST_HASH": "SAHIN_PAKSOY",
    "NAME": "\"Figüratif\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>90 x 120 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_16/0.d.webp?w=1200&h=908",
      "./assets/auction_products/2023_DAILY_16/1.d.webp?w=1200&h=898",
      "./assets/auction_products/2023_DAILY_16/2.d.webp?w=803&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_16/0.m.webp?w=450&h=340",
      "./assets/auction_products/2023_DAILY_16/1.m.webp?w=450&h=337",
      "./assets/auction_products/2023_DAILY_16/2.m.webp?w=301&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_17": {
    "LOT_NAME": "LOT 17",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Kenan Işık (1981 - )",
    "ARTIST_HASH": "KENAN_ISIK",
    "NAME": "\"Kırmızı İstanbul\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>60 x 60 cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_17/0.d.webp?w=1197&h=1200",
      "./assets/auction_products/2023_DAILY_17/1.d.webp?w=1194&h=1200",
      "./assets/auction_products/2023_DAILY_17/2.d.webp?w=1200&h=1159"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_17/0.m.webp?w=449&h=450",
      "./assets/auction_products/2023_DAILY_17/1.m.webp?w=447&h=450",
      "./assets/auction_products/2023_DAILY_17/2.m.webp?w=466&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_18": {
    "LOT_NAME": "LOT 18",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Selim Turan (1915 - 1994)",
    "ARTIST_HASH": "SELIM_TURAN",
    "NAME": "\"Soyut Kompozisyon\"",
    "TEXT": "İmzalı, Tuvale Marufle Karton Üzerine Guaj Boya<br>50 x 65 cm<br>1957",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_18/0.d.webp?w=1200&h=959",
      "./assets/auction_products/2023_DAILY_18/1.d.webp?w=1200&h=929",
      "./assets/auction_products/2023_DAILY_18/2.d.webp?w=900&h=1200",
      "./assets/auction_products/2023_DAILY_18/3.d.webp?w=1200&h=973",
      "./assets/auction_products/2023_DAILY_18/4.d.webp?w=897&h=1200",
      "./assets/auction_products/2023_DAILY_18/5.d.webp?w=924&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_18/0.m.webp?w=564&h=450",
      "./assets/auction_products/2023_DAILY_18/1.m.webp?w=581&h=450",
      "./assets/auction_products/2023_DAILY_18/2.m.webp?w=338&h=450",
      "./assets/auction_products/2023_DAILY_18/3.m.webp?w=555&h=450",
      "./assets/auction_products/2023_DAILY_18/4.m.webp?w=336&h=450",
      "./assets/auction_products/2023_DAILY_18/5.m.webp?w=346&h=450"
    ],
    "STATEMENT": 'Sanatçının eseri Tez - Antitez - Sentez kitabında yer almaktadır.',
  },
  "2023_DAILY_19": {
    "LOT_NAME": "LOT 19",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Hüseyin Feyzullah",
    "ARTIST_HASH": "HUSEYIN_FEYZULLAH",
    "NAME": "\"Natürmort\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>60 x 50 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_19/0.d.webp?w=995&h=1200",
      "./assets/auction_products/2023_DAILY_19/1.d.webp?w=993&h=1200",
      "./assets/auction_products/2023_DAILY_19/2.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_19/0.m.webp?w=373&h=450",
      "./assets/auction_products/2023_DAILY_19/1.m.webp?w=372&h=450",
      "./assets/auction_products/2023_DAILY_19/2.m.webp?w=337&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_20": {
    "LOT_NAME": "LOT 20",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Mümin Candaş (1971 - )",
    "ARTIST_HASH": "MUMIN_CANDAS",
    "NAME": "\"Barınaklar\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>50 x 50 cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_20/0.d.webp?w=1200&h=1193",
      "./assets/auction_products/2023_DAILY_20/1.d.webp?w=1200&h=1194",
      "./assets/auction_products/2023_DAILY_20/2.d.webp?w=1196&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_20/0.m.webp?w=452&h=450",
      "./assets/auction_products/2023_DAILY_20/1.m.webp?w=453&h=450",
      "./assets/auction_products/2023_DAILY_20/2.m.webp?w=449&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_21": {
    "LOT_NAME": "LOT 21",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925-2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Kemancı Kız\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>70 x 50 cm<br>2007",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_21/0.d.webp?w=1200&h=939",
      "./assets/auction_products/2023_DAILY_21/1.d.webp?w=1199&h=916",
      "./assets/auction_products/2023_DAILY_21/2.d.webp?w=846&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_21/0.m.webp?w=448&h=351",
      "./assets/auction_products/2023_DAILY_21/1.m.webp?w=589&h=450",
      "./assets/auction_products/2023_DAILY_21/2.m.webp?w=317&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_22": {
    "LOT_NAME": "LOT 22",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>70 x 100 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_22/0.d.webp?w=1200&h=832",
      "./assets/auction_products/2023_DAILY_22/1.d.webp?w=1200&h=841",
      "./assets/auction_products/2023_DAILY_22/2.d.webp?w=806&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_22/0.m.webp?w=650&h=450",
      "./assets/auction_products/2023_DAILY_22/1.m.webp?w=642&h=450",
      "./assets/auction_products/2023_DAILY_22/2.m.webp?w=302&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_23": {
    "LOT_NAME": "LOT 23",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Güven Araz (1964-)",
    "ARTIST_HASH": "GUVEN_ARAZ",
    "NAME": "\"Lighthouse of the Universe\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>100 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_23/0.d.webp?w=842&h=1200",
      "./assets/auction_products/2023_DAILY_23/1.d.webp?w=867&h=1200",
      "./assets/auction_products/2023_DAILY_23/2.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_23/0.m.webp?w=315&h=449",
      "./assets/auction_products/2023_DAILY_23/1.m.webp?w=325&h=450",
      "./assets/auction_products/2023_DAILY_23/2.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_24": {
    "LOT_NAME": "LOT 24",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ertuğrul Ateş (1954 - )",
    "ARTIST_HASH": "ERTUGRUL_ATES",
    "NAME": "\"Rüzgarlı Gün - Windyday\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>150 x 150 cm<br>2017",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_24/0.d.webp?w=1144&h=1200",
      "./assets/auction_products/2023_DAILY_24/1.d.webp?w=1200&h=1171"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_24/0.m.webp?w=429&h=450",
      "./assets/auction_products/2023_DAILY_24/1.m.webp?w=461&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_25": {
    "LOT_NAME": "LOT 25",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adem Genç (1944 - )",
    "ARTIST_HASH": "ADEM_GENC",
    "NAME": "\"Tılsım\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>85 x 85 cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_25/0.d.webp?w=1133&h=1200",
      "./assets/auction_products/2023_DAILY_25/1.d.webp?w=1182&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_25/0.m.webp?w=425&h=450",
      "./assets/auction_products/2023_DAILY_25/1.m.webp?w=443&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_26": {
    "LOT_NAME": "LOT 26",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ahmet Güneştekin (1966 - )",
    "ARTIST_HASH": "AHMET_GUNESTEKIN",
    "NAME": "\"Asaletin Rengi\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>100 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_26/0.d.webp?w=1191&h=1200",
      "./assets/auction_products/2023_DAILY_26/1.d.webp?w=1185&h=1200",
      "./assets/auction_products/2023_DAILY_26/2.d.webp?w=1200&h=1142"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_26/0.m.webp?w=447&h=450",
      "./assets/auction_products/2023_DAILY_26/1.m.webp?w=445&h=450",
      "./assets/auction_products/2023_DAILY_26/2.m.webp?w=473&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_27": {
    "LOT_NAME": "LOT 27",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Memduh Kuzay (1957 - )",
    "ARTIST_HASH": "MEMDUH_KUZAY",
    "NAME": "\"İstanbul\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>110 x 140 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_27/0.d.webp?w=1200&h=900",
      "./assets/auction_products/2023_DAILY_27/1.d.webp?w=1200&h=900"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_27/0.m.webp?w=600&h=450",
      "./assets/auction_products/2023_DAILY_27/1.m.webp?w=600&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_28": {
    "LOT_NAME": "LOT 28",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Behzat Hasan Feyzullah (1990 - )",
    "ARTIST_HASH": "BEHZAT_HASAN",
    "NAME": "\"Nü\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>110 x 110 cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_28/0.d.webp?w=1200&h=1189",
      "./assets/auction_products/2023_DAILY_28/1.d.webp?w=1200&h=1188",
      "./assets/auction_products/2023_DAILY_28/2.d.webp?w=1193&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_28/0.m.webp?w=454&h=450",
      "./assets/auction_products/2023_DAILY_28/1.m.webp?w=454&h=450",
      "./assets/auction_products/2023_DAILY_28/2.m.webp?w=448&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_29": {
    "LOT_NAME": "LOT 29",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Fikret Mualla (1903 - 1967)",
    "ARTIST_HASH": "FIKRET_MUALLA",
    "NAME": "\"Natürmort\"",
    "TEXT": "İmzalı, Kağıt Üzerine Guaj Boya<br>30 x 22 cm<br>-",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_29/0.d.webp?w=1200&h=1075",
      "./assets/auction_products/2023_DAILY_29/1.d.webp?w=1200&h=954",
      "./assets/auction_products/2023_DAILY_29/2.d.webp?w=1200&h=1087"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_29/0.m.webp?w=503&h=450",
      "./assets/auction_products/2023_DAILY_29/1.m.webp?w=566&h=450",
      "./assets/auction_products/2023_DAILY_29/2.m.webp?w=497&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_30": {
    "LOT_NAME": "LOT 30",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Şahin Paksoy (1952 - )",
    "ARTIST_HASH": "SAHIN_PAKSOY",
    "NAME": "\"Figüratif \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>100 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_30/0.d.webp?w=1175&h=1200",
      "./assets/auction_products/2023_DAILY_30/1.d.webp?w=1191&h=1200",
      "./assets/auction_products/2023_DAILY_30/2.d.webp?w=821&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_30/0.m.webp?w=440&h=450",
      "./assets/auction_products/2023_DAILY_30/1.m.webp?w=447&h=450",
      "./assets/auction_products/2023_DAILY_30/2.m.webp?w=308&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_31": {
    "LOT_NAME": "LOT 31",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"Gözler\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>40 x 40 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_31/0.d.webp?w=1200&h=1188",
      "./assets/auction_products/2023_DAILY_31/1.d.webp?w=1200&h=1197",
      "./assets/auction_products/2023_DAILY_31/2.d.webp?w=813&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_31/0.m.webp?w=454&h=450",
      "./assets/auction_products/2023_DAILY_31/1.m.webp?w=451&h=450",
      "./assets/auction_products/2023_DAILY_31/2.m.webp?w=305&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_32": {
    "LOT_NAME": "LOT 32",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Viyolonsel Çalan Kız\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2011",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_32/0.d.webp?w=1200&h=893",
      "./assets/auction_products/2023_DAILY_32/1.d.webp?w=1200&h=833",
      "./assets/auction_products/2023_DAILY_32/2.d.webp?w=1200&h=921",
      "./assets/auction_products/2023_DAILY_32/3.d.webp?w=825&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_32/0.m.webp?w=605&h=450",
      "./assets/auction_products/2023_DAILY_32/1.m.webp?w=648&h=450",
      "./assets/auction_products/2023_DAILY_32/2.m.webp?w=586&h=450",
      "./assets/auction_products/2023_DAILY_32/3.m.webp?w=309&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_33": {
    "LOT_NAME": "LOT 33",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "İbrahim Tayfur (1971 - )",
    "ARTIST_HASH": "IBRAHIM_TAYFUR",
    "NAME": "\"Figüratif Soyut\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>100 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_33/0.d.webp?w=966&h=1200",
      "./assets/auction_products/2023_DAILY_33/1.d.webp?w=1020&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_33/0.m.webp?w=362&h=450",
      "./assets/auction_products/2023_DAILY_33/1.m.webp?w=383&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_34": {
    "LOT_NAME": "LOT 34",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Alaaddin Hız (1989 - )",
    "ARTIST_HASH": "ALAADDIN_HIZ",
    "NAME": "\"Kargaşa Serisi\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>80 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_34/0.d.webp?w=1200&h=1191",
      "./assets/auction_products/2023_DAILY_34/1.d.webp?w=1200&h=1121"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_34/0.m.webp?w=453&h=450",
      "./assets/auction_products/2023_DAILY_34/1.m.webp?w=482&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_35": {
    "LOT_NAME": "LOT 35",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>60x80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_35/0.d.webp?w=1200&h=892",
      "./assets/auction_products/2023_DAILY_35/1.d.webp?w=1200&h=899",
      "./assets/auction_products/2023_DAILY_35/2.d.webp?w=772&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_35/0.m.webp?w=605&h=450",
      "./assets/auction_products/2023_DAILY_35/1.m.webp?w=600&h=450",
      "./assets/auction_products/2023_DAILY_35/2.m.webp?w=290&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_36": {
    "LOT_NAME": "LOT 36",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ali Atmaca (1947 - )",
    "ARTIST_HASH": "ALI_ATMACA",
    "NAME": "\"Soyut Figüratif\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>90 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_36/0.d.webp?w=1069&h=1200",
      "./assets/auction_products/2023_DAILY_36/1.d.webp?w=1084&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_36/0.m.webp?w=401&h=450",
      "./assets/auction_products/2023_DAILY_36/1.m.webp?w=406&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_37": {
    "LOT_NAME": "LOT 37",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Faruk Cimok (1956 - )",
    "ARTIST_HASH": "FARUK_CIMOK",
    "NAME": "\"Ortaköy\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>95 x 75 cm<br>-",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_37/0.d.webp?w=1200&h=1073",
      "./assets/auction_products/2023_DAILY_37/1.d.webp?w=1200&h=1020",
      "./assets/auction_products/2023_DAILY_37/2.d.webp?w=1200&h=1033"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_37/0.m.webp?w=503&h=450",
      "./assets/auction_products/2023_DAILY_37/1.m.webp?w=530&h=450",
      "./assets/auction_products/2023_DAILY_37/2.m.webp?w=522&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_38": {
    "LOT_NAME": "LOT 38",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Fehim Güler (1964 - )",
    "ARTIST_HASH": "FEHIM_GULER",
    "NAME": "\"Mavi İstanbul\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>50 x 150 cm<br>2012",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_38/0.d.webp?w=1200&h=381",
      "./assets/auction_products/2023_DAILY_38/1.d.webp?w=1200&h=430",
      "./assets/auction_products/2023_DAILY_38/2.d.webp?w=827&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_38/0.m.webp?w=1418&h=450",
      "./assets/auction_products/2023_DAILY_38/1.m.webp?w=1256&h=450",
      "./assets/auction_products/2023_DAILY_38/2.m.webp?w=310&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_39": {
    "LOT_NAME": "LOT 39",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Güven Araz (1964 - )",
    "ARTIST_HASH": "GUVEN_ARAZ",
    "NAME": "\"Ghost Wreck\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>60 x 120 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_39/0.d.webp?w=1200&h=600",
      "./assets/auction_products/2023_DAILY_39/1.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_39/0.m.webp?w=900&h=450",
      "./assets/auction_products/2023_DAILY_39/1.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_40": {
    "LOT_NAME": "LOT 40",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2009",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_40/0.d.webp?w=1200&h=875",
      "./assets/auction_products/2023_DAILY_40/1.d.webp?w=1200&h=857",
      "./assets/auction_products/2023_DAILY_40/2.d.webp?w=1200&h=911",
      "./assets/auction_products/2023_DAILY_40/3.d.webp?w=839&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_40/0.m.webp?w=617&h=450",
      "./assets/auction_products/2023_DAILY_40/1.m.webp?w=630&h=450",
      "./assets/auction_products/2023_DAILY_40/2.m.webp?w=593&h=450",
      "./assets/auction_products/2023_DAILY_40/3.m.webp?w=314&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_41": {
    "LOT_NAME": "LOT 41",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Alev Özas (1977 - )",
    "ARTIST_HASH": "ALEV_OZAS",
    "NAME": "\"Kırmızı Galata\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>50 x 50 cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_41/0.d.webp?w=1200&h=1192"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_41/0.m.webp?w=453&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_42": {
    "LOT_NAME": "LOT 42",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Alaaddin Hız (1989 - )",
    "ARTIST_HASH": "ALAADDIN_HIZ",
    "NAME": "\"Vertigo\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>130 x 130 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_42/0.d.webp?w=1200&h=1166",
      "./assets/auction_products/2023_DAILY_42/1.d.webp?w=1186&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_42/0.m.webp?w=463&h=450",
      "./assets/auction_products/2023_DAILY_42/1.m.webp?w=444&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_43": {
    "LOT_NAME": "LOT 43",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Tanju Demirci (1961 - 2016)",
    "ARTIST_HASH": "TANJU_DEMIRCI",
    "NAME": "\"Soyut\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>150 x 150 cm<br>",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_43/0.d.webp?w=1184&h=1200",
      "./assets/auction_products/2023_DAILY_43/1.d.webp?w=1184&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_43/0.m.webp?w=444&h=450",
      "./assets/auction_products/2023_DAILY_43/1.m.webp?w=444&h=450"
    ],
    "STATEMENT": "-"
  },
  // "2023_DAILY_44": {},
  // "2023_DAILY_45": {},
  "2023_DAILY_46": {
    "LOT_NAME": "LOT 46",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"Yüzler Serisi\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>50 x 50 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_46/0.d.webp?w=1199&h=1196",
      "./assets/auction_products/2023_DAILY_46/1.d.webp?w=816&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_46/0.m.webp?w=451&h=450",
      "./assets/auction_products/2023_DAILY_46/1.m.webp?w=306&h=450"
    ],
    "STATEMENT": "-"
  },
  // "2023_DAILY_47": {},
  "2023_DAILY_48": {
    "LOT_NAME": "LOT 48",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"İsimsiz\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>120 x 120 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_48/0.d.webp?w=1187&h=1200",
      "./assets/auction_products/2023_DAILY_48/1.d.webp?w=1200&h=900"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_48/0.m.webp?w=445&h=450",
      "./assets/auction_products/2023_DAILY_48/1.m.webp?w=600&h=450"
    ],
    "STATEMENT": "-"
  },
  // "2023_DAILY_49": {},
  // "2023_DAILY_50": {},
  // "2023_DAILY_51": {},
  "2023_DAILY_52": {
    "LOT_NAME": "LOT 52",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"Başlangıç\"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>120 x 120  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_52/0.d.webp?w=1200&h=1189",
      "./assets/auction_products/2023_DAILY_52/1.d.webp?w=1200&h=1199",
      "./assets/auction_products/2023_DAILY_52/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2023_DAILY_52/3.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_52/0.m.webp?w=454&h=450",
      "./assets/auction_products/2023_DAILY_52/1.m.webp?w=450&h=450",
      "./assets/auction_products/2023_DAILY_52/2.m.webp?w=695&h=450",
      "./assets/auction_products/2023_DAILY_52/3.m.webp?w=338&h=450"
    ],
    "STATEMENT": "-"
  },
  // "2023_DAILY_53": {},
  // "2023_DAILY_54": {},
  "2023_DAILY_55": {
    "LOT_NAME": "LOT 55",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Serap Can (1981 - )",
    "ARTIST_HASH": "SERAP_CAN",
    "NAME": "\"Pedestrians\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>60 x 60 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_55/0.d.webp?w=1149&h=1200",
      "./assets/auction_products/2023_DAILY_55/1.d.webp?w=1080&h=674",
      "./assets/auction_products/2023_DAILY_55/2.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_55/0.m.webp?w=431&h=450",
      "./assets/auction_products/2023_DAILY_55/1.m.webp?w=721&h=450",
      "./assets/auction_products/2023_DAILY_55/2.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_56": {
    "LOT_NAME": "LOT 56",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>60 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_56/0.d.webp?w=1200&h=901",
      "./assets/auction_products/2023_DAILY_56/1.d.webp?w=1200&h=749",
      "./assets/auction_products/2023_DAILY_56/2.d.webp?w=832&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_56/0.m.webp?w=599&h=450",
      "./assets/auction_products/2023_DAILY_56/1.m.webp?w=721&h=450",
      "./assets/auction_products/2023_DAILY_56/2.m.webp?w=312&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_68": {
    "LOT_NAME": "LOT 68",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Muhittin Arıkan (1976 - )",
    "ARTIST_HASH": "MUHITTIN_ARIKAN",
    "NAME": "\"Anadolu Yılkı Atı\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>80 x 120 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_68/0.d.webp?w=1200&h=890",
      "./assets/auction_products/2023_DAILY_68/1.d.webp?w=1200&h=811",
      "./assets/auction_products/2023_DAILY_68/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2023_DAILY_68/3.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_68/0.m.webp?w=606&h=450",
      "./assets/auction_products/2023_DAILY_68/1.m.webp?w=666&h=450",
      "./assets/auction_products/2023_DAILY_68/2.m.webp?w=695&h=450",
      "./assets/auction_products/2023_DAILY_68/3.m.webp?w=338&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_69": {
    "LOT_NAME": "LOT 69",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Zlata Shyshman (1995 -)",
    "ARTIST_HASH": "ZLATA_SHYSHMAN",
    "NAME": "\"Legends of Galata\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>70 x 50 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_69/0.d.webp?w=827&h=1200",
      "./assets/auction_products/2023_DAILY_69/1.d.webp?w=833&h=1200",
      "./assets/auction_products/2023_DAILY_69/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2023_DAILY_69/3.d.webp?w=891&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_69/0.m.webp?w=310&h=450",
      "./assets/auction_products/2023_DAILY_69/1.m.webp?w=313&h=450",
      "./assets/auction_products/2023_DAILY_69/2.m.webp?w=721&h=450",
      "./assets/auction_products/2023_DAILY_69/3.m.webp?w=334&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_70": {
    "LOT_NAME": "LOT 70",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Remzi Karabulut (1963 -)",
    "ARTIST_HASH": "REMZI_KARABULUT",
    "NAME": "\"İsimsiz \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>130 x 100 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_70/0.d.webp?w=919&h=1200",
      "./assets/auction_products/2023_DAILY_70/1.d.webp?w=954&h=1200",
      "./assets/auction_products/2023_DAILY_70/2.d.webp?w=1200&h=724",
      "./assets/auction_products/2023_DAILY_70/3.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_70/0.m.webp?w=345&h=450",
      "./assets/auction_products/2023_DAILY_70/1.m.webp?w=358&h=450",
      "./assets/auction_products/2023_DAILY_70/2.m.webp?w=745&h=450",
      "./assets/auction_products/2023_DAILY_70/3.m.webp?w=337&h=450"
    ],
    "STATEMENT": "-"
  },
  // "2023_DAILY_71": {},
  // "2023_DAILY_72": {},
  "2023_DAILY_73": {
    "LOT_NAME": "LOT 73",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Yılkı Atları \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>100 x 120 cm<br>2007",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_73/0.d.webp?w=1200&h=1012",
      "./assets/auction_products/2023_DAILY_73/1.d.webp?w=1200&h=1010",
      "./assets/auction_products/2023_DAILY_73/2.d.webp?w=1200&h=1023",
      "./assets/auction_products/2023_DAILY_73/3.d.webp?w=1200&h=718",
      "./assets/auction_products/2023_DAILY_73/4.d.webp?w=855&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_73/0.m.webp?w=533&h=450",
      "./assets/auction_products/2023_DAILY_73/1.m.webp?w=535&h=450",
      "./assets/auction_products/2023_DAILY_73/2.m.webp?w=528&h=450",
      "./assets/auction_products/2023_DAILY_73/3.m.webp?w=752&h=450",
      "./assets/auction_products/2023_DAILY_73/4.m.webp?w=321&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_74": {
    "LOT_NAME": "LOT 74",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Güven Araz (1964 - )",
    "ARTIST_HASH": "GUVEN_ARAZ",
    "NAME": "\"Whale Effect \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>100 x 100  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_74/0.d.webp?w=1200&h=1185",
      "./assets/auction_products/2023_DAILY_74/1.d.webp?w=1200&h=1174",
      "./assets/auction_products/2023_DAILY_74/2.d.webp?w=1200&h=1104",
      "./assets/auction_products/2023_DAILY_74/3.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_74/0.m.webp?w=455&h=450",
      "./assets/auction_products/2023_DAILY_74/1.m.webp?w=460&h=450",
      "./assets/auction_products/2023_DAILY_74/2.m.webp?w=489&h=450",
      "./assets/auction_products/2023_DAILY_74/3.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_75": {
    "LOT_NAME": "LOT 75",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Elif Şule Şanlı (2000 - )",
    "ARTIST_HASH": "ELIF_SULE_SANLI",
    "NAME": "\"Persona'nın Ardındaki Gölge\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>100 x 70  cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_75/0.d.webp?w=840&h=1200",
      "./assets/auction_products/2023_DAILY_75/1.d.webp?w=876&h=1200",
      "./assets/auction_products/2023_DAILY_75/2.d.webp?w=1200&h=724",
      "./assets/auction_products/2023_DAILY_75/3.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_75/0.m.webp?w=315&h=450",
      "./assets/auction_products/2023_DAILY_75/1.m.webp?w=328&h=450",
      "./assets/auction_products/2023_DAILY_75/2.m.webp?w=746&h=450",
      "./assets/auction_products/2023_DAILY_75/3.m.webp?w=337&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_17": {
    "LOT_NAME": "LOT 17",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"Yüzler Serisi\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>40 x 40 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_17/0.d.webp?w=1194&h=1200",
      "./assets/auction_products/2024_DAILY_17/1.d.webp?w=1200&h=1191",
      "./assets/auction_products/2024_DAILY_17/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_17/3.d.webp?w=760&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_17/0.m.webp?w=448&h=450",
      "./assets/auction_products/2024_DAILY_17/1.m.webp?w=454&h=450",
      "./assets/auction_products/2024_DAILY_17/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_17/3.m.webp?w=285&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_18": {
    "LOT_NAME": "LOT 18",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Hülya Elibol (1994 - )",
    "ARTIST_HASH": "HULYA_ELIBOL",
    "NAME": "\"Varoluşa Yolculuk \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>100 x 80  cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_18/0.d.webp?w=920&h=1200",
      "./assets/auction_products/2024_DAILY_18/1.d.webp?w=964&h=1200",
      "./assets/auction_products/2024_DAILY_18/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_18/3.d.webp?w=879&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_18/0.m.webp?w=345&h=450",
      "./assets/auction_products/2024_DAILY_18/1.m.webp?w=361&h=450",
      "./assets/auction_products/2024_DAILY_18/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_18/3.m.webp?w=329&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_21": {
    "LOT_NAME": "LOT 21",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"Hayal \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>120 x 120  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_21/0.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_21/1.d.webp?w=1188&h=1200",
      "./assets/auction_products/2024_DAILY_21/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_21/3.d.webp?w=765&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_21/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_21/1.m.webp?w=445&h=450",
      "./assets/auction_products/2024_DAILY_21/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_21/3.m.webp?w=287&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_22": {
    "LOT_NAME": "LOT 22",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Ensieh Najarian (1982 - )",
    "ARTIST_HASH": "ENSIEH_NAJARIAN",
    "NAME": "\"Kadın Özgürlüğü \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>100 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_22/0.d.webp?w=1165&h=1200",
      "./assets/auction_products/2024_DAILY_22/1.d.webp?w=1200&h=1087",
      "./assets/auction_products/2024_DAILY_22/2.d.webp?w=1200&h=724",
      "./assets/auction_products/2024_DAILY_22/3.d.webp?w=907&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_22/0.m.webp?w=437&h=450",
      "./assets/auction_products/2024_DAILY_22/1.m.webp?w=497&h=450",
      "./assets/auction_products/2024_DAILY_22/2.m.webp?w=745&h=450",
      "./assets/auction_products/2024_DAILY_22/3.m.webp?w=340&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_23": {
    "LOT_NAME": "LOT 23",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Sevgi Ağanoğlu (1966 - )",
    "ARTIST_HASH": "SEVGI_AGANOGLU",
    "NAME": "\"Sayuri \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>70 x 50  cm<br>2012",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_23/0.d.webp?w=857&h=1200",
      "./assets/auction_products/2024_DAILY_23/1.d.webp?w=834&h=1200",
      "./assets/auction_products/2024_DAILY_23/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_23/3.d.webp?w=841&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_23/0.m.webp?w=321&h=450",
      "./assets/auction_products/2024_DAILY_23/1.m.webp?w=313&h=450",
      "./assets/auction_products/2024_DAILY_23/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_23/3.m.webp?w=315&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_29": {
    "LOT_NAME": "LOT 29",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"Tohum\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>46 x 56  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_29/0.d.webp?w=980&h=1200",
      "./assets/auction_products/2024_DAILY_29/1.d.webp?w=956&h=1200",
      "./assets/auction_products/2024_DAILY_29/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_29/3.d.webp?w=755&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_29/0.m.webp?w=368&h=450",
      "./assets/auction_products/2024_DAILY_29/1.m.webp?w=359&h=450",
      "./assets/auction_products/2024_DAILY_29/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_29/3.m.webp?w=283&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_31": {
    "LOT_NAME": "LOT 31",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Sevgi Ağanoğlu (1966 - ) ",
    "ARTIST_HASH": "SEVGI_AGANOGLU",
    "NAME": "\"Fakir Kızlar \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>120 x 80  cm<br>2013",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_31/0.d.webp?w=812&h=1200",
      "./assets/auction_products/2024_DAILY_31/1.d.webp?w=814&h=1200",
      "./assets/auction_products/2024_DAILY_31/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_31/3.d.webp?w=715&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_31/0.m.webp?w=304&h=450",
      "./assets/auction_products/2024_DAILY_31/1.m.webp?w=305&h=450",
      "./assets/auction_products/2024_DAILY_31/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_31/3.m.webp?w=268&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_33": {
    "LOT_NAME": "LOT 33",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Çetin Pireci (1960 - )",
    "ARTIST_HASH": "CETIN_PIRECI",
    "NAME": "\"Son Şans - I\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>120 x 90 cm<br>2016",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_33/0.d.webp?w=897&h=1200",
      "./assets/auction_products/2024_DAILY_33/1.d.webp?w=914&h=1200",
      "./assets/auction_products/2024_DAILY_33/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_33/3.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_33/0.m.webp?w=336&h=450",
      "./assets/auction_products/2024_DAILY_33/1.m.webp?w=343&h=450",
      "./assets/auction_products/2024_DAILY_33/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_33/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_35": {
    "LOT_NAME": "LOT 35",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"Yüzler \"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>50 x 50  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_35/0.d.webp?w=1185&h=1200",
      "./assets/auction_products/2024_DAILY_35/1.d.webp?w=1196&h=1200",
      "./assets/auction_products/2024_DAILY_35/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_35/3.d.webp?w=844&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_35/0.m.webp?w=444&h=450",
      "./assets/auction_products/2024_DAILY_35/1.m.webp?w=449&h=450",
      "./assets/auction_products/2024_DAILY_35/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_35/3.m.webp?w=316&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_37": {
    "LOT_NAME": "LOT 37",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Remzi Karabulut (1963 -) ",
    "ARTIST_HASH": "REMZI_KARABULUT",
    "NAME": "\"İsimsiz \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>130 x 100 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_37/0.d.webp?w=927&h=1200",
      "./assets/auction_products/2024_DAILY_37/1.d.webp?w=909&h=1200",
      "./assets/auction_products/2024_DAILY_37/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_37/3.d.webp?w=814&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_37/0.m.webp?w=348&h=450",
      "./assets/auction_products/2024_DAILY_37/1.m.webp?w=341&h=450",
      "./assets/auction_products/2024_DAILY_37/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_37/3.m.webp?w=305&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_40": {
    "LOT_NAME": "LOT 40",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Kadın Modelin Dansı \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>40 x 40 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_40/0.d.webp?w=1177&h=1200",
      "./assets/auction_products/2024_DAILY_40/1.d.webp?w=1184&h=1200",
      "./assets/auction_products/2024_DAILY_40/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_40/3.d.webp?w=863&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_40/0.m.webp?w=442&h=450",
      "./assets/auction_products/2024_DAILY_40/1.m.webp?w=444&h=450",
      "./assets/auction_products/2024_DAILY_40/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_40/3.m.webp?w=324&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_41": {
    "LOT_NAME": "LOT 41",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Filiz Aksu (1977 - ) ",
    "ARTIST_HASH": "FILIZ_AKSU",
    "NAME": "\"Aklıma Takılanlar \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>70 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_41/0.d.webp?w=1200&h=844",
      "./assets/auction_products/2024_DAILY_41/1.d.webp?w=1200&h=830",
      "./assets/auction_products/2024_DAILY_41/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_41/3.d.webp?w=829&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_41/0.m.webp?w=640&h=450",
      "./assets/auction_products/2024_DAILY_41/1.m.webp?w=650&h=450",
      "./assets/auction_products/2024_DAILY_41/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_41/3.m.webp?w=311&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_42": {
    "LOT_NAME": "LOT 42",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Çetin Pireci (1960 - )",
    "ARTIST_HASH": "CETIN_PIRECI",
    "NAME": "\"Uyuyan Güzel - IV\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>90 x 120  cm<br>2017",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_42/0.d.webp?w=1200&h=894",
      "./assets/auction_products/2024_DAILY_42/1.d.webp?w=1200&h=885",
      "./assets/auction_products/2024_DAILY_42/2.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_42/3.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_42/0.m.webp?w=604&h=450",
      "./assets/auction_products/2024_DAILY_42/1.m.webp?w=610&h=450",
      "./assets/auction_products/2024_DAILY_42/2.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_42/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_43": {
    "LOT_NAME": "LOT 43",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Elif Şule Şanlı (2000 - ) ",
    "ARTIST_HASH": "ELIF_SULE_SANLI",
    "NAME": "\"Panettone\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>50 x 35 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_43/0.d.webp?w=900&h=1200",
      "./assets/auction_products/2024_DAILY_43/1.d.webp?w=893&h=1200",
      "./assets/auction_products/2024_DAILY_43/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_43/3.d.webp?w=872&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_43/0.m.webp?w=338&h=450",
      "./assets/auction_products/2024_DAILY_43/1.m.webp?w=335&h=450",
      "./assets/auction_products/2024_DAILY_43/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_43/3.m.webp?w=327&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_49": {
    "LOT_NAME": "LOT 49",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Üç Kız Kardeş \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>100 x 120  cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_49/0.d.webp?w=1200&h=990",
      "./assets/auction_products/2024_DAILY_49/1.d.webp?w=1200&h=964",
      "./assets/auction_products/2024_DAILY_49/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_49/3.d.webp?w=852&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_49/0.m.webp?w=545&h=450",
      "./assets/auction_products/2024_DAILY_49/1.m.webp?w=560&h=450",
      "./assets/auction_products/2024_DAILY_49/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_49/3.m.webp?w=320&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_51": {
    "LOT_NAME": "LOT 51",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"İsimsiz\"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>110 x 110  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_51/0.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_51/1.d.webp?w=1200&h=1194",
      "./assets/auction_products/2024_DAILY_51/2.d.webp?w=1200&h=1105",
      "./assets/auction_products/2024_DAILY_51/3.d.webp?w=800&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_51/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_51/1.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_51/2.m.webp?w=489&h=450",
      "./assets/auction_products/2024_DAILY_51/3.m.webp?w=300&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_55": {
    "LOT_NAME": "LOT 55",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Filiz Aksu (1977 - ) ",
    "ARTIST_HASH": "FILIZ_AKSU",
    "NAME": "\"Sıkışmışlık\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>100 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_55/0.d.webp?w=844&h=1200",
      "./assets/auction_products/2024_DAILY_55/1.d.webp?w=850&h=1200",
      "./assets/auction_products/2024_DAILY_55/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_55/3.d.webp?w=820&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_55/0.m.webp?w=316&h=450",
      "./assets/auction_products/2024_DAILY_55/1.m.webp?w=319&h=450",
      "./assets/auction_products/2024_DAILY_55/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_55/3.m.webp?w=307&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_57": {
    "LOT_NAME": "LOT 57",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Kemancı Kız I\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2010",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_57/0.d.webp?w=1200&h=872",
      "./assets/auction_products/2024_DAILY_57/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_57/2.d.webp?w=859&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_57/0.m.webp?w=619&h=450",
      "./assets/auction_products/2024_DAILY_57/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_57/2.m.webp?w=322&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_58": {
    "LOT_NAME": "LOT 58",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Mehmet Demir (1995 - )",
    "ARTIST_HASH": "MEHMET_DEMIR",
    "NAME": "\"Space\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>100 x 100 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_58/0.d.webp?w=1168&h=1200",
      "./assets/auction_products/2024_DAILY_58/1.d.webp?w=1196&h=1200",
      "./assets/auction_products/2024_DAILY_58/2.d.webp?w=1200&h=718",
      "./assets/auction_products/2024_DAILY_58/3.d.webp?w=862&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_58/0.m.webp?w=438&h=450",
      "./assets/auction_products/2024_DAILY_58/1.m.webp?w=449&h=450",
      "./assets/auction_products/2024_DAILY_58/2.m.webp?w=752&h=450",
      "./assets/auction_products/2024_DAILY_58/3.m.webp?w=323&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_59": {
    "LOT_NAME": "LOT 59",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>60 x 80  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_59/0.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_59/1.d.webp?w=1200&h=901",
      "./assets/auction_products/2024_DAILY_59/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_59/3.d.webp?w=897&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_59/0.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_59/1.m.webp?w=599&h=450",
      "./assets/auction_products/2024_DAILY_59/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_59/3.m.webp?w=336&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_60": {
    "LOT_NAME": "LOT 60",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2011",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_60/0.d.webp?w=1200&h=814",
      "./assets/auction_products/2024_DAILY_60/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_60/2.d.webp?w=868&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_60/0.m.webp?w=664&h=450",
      "./assets/auction_products/2024_DAILY_60/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_60/2.m.webp?w=325&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_61": {
    "LOT_NAME": "LOT 61",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Müge Cücü (1978 - ) ",
    "ARTIST_HASH": "MUGE_CUCU",
    "NAME": "\"Humanity \"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>60 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_61/0.d.webp?w=1200&h=1039",
      "./assets/auction_products/2024_DAILY_61/1.d.webp?w=1200&h=938",
      "./assets/auction_products/2024_DAILY_61/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_61/3.d.webp?w=850&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_61/0.m.webp?w=520&h=450",
      "./assets/auction_products/2024_DAILY_61/1.m.webp?w=576&h=450",
      "./assets/auction_products/2024_DAILY_61/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_61/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_62": {
    "LOT_NAME": "LOT 62",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"İsimsiz \"",
    "TEXT": "İmzalı, Kağıt Üzerine Akrilik Boya<br>70x100 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_62/0.d.webp?w=1200&h=851",
      "./assets/auction_products/2024_DAILY_62/1.d.webp?w=1200&h=834",
      "./assets/auction_products/2024_DAILY_62/2.d.webp?w=1200&h=839",
      "./assets/auction_products/2024_DAILY_62/3.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_62/4.d.webp?w=834&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_62/0.m.webp?w=635&h=450",
      "./assets/auction_products/2024_DAILY_62/1.m.webp?w=648&h=450",
      "./assets/auction_products/2024_DAILY_62/2.m.webp?w=644&h=450",
      "./assets/auction_products/2024_DAILY_62/3.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_62/4.m.webp?w=313&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_63": {
    "LOT_NAME": "LOT 63",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>60 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_63/0.d.webp?w=1200&h=895",
      "./assets/auction_products/2024_DAILY_63/1.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_63/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_63/3.d.webp?w=861&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_63/0.m.webp?w=603&h=450",
      "./assets/auction_products/2024_DAILY_63/1.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_63/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_63/3.m.webp?w=323&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_64": {
    "LOT_NAME": "LOT 64",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Kemancı Kız II\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2011",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_64/0.d.webp?w=1200&h=870",
      "./assets/auction_products/2024_DAILY_64/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_64/2.d.webp?w=862&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_64/0.m.webp?w=621&h=450",
      "./assets/auction_products/2024_DAILY_64/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_64/2.m.webp?w=323&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_65": {
    "LOT_NAME": "LOT 65",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Ferhat Arat (1996 - )",
    "ARTIST_HASH": "FERHAT_ARAT",
    "NAME": "\"İsimsiz \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>40 x 40 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_65/0.d.webp?w=1197&h=1200",
      "./assets/auction_products/2024_DAILY_65/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_65/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_65/3.d.webp?w=844&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_65/0.m.webp?w=449&h=450",
      "./assets/auction_products/2024_DAILY_65/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_65/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_65/3.m.webp?w=316&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_67": {
    "LOT_NAME": "LOT 67",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Yusuf Duran (1985 - )",
    "ARTIST_HASH": "YUSUF_DURAN",
    "NAME": "\"Dalga\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>80 x 120  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_67/0.d.webp?w=1200&h=821",
      "./assets/auction_products/2024_DAILY_67/1.d.webp?w=1200&h=801",
      "./assets/auction_products/2024_DAILY_67/2.d.webp?w=1200&h=804",
      "./assets/auction_products/2024_DAILY_67/3.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_67/4.d.webp?w=853&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_67/0.m.webp?w=658&h=450",
      "./assets/auction_products/2024_DAILY_67/1.m.webp?w=674&h=450",
      "./assets/auction_products/2024_DAILY_67/2.m.webp?w=672&h=450",
      "./assets/auction_products/2024_DAILY_67/3.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_67/4.m.webp?w=320&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_68": {
    "LOT_NAME": "LOT 68",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Alaaddin Hız (1989 - )",
    "ARTIST_HASH": "ALAADDIN_HIZ",
    "NAME": "\"Kargaşa Serisi\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>80 x 80  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_68/0.d.webp?w=1200&h=1195",
      "./assets/auction_products/2024_DAILY_68/1.d.webp?w=1192&h=1200",
      "./assets/auction_products/2024_DAILY_68/2.d.webp?w=1200&h=862",
      "./assets/auction_products/2024_DAILY_68/3.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_68/0.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_68/1.m.webp?w=447&h=450",
      "./assets/auction_products/2024_DAILY_68/2.m.webp?w=626&h=450",
      "./assets/auction_products/2024_DAILY_68/3.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_70": {
    "LOT_NAME": "LOT 70",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Hakan Esmer (1973 - )",
    "ARTIST_HASH": "HAKAN_ESMER",
    "NAME": "\"Cafe-Paris \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>116 x 89 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_70/0.d.webp?w=965&h=1200",
      "./assets/auction_products/2024_DAILY_70/1.d.webp?w=940&h=1200",
      "./assets/auction_products/2024_DAILY_70/2.d.webp?w=1200&h=1105",
      "./assets/auction_products/2024_DAILY_70/3.d.webp?w=1200&h=857"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_70/0.m.webp?w=362&h=450",
      "./assets/auction_products/2024_DAILY_70/1.m.webp?w=353&h=450",
      "./assets/auction_products/2024_DAILY_70/2.m.webp?w=489&h=450",
      "./assets/auction_products/2024_DAILY_70/3.m.webp?w=631&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_71": {
    "LOT_NAME": "LOT 71",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Elif Şule Şanlı (2000 - )",
    "ARTIST_HASH": "ELIF_SULE_SANLI",
    "NAME": "\"An\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>40 x 80  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_71/0.d.webp?w=1200&h=594",
      "./assets/auction_products/2024_DAILY_71/1.d.webp?w=1200&h=620",
      "./assets/auction_products/2024_DAILY_71/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_71/3.d.webp?w=901&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_71/0.m.webp?w=909&h=450",
      "./assets/auction_products/2024_DAILY_71/1.m.webp?w=871&h=450",
      "./assets/auction_products/2024_DAILY_71/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_71/3.m.webp?w=338&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_75": {
    "LOT_NAME": "LOT 75",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - )",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Köyde Bir Gün \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>50 x 80 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_75/0.d.webp?w=1200&h=728",
      "./assets/auction_products/2024_DAILY_75/1.d.webp?w=1200&h=743",
      "./assets/auction_products/2024_DAILY_75/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_75/3.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_75/0.m.webp?w=742&h=450",
      "./assets/auction_products/2024_DAILY_75/1.m.webp?w=726&h=450",
      "./assets/auction_products/2024_DAILY_75/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_75/3.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_79": {
    "LOT_NAME": "LOT 79",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Mümin Candaş (1971 - )",
    "ARTIST_HASH": "MUMIN_CANDAS",
    "NAME": "\"Barınaklar\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>50 x 50  cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_79/0.d.webp?w=1200&h=1195",
      "./assets/auction_products/2024_DAILY_79/1.d.webp?w=1200&h=1194",
      "./assets/auction_products/2024_DAILY_79/2.d.webp?w=1200&h=1179",
      "./assets/auction_products/2024_DAILY_79/3.d.webp?w=1200&h=1120"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_79/0.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_79/1.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_79/2.m.webp?w=458&h=450",
      "./assets/auction_products/2024_DAILY_79/3.m.webp?w=482&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_81": {
    "LOT_NAME": "LOT 81",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Horoz\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70  cm<br>2007",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_81/0.d.webp?w=1200&h=855",
      "./assets/auction_products/2024_DAILY_81/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_81/2.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_81/0.m.webp?w=632&h=450",
      "./assets/auction_products/2024_DAILY_81/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_81/2.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_83": {
    "LOT_NAME": "LOT 83",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Hakan Esmer (1973 - )",
    "ARTIST_HASH": "HAKAN_ESMER",
    "NAME": "\"Heyamola\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>116 x 89 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_83/0.d.webp?w=967&h=1200",
      "./assets/auction_products/2024_DAILY_83/1.d.webp?w=944&h=1200",
      "./assets/auction_products/2024_DAILY_83/2.d.webp?w=1200&h=1105",
      "./assets/auction_products/2024_DAILY_83/3.d.webp?w=1200&h=859"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_83/0.m.webp?w=362&h=450",
      "./assets/auction_products/2024_DAILY_83/1.m.webp?w=354&h=450",
      "./assets/auction_products/2024_DAILY_83/2.m.webp?w=489&h=450",
      "./assets/auction_products/2024_DAILY_83/3.m.webp?w=629&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_86": {
    "LOT_NAME": "LOT 86",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "İbrahim Mısırlıoğlu (1959 - )",
    "ARTIST_HASH": "IBRAHIM_MISIRLIOGLU",
    "NAME": "\"İsimsiz \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>100 x 85 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_86/0.d.webp?w=1032&h=1200",
      "./assets/auction_products/2024_DAILY_86/1.d.webp?w=1045&h=1200",
      "./assets/auction_products/2024_DAILY_86/2.d.webp?w=1200&h=749"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_86/0.m.webp?w=387&h=450",
      "./assets/auction_products/2024_DAILY_86/1.m.webp?w=392&h=450",
      "./assets/auction_products/2024_DAILY_86/2.m.webp?w=721&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_89": {
    "LOT_NAME": "LOT 89",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>50 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_89/0.d.webp?w=1200&h=845",
      "./assets/auction_products/2024_DAILY_89/1.d.webp?w=1200&h=843",
      "./assets/auction_products/2024_DAILY_89/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_89/3.d.webp?w=837&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_89/0.m.webp?w=639&h=450",
      "./assets/auction_products/2024_DAILY_89/1.m.webp?w=641&h=450",
      "./assets/auction_products/2024_DAILY_89/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_89/3.m.webp?w=314&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_90": {
    "LOT_NAME": "LOT 90",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Sırlardaki Kadın\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>160 x 120 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_90/0.d.webp?w=921&h=1200",
      "./assets/auction_products/2024_DAILY_90/1.d.webp?w=890&h=1200",
      "./assets/auction_products/2024_DAILY_90/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_90/3.d.webp?w=871&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_90/0.m.webp?w=345&h=450",
      "./assets/auction_products/2024_DAILY_90/1.m.webp?w=333&h=450",
      "./assets/auction_products/2024_DAILY_90/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_90/3.m.webp?w=326&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_91": {
    "LOT_NAME": "LOT 91",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"İsimsiz \"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>120 x 120  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_91/0.d.webp?w=1200&h=1199",
      "./assets/auction_products/2024_DAILY_91/1.d.webp?w=1200&h=1163",
      "./assets/auction_products/2024_DAILY_91/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_91/3.d.webp?w=752&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_91/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_91/1.m.webp?w=464&h=450",
      "./assets/auction_products/2024_DAILY_91/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_91/3.m.webp?w=282&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_92": {
    "LOT_NAME": "LOT 92",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Gülşah Kurt (1995 - ) ",
    "ARTIST_HASH": "GULSAH_KURT",
    "NAME": "\"Eski I\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>80 x 100 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_92/0.d.webp?w=900&h=1200",
      "./assets/auction_products/2024_DAILY_92/1.d.webp?w=900&h=1200",
      "./assets/auction_products/2024_DAILY_92/2.d.webp?w=1200&h=1105",
      "./assets/auction_products/2024_DAILY_92/3.d.webp?w=883&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_92/0.m.webp?w=338&h=450",
      "./assets/auction_products/2024_DAILY_92/1.m.webp?w=338&h=450",
      "./assets/auction_products/2024_DAILY_92/2.m.webp?w=489&h=450",
      "./assets/auction_products/2024_DAILY_92/3.m.webp?w=331&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_93": {
    "LOT_NAME": "LOT 93",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2015",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_93/0.d.webp?w=1200&h=857",
      "./assets/auction_products/2024_DAILY_93/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_93/2.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_93/0.m.webp?w=629&h=450",
      "./assets/auction_products/2024_DAILY_93/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_93/2.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_95": {
    "LOT_NAME": "LOT 95",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2010",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_95/0.d.webp?w=1200&h=879",
      "./assets/auction_products/2024_DAILY_95/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_95/2.d.webp?w=852&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_95/0.m.webp?w=614&h=450",
      "./assets/auction_products/2024_DAILY_95/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_95/2.m.webp?w=320&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_96": {
    "LOT_NAME": "LOT 96",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Güç ve Hız \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>100 x 100 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_96/0.d.webp?w=1200&h=1195",
      "./assets/auction_products/2024_DAILY_96/1.d.webp?w=1071&h=1200",
      "./assets/auction_products/2024_DAILY_96/2.d.webp?w=1200&h=718",
      "./assets/auction_products/2024_DAILY_96/3.d.webp?w=856&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_96/0.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_96/1.m.webp?w=402&h=450",
      "./assets/auction_products/2024_DAILY_96/2.m.webp?w=752&h=450",
      "./assets/auction_products/2024_DAILY_96/3.m.webp?w=321&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_97": {
    "LOT_NAME": "LOT 97",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Uğur Pekcan (1992 - ) ",
    "ARTIST_HASH": "UGUR_PEKCAN",
    "NAME": "\"Döngü \"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>70 x 100 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_97/0.d.webp?w=1200&h=849",
      "./assets/auction_products/2024_DAILY_97/1.d.webp?w=1200&h=863",
      "./assets/auction_products/2024_DAILY_97/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_97/3.d.webp?w=845&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_97/0.m.webp?w=636&h=450",
      "./assets/auction_products/2024_DAILY_97/1.m.webp?w=626&h=450",
      "./assets/auction_products/2024_DAILY_97/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_97/3.m.webp?w=317&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_98": {
    "LOT_NAME": "LOT 98",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"İsimsiz \"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>90 x 90  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_98/0.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_98/1.d.webp?w=1200&h=1197",
      "./assets/auction_products/2024_DAILY_98/2.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_98/3.d.webp?w=781&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_98/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_98/1.m.webp?w=451&h=450",
      "./assets/auction_products/2024_DAILY_98/2.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_98/3.m.webp?w=293&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_99": {
    "LOT_NAME": "LOT 99",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"İsimsiz \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>40 x 40  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_99/0.d.webp?w=1200&h=1175",
      "./assets/auction_products/2024_DAILY_99/1.d.webp?w=1200&h=1178",
      "./assets/auction_products/2024_DAILY_99/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_99/3.d.webp?w=825&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_99/0.m.webp?w=460&h=450",
      "./assets/auction_products/2024_DAILY_99/1.m.webp?w=459&h=450",
      "./assets/auction_products/2024_DAILY_99/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_99/3.m.webp?w=309&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_100": {
    "LOT_NAME": "LOT 100",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Kıvanç Gülhan (1963 - )",
    "ARTIST_HASH": "KIVANC_GULHAN",
    "NAME": "\"İsimsiz\"",
    "TEXT": "İmzalı, Karton Üzerine Sulu Boya<br>22 x 35 cm<br>2010",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_100/0.d.webp?w=960&h=1200",
      "./assets/auction_products/2024_DAILY_100/1.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_100/2.d.webp?w=847&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_100/0.m.webp?w=360&h=450",
      "./assets/auction_products/2024_DAILY_100/1.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_100/2.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_103": {
    "LOT_NAME": "LOT 103",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_103/0.d.webp?w=1200&h=858",
      "./assets/auction_products/2024_DAILY_103/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_103/2.d.webp?w=881&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_103/0.m.webp?w=629&h=450",
      "./assets/auction_products/2024_DAILY_103/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_103/2.m.webp?w=330&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_106": {
    "LOT_NAME": "LOT 106",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_106/0.d.webp?w=1200&h=865",
      "./assets/auction_products/2024_DAILY_106/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_106/2.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_106/0.m.webp?w=624&h=450",
      "./assets/auction_products/2024_DAILY_106/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_106/2.m.webp?w=337&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_107": {
    "LOT_NAME": "LOT 107",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Şahlanışa Doğru\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>50 x 70 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_107/0.d.webp?w=1200&h=842",
      "./assets/auction_products/2024_DAILY_107/1.d.webp?w=1200&h=838",
      "./assets/auction_products/2024_DAILY_107/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_107/3.d.webp?w=845&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_107/0.m.webp?w=641&h=450",
      "./assets/auction_products/2024_DAILY_107/1.m.webp?w=645&h=450",
      "./assets/auction_products/2024_DAILY_107/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_107/3.m.webp?w=317&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_108": {
    "LOT_NAME": "LOT 108",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Işıl Çalık (1965 - )",
    "ARTIST_HASH": "ISIL_CALIK",
    "NAME": "\"Mor Salkımlı Avlu\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>80 x 60 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_108/0.d.webp?w=899&h=1200",
      "./assets/auction_products/2024_DAILY_108/1.d.webp?w=899&h=1200",
      "./assets/auction_products/2024_DAILY_108/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_108/3.d.webp?w=832&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_108/0.m.webp?w=337&h=450",
      "./assets/auction_products/2024_DAILY_108/1.m.webp?w=337&h=450",
      "./assets/auction_products/2024_DAILY_108/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_108/3.m.webp?w=312&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_109": {
    "LOT_NAME": "LOT 109",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Elif Şule Şanlı (2000 - )",
    "ARTIST_HASH": "ELIF_SULE_SANLI",
    "NAME": "\"Karanlığın Gölgesinde \"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>80 x 60 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_109/0.d.webp?w=908&h=1200",
      "./assets/auction_products/2024_DAILY_109/1.d.webp?w=915&h=1200",
      "./assets/auction_products/2024_DAILY_109/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_109/3.d.webp?w=814&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_109/0.m.webp?w=341&h=450",
      "./assets/auction_products/2024_DAILY_109/1.m.webp?w=343&h=450",
      "./assets/auction_products/2024_DAILY_109/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_109/3.m.webp?w=305&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_110": {
    "LOT_NAME": "LOT 110",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - )",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Mavi Boşluk\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>25 x 35 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_110/0.d.webp?w=1200&h=851",
      "./assets/auction_products/2024_DAILY_110/1.d.webp?w=1200&h=839",
      "./assets/auction_products/2024_DAILY_110/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_110/3.d.webp?w=815&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_110/0.m.webp?w=635&h=450",
      "./assets/auction_products/2024_DAILY_110/1.m.webp?w=643&h=450",
      "./assets/auction_products/2024_DAILY_110/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_110/3.m.webp?w=306&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_111": {
    "LOT_NAME": "LOT 111",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Mavi Yalnızlık\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>50 x 70  cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_111/0.d.webp?w=1200&h=839",
      "./assets/auction_products/2024_DAILY_111/1.d.webp?w=1200&h=850",
      "./assets/auction_products/2024_DAILY_111/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_111/3.d.webp?w=846&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_111/0.m.webp?w=644&h=450",
      "./assets/auction_products/2024_DAILY_111/1.m.webp?w=635&h=450",
      "./assets/auction_products/2024_DAILY_111/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_111/3.m.webp?w=317&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_112": {
    "LOT_NAME": "LOT 112",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70  cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_112/0.d.webp?w=1200&h=863",
      "./assets/auction_products/2024_DAILY_112/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_112/2.d.webp?w=858&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_112/0.m.webp?w=625&h=450",
      "./assets/auction_products/2024_DAILY_112/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_112/2.m.webp?w=322&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_113": {
    "LOT_NAME": "LOT 113",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Işıl Çalık (1965 - )",
    "ARTIST_HASH": "ISIL_CALIK",
    "NAME": "\"Keyifli Bir Yürüyüş \"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>80 x 60 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_113/0.d.webp?w=839&h=1200",
      "./assets/auction_products/2024_DAILY_113/1.d.webp?w=889&h=1200",
      "./assets/auction_products/2024_DAILY_113/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_113/3.d.webp?w=888&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_113/0.m.webp?w=315&h=450",
      "./assets/auction_products/2024_DAILY_113/1.m.webp?w=333&h=450",
      "./assets/auction_products/2024_DAILY_113/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_113/3.m.webp?w=333&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_117": {
    "LOT_NAME": "LOT 117",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Uğur Pekcan (1992 - ) ",
    "ARTIST_HASH": "UGUR_PEKCAN",
    "NAME": "\"Yaşlı Ağaç\"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>100 x 100 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_117/0.d.webp?w=1155&h=1200",
      "./assets/auction_products/2024_DAILY_117/1.d.webp?w=1200&h=1090",
      "./assets/auction_products/2024_DAILY_117/2.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_117/3.d.webp?w=705&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_117/0.m.webp?w=433&h=450",
      "./assets/auction_products/2024_DAILY_117/1.m.webp?w=495&h=450",
      "./assets/auction_products/2024_DAILY_117/2.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_117/3.m.webp?w=264&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_120": {
    "LOT_NAME": "LOT 120",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Müge Cücü (1978 - ) ",
    "ARTIST_HASH": "MUGE_CUCU",
    "NAME": "\"Depresyon \"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>100 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_120/0.d.webp?w=847&h=1200",
      "./assets/auction_products/2024_DAILY_120/1.d.webp?w=952&h=1200",
      "./assets/auction_products/2024_DAILY_120/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_120/3.d.webp?w=822&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_120/0.m.webp?w=318&h=450",
      "./assets/auction_products/2024_DAILY_120/1.m.webp?w=357&h=450",
      "./assets/auction_products/2024_DAILY_120/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_120/3.m.webp?w=308&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_121": {
    "LOT_NAME": "LOT 121",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Huseyingazi Mount\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>40 x 40  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_121/0.d.webp?w=1200&h=1190",
      "./assets/auction_products/2024_DAILY_121/1.d.webp?w=1200&h=1166",
      "./assets/auction_products/2024_DAILY_121/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_121/3.d.webp?w=675&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_121/0.m.webp?w=454&h=450",
      "./assets/auction_products/2024_DAILY_121/1.m.webp?w=463&h=450",
      "./assets/auction_products/2024_DAILY_121/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_121/3.m.webp?w=253&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_123": {
    "LOT_NAME": "LOT 123",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Filiz Aksu (1977 - ) ",
    "ARTIST_HASH": "FILIZ_AKSU",
    "NAME": "\"İz Bırakanlar \"",
    "TEXT": "İmzalı, -<br>100 x 150 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_123/0.d.webp?w=1200&h=796",
      "./assets/auction_products/2024_DAILY_123/1.d.webp?w=1200&h=778",
      "./assets/auction_products/2024_DAILY_123/2.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_123/3.d.webp?w=831&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_123/0.m.webp?w=678&h=450",
      "./assets/auction_products/2024_DAILY_123/1.m.webp?w=694&h=450",
      "./assets/auction_products/2024_DAILY_123/2.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_123/3.m.webp?w=312&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_125": {
    "LOT_NAME": "LOT 125",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Bağışlayan\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>130 x 200 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_125/0.d.webp?w=1200&h=778",
      "./assets/auction_products/2024_DAILY_125/1.d.webp?w=1200&h=796",
      "./assets/auction_products/2024_DAILY_125/2.d.webp?w=1200&h=725",
      "./assets/auction_products/2024_DAILY_125/3.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_125/0.m.webp?w=694&h=450",
      "./assets/auction_products/2024_DAILY_125/1.m.webp?w=679&h=450",
      "./assets/auction_products/2024_DAILY_125/2.m.webp?w=745&h=450",
      "./assets/auction_products/2024_DAILY_125/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_128": {
    "LOT_NAME": "LOT 128",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Yonca Usta (1992 - )",
    "ARTIST_HASH": "YONCA_USTA",
    "NAME": "\"Son Rüya - No: 12\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>35 x 35 cm<br>-",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_128/0.d.webp?w=1197&h=1200",
      "./assets/auction_products/2024_DAILY_128/1.d.webp?w=1200&h=1198",
      "./assets/auction_products/2024_DAILY_128/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_128/3.d.webp?w=851&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_128/0.m.webp?w=449&h=450",
      "./assets/auction_products/2024_DAILY_128/1.m.webp?w=451&h=450",
      "./assets/auction_products/2024_DAILY_128/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_128/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_129": {
    "LOT_NAME": "LOT 129",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - )",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Ebrar\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>60 x 50  cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_129/0.d.webp?w=964&h=1200",
      "./assets/auction_products/2024_DAILY_129/1.d.webp?w=987&h=1200",
      "./assets/auction_products/2024_DAILY_129/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_129/3.d.webp?w=675&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_129/0.m.webp?w=362&h=450",
      "./assets/auction_products/2024_DAILY_129/1.m.webp?w=370&h=450",
      "./assets/auction_products/2024_DAILY_129/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_129/3.m.webp?w=253&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_131": {
    "LOT_NAME": "LOT 131",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Uğur Pekcan (1992 - )",
    "ARTIST_HASH": "UGUR_PEKCAN",
    "NAME": "\"Şaman Yolculuğu\"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>160 x 90  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_131/0.d.webp?w=1200&h=696",
      "./assets/auction_products/2024_DAILY_131/1.d.webp?w=1200&h=744",
      "./assets/auction_products/2024_DAILY_131/2.d.webp?w=1200&h=725",
      "./assets/auction_products/2024_DAILY_131/3.d.webp?w=745&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_131/0.m.webp?w=776&h=450",
      "./assets/auction_products/2024_DAILY_131/1.m.webp?w=725&h=450",
      "./assets/auction_products/2024_DAILY_131/2.m.webp?w=745&h=450",
      "./assets/auction_products/2024_DAILY_131/3.m.webp?w=279&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_132": {
    "LOT_NAME": "LOT 132",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "İmzalı, Karton Üzerine Karışık Teknik<br>50 x 70 cm<br>2016",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_132/0.d.webp?w=1200&h=853",
      "./assets/auction_products/2024_DAILY_132/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_132/2.d.webp?w=866&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_132/0.m.webp?w=633&h=450",
      "./assets/auction_products/2024_DAILY_132/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_132/2.m.webp?w=325&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_134": {
    "LOT_NAME": "LOT 134",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Yonca Usta (1992 - )",
    "ARTIST_HASH": "YONCA_USTA",
    "NAME": "\"Zen - No 1\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>40 x 40 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_134/0.d.webp?w=1193&h=1200",
      "./assets/auction_products/2024_DAILY_134/1.d.webp?w=1195&h=1200",
      "./assets/auction_products/2024_DAILY_134/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_134/3.d.webp?w=840&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_134/0.m.webp?w=448&h=450",
      "./assets/auction_products/2024_DAILY_134/1.m.webp?w=448&h=450",
      "./assets/auction_products/2024_DAILY_134/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_134/3.m.webp?w=315&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_135": {
    "LOT_NAME": "LOT 135",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Uğur Pekcan (1992 - )",
    "ARTIST_HASH": "UGUR_PEKCAN",
    "NAME": "\"Şaman Eli\"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>112 x 112 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_135/0.d.webp?w=1200&h=1185",
      "./assets/auction_products/2024_DAILY_135/1.d.webp?w=1200&h=1128",
      "./assets/auction_products/2024_DAILY_135/2.d.webp?w=1200&h=718",
      "./assets/auction_products/2024_DAILY_135/3.d.webp?w=696&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_135/0.m.webp?w=456&h=450",
      "./assets/auction_products/2024_DAILY_135/1.m.webp?w=479&h=450",
      "./assets/auction_products/2024_DAILY_135/2.m.webp?w=752&h=450",
      "./assets/auction_products/2024_DAILY_135/3.m.webp?w=261&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_138": {
    "LOT_NAME": "LOT 138",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Yonca Usta (1992 - )",
    "ARTIST_HASH": "YONCA_USTA",
    "NAME": "\"Zen - No 2\"",
    "TEXT": "İmzalı, Tuval Üzerine Akrilik Boya<br>40 x 40 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_138/0.d.webp?w=1195&h=1200",
      "./assets/auction_products/2024_DAILY_138/1.d.webp?w=1200&h=1197",
      "./assets/auction_products/2024_DAILY_138/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_138/3.d.webp?w=838&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_138/0.m.webp?w=448&h=450",
      "./assets/auction_products/2024_DAILY_138/1.m.webp?w=451&h=450",
      "./assets/auction_products/2024_DAILY_138/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_138/3.m.webp?w=314&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_139": {
    "LOT_NAME": "LOT 139",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Ertuğrul Ateş (1954 - )",
    "ARTIST_HASH": "ERTUGRUL_ATES",
    "NAME": "\"Arka Bahçe VII\"",
    "TEXT": "İmzalı, Tuval Üzerine Yağlı Boya<br>150 (çap)  cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_139/0.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_139/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_139/2.d.webp?w=1200&h=725",
      "./assets/auction_products/2024_DAILY_139/3.d.webp?w=842&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_139/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_139/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_139/2.m.webp?w=745&h=450",
      "./assets/auction_products/2024_DAILY_139/3.m.webp?w=316&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_142": {
    "LOT_NAME": "LOT 142",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Müge Cücü (1978 - ) ",
    "ARTIST_HASH": "MUGE_CUCU",
    "NAME": "\"Kafamdaki Kaos\"",
    "TEXT": "İmzalı, Tuval Üzerine Karışık Teknik<br>50 x 60 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_142/0.d.webp?w=960&h=1200",
      "./assets/auction_products/2024_DAILY_142/1.d.webp?w=1045&h=1200",
      "./assets/auction_products/2024_DAILY_142/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_142/3.d.webp?w=826&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_142/0.m.webp?w=360&h=450",
      "./assets/auction_products/2024_DAILY_142/1.m.webp?w=392&h=450",
      "./assets/auction_products/2024_DAILY_142/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_142/3.m.webp?w=310&h=450"
    ],
    "STATEMENT": "-"
  }
};

export const auction_config_tr = {
  AUCTION_STATE_MAP: {
    AUCTION: 'Başlamadı',
    LIVE: 'Canlı',
    KNOCKDOWN: 'Bitti',
  },
  CATALOG_LOT_IS_YOURS: 'Sizde',
  LOT_IS_YOURS: 'Lot Sizde',
  REQUIRED_PARTS: '* Zorunlu Alanları Belirtmektedir',
  NO_KYC_DESCRIPTION: 'Müzayede tekliflerinde güvenlik sebebiyle "Kimlik Beyanı" yapmanız gerekmektedir.',
  BILLING: {
    SAVE_ADDRESS: 'Adresi Kaydet',
    CANCEL: 'Vazgeç',
  },
  APPROVE_PAYMENT: {
    HEADER: 'ÖDEMEYİ ONAYLA',
    BODY: 'Belirtilen kurallara göre Havale/Eft işlemini gerçekleştirdiğimi onaylıyorum.',
    CANCEL: 'Vazgeç',
    APPROVE: 'Ödemeyi Onayla',
  },
  DELETE_ADDRESS: {
    HEADER: 'ADRES SİLME',
    BODY: 'Kayıtlı adresi silmek istediğinize emin misiniz?',
    CANCEL: 'Vazgeç',
    APPROVE: 'Adresi Sil',
  },
  LIVE_CATALOG: 'Güncel Artiox Müzayedeleri',
  PAST_CATALOG: 'Geçmiş Artiox Müzayedeleri',
  PRODUCT_INFO: 'Eser Satış Bilgileri',
  CATALOG_TYPE: {
    CATALOG: 'Katalog',
    DAILY: 'Günlük',
    STATUS: {
      LIVE: 'Canlı',
      UPCOMING: 'Yakında',
      ENDED: 'Bitti',
    },
    CATALOG_TEXT: 'Katalog Müzayedeler belirlenen tarihler arasında belirlenen sanat eserlerini müzayede içerisinde sunan bir Artiox Müzayede sistemidir.',
    CATALOG_IMG: './assets/images/iphone_artiox_rotated.png',
    DAILY_TEXT: 'Günlük Müzayedeler belirlenen tarihler arasında belirlenen sanat eserlerini müzayede içerisinde sunan bir Artiox Müzayede sistemidir.',
    DAILY_IMG: './assets/images/iphone_artiox.png',
  },
  FOLLOW: 'Takip',
  SHARE: {
    SHARE: 'Paylaş',
    HEADER: 'Müzayede Paylaş',
    DESCRIPTION: 'Artiox Müzayede bulunan eseri sosyal medya hesapların paylaşabilirsin.',
    OR: 'veya',
    TWITTER: {
      TEXT: '',
      HASHTAGS: '',
      VIA: '',
    },
    LINKEDIN: {
      TITLE: '',
      SUMMARY: '',
    },
    WHATSAPP: {
      TEXT: '',
    },
  },
  AGREEMENT: {
      TITLE: 'Artiox Müzayede Katılım Kuralları, Koşullar ve Sözleşmeler',
      SUBTITLE: 'Artiox Kripto Varlık Alım Satım Platformu A.Ş.’nin düzenleyeceği online müzayedelere katılım sağlamak için onaylamanız gereken kurallar ve sözleşmeler:',
      RULES: ['Artiox Kripto Varlık Alım Satım Platformu A.Ş.’nin (kısaca “Artiox” veya “Şirket” olarak anılacaktır) düzenlediği online müzayedelere katılan üyeler/müşteriler aşağıdaki şartları peşinen kabul ederler;'
        ,
        '1. Artiox satıcı için aracı olup, elektronik ortamda gerçekleştirdiği aracılık hizmetinden dolayı satış anında Alıcı’dan Alıma Aracılık Komisyonu’na (kısaca “Alıcı Komisyonu” olarak anılacaktır) hak kazanır. Alıcıya kesilen faturada son teklif fiyatı üzerine Alıcı Komisyonu ve mevzuat gereği son teklif fiyatı ve alıcı komisyonu üzerinden KDV eklenir. Alıcı Komisyonu net 10% dur. Mevzuat gereği hem son teklif fiyatı hem Alıcı Komisyonu üzerine 20% KDV eklenir. Yasal KDV oranlarındaki artış veya azalış olması halinde güncel KDV oranı uygulanır. Alıcı müzayede konusu eseri almaktan vazgeçse dahi cezai şart yanında %10 komisyon bedeli ve komisyon bedeline ilave edilecek olan yasal KDV’yi ödemekle mükelleftir. Artiox’un bu kapsamda sunduğu hizmet; elektronik ortamda anında ifa edilen aracılık hizmeti olup cayma hakkı kapsamı dışındadır.'
        ,
        '2. Online Müzayedemizde satışa sunulan eserler 2863 sayılı Kültür ve Tabiat Varlıkları Kanunu uyarınca müze uzmanları tarafından kontrol edilmekte olup, Kanun kapsamına giren ve gerek görülenlerine belge verilmektedir.'
        ,
        '3. Online müzayedelerimize katılabilmek için müşterilerin www.artiox.com adresinden website’mize üye olması gerekmekte veya Artiox mobil uygulaması üzerinden üye olması gerekmektedir.'
        ,
        '4. Şirket’in düzenlediği online müzayedelerde yer alan eserler/ürünler satış öncesinde online katalog website’mizde yayına girdikten itibaren Artiox’un Kızılırmak Mah. Dumlupınar Bul. A Blok No:9A D:287 Çankaya Ankara adresinde teşhir edilerek müşterilerin incelemesine sunulmaktadır. Müzayedeye katılanlar eserleri/ürünleri Artiox’un yukarıdaki adresinde görmüş ve incelemiş olduklarını beyan ve kabul ederler. Müşteriler, müzayedede satışa sunulan tüm eserlerin “haliyle” satışa sunulduğunu, satılan eser/ürün için teklif vermeden evvel yeterince incelediğini ve Borçlar Kanunu’nun 223. Maddesi’nde belirtilen hükümlerden feragat ettiğini kabul ve beyan eder. Her eserin/ürünün yanında “haliyle” yazma zorunluluğu yoktur. Yayına giren müzayede ile ilgili olarak mobil uygulama ve website’mizde tanıtımlarda verilen tüm bilgiler Şirket çalışanlarımızın kanaatleri olup, taahhüt niteliğinde değil genel bilgi niteliğindedir. Katılımcılar bu durumu peşinen kabul ederler. Artiox satılan eserin/ürünün tekellüfü (garantisi) ile mükellef değildir. Alıcı bu hususta Artiox’dan herhangi bir itiraz ve talepte bulunmayacağını kabul ve beyan eder. Online ortamda düzenlenen müzayedelerimizde her ne kadar teklif verme süresi gün/günler önce başlıyor olsa da müzayede bitiş tarihinde müzayede sonlanmadan eser satışı yapılmamaktadır.'
        ,
        '5. Online müzayedelerimizde fiyat artırımı “Teklif Ver” butonuna tıklanarak yapılır. Müşteri online olarak vermiş olduğu teklif ile bağlıdır. Müzayedede online olarak satışa sunulan eser/ürün, Artiox tarafından belirlenen müzayede bitimi itibari ile en yüksek teklifi vermiş olan müşteriye ihale edilir. Alıcı, her ne sebeple olursa olsun müzayedenin butlanını dermeyan edemez. Katılımcılar her bir eser/ürün için verilen sürenin sonuna kadar teklif verebilirler, sözkonusu sürenin bitimi itibari ile en yüksek teklifi vermiş olan müşteri sözkonusu eser/ürün ile ilgili artırmayı kazanmış olur.'
        ,
        '6. Artiox her “Teklif Ver” butonuna tıklandığındaki değer artışlarını belirleme ve gerekli gördüğünde değer artış aralıklarını değiştirme hakkına sahiptir.'
        ,
        '7. Artiox satışa çıkarılan bir eserin/ürünün başlangıç fiyatını muhammen (konsinye tesellüm) bedelinin altında belirleme hakkına sahiptir.'
        ,
        '8. Online müzayedelerimize katılabilmek için, müşterilerin mobil uygulama veya Website’mize üyelik esnasında oluşturduğu şifre’yi kullanması gerekmektedir. “şifre” kişiye özeldir, üye müşteri dışında birinin kullanması ve/veya üye müşteri’nin kendisi dışında birine kullandırması yasaktır. “şifre” sahibi müşteri kendisine ait “şifre”nin başkaları tarafından kullanımı durumunda, Artiox’un hiçbir sorumluluk kabul etmeyeceğini peşinen kabul eder.'
        ,
        '9. Artiox verilen teklifler, teklif verenler, en yüksek teklifi veren vs gibi bilgileri kendi nezdinde güvenlik altına almaktadır, katılımcılar bu kayıtlara herhangi bir itiraz haklarının olmadıklarını peşinen kabul ve beyan ederler.'
        ,
        '10. Satın alınan tüm eserler için, eserleri satın alan müşterilerin mobil uygulama veya website üyelik kayıtlarındaki ad, soyad, ve adrese veya müşterilerin belirlediği şirket adına ve adrese Artiox tarafından fatura düzenlenir. Ancak, fatura düzenlenmesi, Artiox’un satıcı olduğu anlamına gelmez, Artiox satıcı değil, satışa aracılık eden aracıdır.'
        ,
        '11. Müzayedede satılan her eser/ürün için Aritox’un Alıcı Komisyonu müzayede sona erdiği andan itibaren muaccel hale gelir. Alıcı müzayedede satın aldığı eserlerin/ürünlerin toplam bedelini (satış bedeli, Satış Komisyonu, ilgili KDV’ler vs) işbu Artiox Müzayede Katılım Şartnamesi’ne uygun olarak tümüyle ve zamanında ödemediği takdirde, Artiox sözkonusu satışı iptal etmek hakkına sahiptir, ancak bu durumda işbu Müzayede Katılım Şartnamesi’ne uygun olarak ödeme yükümlülüğünü yerine getirmemiş olan alıcı Alıcı Komisyonu’na ek olarak eser/ürün sahiplerinden alınan Satıma Aracılık Komisyonu (kısaca “Satıcı Komisyonu” olarak anılacaktır), komisyonlara dair KDV’ler, Artiox’un uğradığı bilcümle zararlarını fatura tarihinden itibaren ticari faizi ile birlikte ödemekle yükümlüdür. Artiox’un sair dava ve talep hakları saklıdır.'
        ,
        '12. Artiox’un online olarak sunmuş olduğu müzayede hizmeti, elektronik ortamda anında ifa edilen aracılık hizmeti olup, cayma hakkı kapsamı dışındadır. Artiox’un elektronik ortamda düzenlediği müzayedeler TBK 274 ve devamı maddelerindeki düzenlemeye göre gerçekleştiğinden ve Tüketici Kanunu’nun 48. Maddesinde düzenlenen mesafeli satış sözleşmesi olarak kabul edilemeyeceğinden müzayedeye katılanların cayma hakkı yoktur. Müzayede de son teklifi vererek müzayedeyi kazanan kişi veya kuruluş veya açık artırma üzerine kalan kişi eserin bedelini 7 (yedi) gün içinde tam olarak ödemediği takdirde Artiox dilerse satış akdini feshe, %20 cezai şartı ve uğradığı bilcümle zararlarını ticari faiz ile fatura tarihinden itibaren işletilecek aylık %10 faiz ile birlikte tahsile yetkilidir. Artiox’un sair dava ve talep hakları saklıdır. Fatura bedeli tahsil edilinceye kadar geçecek süre içinde eserin muhafazası için Artiox’un yapacağı bilcümle masraf alıcı tarafından ödenmedikçe satılan eser alıcıya teslim edilmez.'
        ,
        '13. Alıcı alım bedeli, alıcı komisyonu ve KDV’den oluşan toplam Alım Bedeli’ni tümüyle müzayede bitim tarihinden itibaren en geç 7 (yedi) gün içinde ödemekle yükümlüdür. Alıcı Artiox’dan satın almış olduğu eserleri/ürünleri teslim alabilmek için Toplam Alım Bedeli’ni tümüyle ödemiş olması gerekmektedir.'
        ,
        '14. Alıcı Toplam Alım Bedeli’ni tümüyle ödedikten sonra satın aldığı eserleri/ürünleri Artiox adresinden kendi teslim alır. Alıcı maliyeti kendine ait olmak üzere Artiox’un araçları ile, nakliye ya da kargo yoluyla satın alınanların teslimatını talep edebilir, bu durumda nakliye sırasında oluşabilecek herhangi hasardan Şirket’i sorumlu tutmayacağını peşinen kabul ve taahhüt eder. Ayrıca kargo firmalarından kaynaklanan gecikmelerden Artiox sorumlu tutulamaz. Şehir içi teslimlerde 7 (yedi) işgünü, şehir dışı teslimlerde 14 (ondört) işgünü içinde Artiox ürünü/eşyayı alıcıya kargo yoluyla gönderecektir/teslim edecektir. Eşyanın kargoya verildiği Artiox tarafından web sitesi veya mobil uygulamadaki üyelik ekranından, e-mail, telefon, kısa mesaj ve benzeri vasıtaların herhangi biriyle alıcıya bildirilebilir.'
        ,
        '15. Alıcıların mobil uygulama veya website’mize üyelik kaydında belirttikleri açık posta adresi, e-posta, cep telefonu gibi iletişim bilgilerine posta, e-posta, SMS, veya telefonla yapılan bildirim, tebligatların, kargo ile eser/ürün gönderimlerinin üyelik kaydında verilen bilgilerdeki yanlışlık, değişiklik nedeniyle alıcıya geç ulaşmasından veya hiç ulaşmamasından veya P.T.T./kargodan kaynaklanan gecikmelerden Artiox sorumlu tutulamaz.'
        ,
        '16. Artiox satışa sunulan eserlerin/ürünlerin beyan edilen niteliklere haiz olmaması, orijin itibari ile beyana uygun olmaması, hak sahibinin rızası dışında elden çıkmış olması, veya sair nedenlerden ayıplı olması durumlarında, Artiox’un satıcı olmaması nedeniyle hukuken sorumlu tutulamaz. Sözkonusu durumlarda, hukuki sorumluluk bu eserlerin/ürünlerin satışa arzını talep eden(ler)e aittir.'
        ,
        '17. Artiox satışa sunulan herhangi bir veya birden fazla eser(ler)i/ürün(ler)i, müzayededen geri çekmek, başka eser(ler)/ürün(ler) ile birlikte satmak, birbirinden ayırmak ve satışını reddetmek hakkına sahiptir. Şirket düzenlediği online müzayedelerde yer alan eser(ler)/ürün(ler) hakkında müzayede bitimine kadar ortaya çıkan her türlü itiraz ve bildirimin değerlendirilmesi sonucunda sözkonusu eser(ler)i/ürün(ler)i müzayede kapsamından çıkarmak ve gerekli gördüğü durumlarda yasal prosedür uygulamak hakkına sahiptir.'
        ,
        '18. 18 yaşından küçükler üye olamaz, müzayedelere katılamaz. Artiox herhangi bir mazaret göstermeksizin şüpheli ve/veya sakıncalı gördüğü kişileri üye olarak kabul etmeme, üye olmuş olsalar dahi müzayedeye kabul etmeme, üyelikten çıkarma, ve/veya devam eden müzayedede vermiş olduğu tekliflerin bir kısmını veya tümünü iptal etme yetkisine sahiptir. Ayrıca Artiox hakkında, Şirket’in satıcı için aracı konumunda bulunmasından dolayı BK 214 ve 218 maddelerinde işlenen zapttan sorumluluk hükümleri uygulanamaz.'
        ,
        '19. 5846 sayılı kanunun 45. Maddesi ve Bakanlar Kurulu’nun 27.09.2006 tarihli ve 2006/10880 sayılı kararı uyarınca, bu kapsamda, belge düzenine uyulması koşulu ile sanatçılara veya hak sahiplerine ödenmesi gereken paylar ile ilgili olarak müzayedede eserleri satılacak olan eserler sahiplerine Artiox’un rücu hakkı saklıdır.'
        ,
        '20. Artırmada bulunup online müzayedelere katılan müşteriler Artiox’un elektronik ortamda düzenlediği müzayedelere katılım şartlarını düzenleyen işbu Artiox Müzayede Katılım Şartnamesi’nde yer alan şartların tümünü ve ayrıca bunlara ilişkin Artiox’un diğer hak ve vecibelerini peşinen kabul ettiklerini beyan ederler. Katılımcılar satış gerçekleştikten sonra herhangi bir kusur itirazında bulunmayacaklarını peşinen kabul, beyan ve taahhüt ederler.'
        ,
        '21. Yayına girmiş ve/veya başlamış online müzayedelerde yazım hataları olması olması halinde Artiox’un açıkladığı düzeltmeler esas alınır.'
        ,
        '22. Artiox’un mobil uygulama veya websitesi’nde ve dolayısıyla online ortamda düzenlediği müzayedelerde yayınladığı her türlü yazı ve fotoğraf Şirket’in izni olmadan kısmen veya tümüyle yayınlanamaz.'
        ,
        '23. Şirket’in online ortamda düzenlediği müzayede yayına girdikten sonra çıkan yeni yasalar ve/veya vergiler, yasa ve/veya vergi oranlarında değişiklikler alıcılara yansıtılacak olup, Artiox sorumlu tutulmayacaktır.'
        ,
        '24. Türkiye dışında yerleşim alıcılar müzayedede ilgilendikleri eserlerin/ürünlerin Türkiye dışına çıkarılması ve bulundukları ülkeye girişi ile ilgili yasaları, vergileri ve nakliye dahil tüm maliyeti müzayedeye katılmadan önce incelemekle yükümlüdür. Alıcılar satın almış oldukları eserlerin yurtdışına çıkarılması ya da bulundukları ülkeye girişi ile ilgili karşılaştıkları yasal, vergisel, nakliye ve/veya maliyet sorunlardan Artiox’u sorumlu tutamaz, satın aldıklarını iade edemez.'
        ,
        '25. Artiox mobil uygulaması veya website’sine üye olan ve online ortamda düzenlediğimiz müzayedelere katılan herkes işbu Artiox Müzayede Katılım Şartnamesi’ni tümüyle okuduğunu ve kabul ettiğini beyan etmiş sayılır.'
        ,
        '26. İşbu katılım şartlarından doğan uyuşmazlıklarda Ankara Mahkemeleri ve İcra Daireleri yetkilidir.'],
      CHECKBOX: 'Artiox Müzayede Sözleşme ve Kuralları’nı okudum, anladım.',
      ACCEPT: 'Onaylıyorum',
      CANCEL: 'Vazgeç',
    },
  BIDS: {STRING: 'Teklifler ($AMOUNT)', TOKENS: ['$AMOUNT']},
  MY_BIDS: {
    ONGOING: 'Lot Sizde',
    EXCEEDED: 'Geçilmiştir',
    WON: 'Kazandınız',
  },
  AUCTION_START: 'Müzayede Başlangıç',
  AUCTION_END: 'Müzayede Bitiş',
  AUCTION_FINALIZED: {
    HEADER: {STRING: 'Müzayede $END itibariyle sona ermiştir. İlginiz için teşekkür ederiz', TOKENS: ['$END']},
    CLOSE: 'Kapanış Fiyatı',
    COST: 'Alıcıya Maliyet',
    OPEN: {STRING: 'Sanat eserinin açılış fiyatı: $OPEN', TOKENS: ['$OPEN']},
  },
  OPENING_PRICE: 'Açılış Fiyatı',
  CURRENT_PRICE: 'Güncel Fiyatı',
  BID: 'Teklif Ver',
  QUICK_BID: 'Hızlı Teklif',
  BID_RULES: {
    TITLE: 'Müzayede Teklif Sistemi',
    SUBTITLE: 'Artiox Müzayede’nin teklif kuralları ve teklif süreçleri aşağıda belirtildiği gibidir. Müzayede kurallarının tamamını görmek için <span><a class="red-link" href="../assets/pdfs/Müzayede Kuralları.pdf" target="_blank">tıklayınız</a></span>.',
    TEXT: [
      'Müzayedelerde teklif verdiğinizde Artiox müzayede kurallarını <span class="purple-highlight">kabul etmiş</span> olursunuz. Artiox Müzayede Kurallarının tamamını incelemek için <span><a class="green-link" href="../assets/pdfs/Müzayede Kuralları.pdf" target="_blank">buraya tıklayınız</a></span>. ',
      'Bir esere verilen tekliflerin her birine <span class="purple-highlight">komisyon ve KDV </span> tutarı eklenir. Verdiğiniz teklifin son halini teklif onaylama ekranında görebilirsiniz.',
      'Müzayede başladığında verilebilecek <span class="purple-highlight">minimum teklif tutarı </span> eser bilgilerinde belirtilmiştir.  Bu tutarın altındaki teklifler geçersiz sayılacaktır.',
      'Artiox tarafından düzenlenen müzayedelerin <span class="purple-highlight">teklif artış oranları </span> önceden belirlenmiştir. Teklif artış oranlarını incelemek için  <span><a class="green-link" href="../assets/pdfs/Teklif Kuralları.pdf" target="_blank">buraya tıklayınız</a></span>. ',
      'Bir esere teklif vereceğinizde teklifinizin kazanması durumunda o eseri satın alacağınızı ve tüm müzayede kurallarını <span class="purple-highlight">onaylamış olursunuz</span> .',
    ]

  },
  DURATION_RULES: {
    TITLE: 'Müzayede Süre Kuralları',
    SUBTITLE: 'Artiox Müzayede’nin süre kuralları ve süre açıklamaları aşağıda belirtildiği gibidir. Müzayede kurallarının tamamını görmek için <span><a class="red-link" href="../assets/pdfs/Müzayede Kuralları.pdf" target="_blank">tıklayınız</a></span>.',
    TEXT: [
      'Katalog ve Günlük Müzayedelerin başlangıç ve bitiş zamanları <span class="purple-highlight">önceden belirlenmiştir</span> . Katolog Müzayedelerde listelenen eserler açık artırmaya aynı anda çıkarken,  Katalogların içinde yer alan eserlerin belirlenmiş süreleri  <span class="purple-highlight">farklılık gösterebilir</span>. ',
      'Müzayedelerde <span class="purple-highlight">son üç dakika içerisinde </span>  teklif verilmesi durumunda süreye +3dk daha eklenir. Bunu takip eden her teklif için açık artırma üçer dakika daha uzar. Müzayede bu şekilde uzadığında başka bir teklif gelmezse verilen son teklif açık artırmanın kazananı olur.',
      'Müzayedelerin durumunu eser açıklamasının altında yer alan <span class="purple-highlight">sayacın rengine </span>  göre takip edebilirsiniz. Devam eden müzayedeler mor ile ifade edilir. Sarı, son üç dakikada teklif geldiğini ve sürenin uzadığını gösterir. Sona eren müzayedeler ise kırmızı ile ifade edilir. ',
    ]
  },
  PRICE_ESTIMATION_RULES: {
    TITLE: 'Fiyat Nasıl Tahmin Edilir',
    TEXT: [
      '-  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. ',
      '-  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. ',
      '-  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. ',
      '-  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. ',
    ],
  },
  PAST_PRODUCT_INFO: {
    TITLE: 'Artiox Müzayede Eser Detay',
    DESCRIPTION: 'Artiox Müzayede sonuçlanan eserin satış detay bilgileri aşağıda yer almaktadır.',
    OPEN: 'Açılış Fiyatı',
    CLOSE: 'Kapanış Fiyatı',
    TOTAL: 'Alıcıya Maliyet (KDV ve Komisyon dahil)',
  },
  BID_REPORT: {
    LOT_IS_YOURS: {
      TITLE: 'Hatırlatma: Lot Sizde',
      TEXT: 'Bu esere ait en yüksek teklif size aittir.',
      BODY: 'Teklifinizi yükseltmek istediğinizden emin misiniz?',
      CANCEL: 'Vazgeç',
      BID: 'Teklifimi Yükselt',
    },
    TITLE: 'Artiox Müzayede Teklif Verme',
    DESCRIPTION: {
      STRING: '$YEAR yılı Günlük Müzayede $LOT nolu lota verdiğiniz teklifi onaylamanız gerekmektedir. Eserin alıcıya maliyeti:',
      TOKENS: ['$YEAR', '$LOT']
    }, // Todo: catalog name
    WARNING: '"Verdiğim teklifin geri alınamayacağını ve cayma hakkımın olmadığını bilerek onaylıyorum."',

    LEGAL: '<a href="../../assets/pdfs/Müzayede%20Kullanıcı%20Sözleşmesi.pdf" target="_blank" > Müzayede sözleşme </a> ve <a href="../../assets/pdfs/Müzayede%20Kuralları.pdf" target="_blank"> kurallarında </a> belirtilen yasal yükümlülükleri kabul ediyorum.',
    BID_PRICE: 'Verilen Teklif Tutarı',
    COMMISSIONS: {STRING: 'Komisyon Tutarı (%$BUYER_FEE)', TOKENS: ['$BUYER_FEE']},
    TAX: {STRING: 'KDV (%$TAX_RATE)', TOKENS: ['$TAX_RATE']},
    TOTAL: 'Alıcıya Maliyet',
    ACCEPT: 'Onayla',
    REJECT: 'Vazgeç',
  },
  ACCOUNT_LEVEL: {
    TITLE: 'Hesap Seviyesi Yeterli Değil',
    TEXT: 'Hesabınızın seviyesi müzayedede teklif vermek için yeterli değil. Hesabınızı seviye 1’e yükselterek işleme devam edebilirsiniz.',
    CANCEL: 'Vazgeç',
    LEVELUP: 'Seviye Yükselt',
  },
  BID_APPROVED: {
    APPROVED: 'Teklifiniz Onaylandı',
    SUCCESS: 'Teklifiniz&nbsp;<span class="green-text">“başarılı”</span>&nbsp;bir şekilde gerçekleşmiştir.',
    HISTORY: 'Verdiğiniz teklifleri “Profil” sekmesinden ulaşabilirsiniz.',
  },
  END_TEXT: ' Müzayede Sona Ermiştir',
  ARTIST: 'Sanatçı',
  LAST_BIDS: {
    TEXT: 'Son Teklifler',
    BIDDER: 'Teklif Veren',
    DATE: 'Teklif Zamanı',
    PRICE: 'Verilen Teklif',
  },
  FILTER: {
    TITLE: 'Filtre',
    ARTIST_PRODUCT: 'Sanatçı/Eser',
    FILTERS: 'Filtreler',
    CLEAR: 'Temizle',
    APPLY: 'Filtrele',
    NO_RESULT: 'Uygun eser bulunamadı.',
    PRICE: 'Fiyat',
    KEYWORD: 'Aranacak Kelimeler',
    PRICE_MIN: 'TL en az',
    PRICE_MAX: 'TL en fazla',
  },
  AUCTION_CATALOG_SORT_OPTIONS: {
    OPTIONS: [
      'Akıllı Sıralama',
      'Fiyata göre artan',
      'Fiyata göre azalan',
      'Güncel fıyata göre artan',
      'Güncel fıyata göre azalan',
      'Lot numarasına göre artan',
      'Lot numarasına göre azalan',
    ]
  },
  NO_LIVE_CATALOG: 'Güncel katalog bulunmamaktadır.',
  NO_PAST_CATALOG: 'Geçmiş katalog bulunmamaktadır.',
  ARTWORKS: 'Eserler',
  STATEMENT_HEADER: 'Açıklama: ',
  AUCTION_PRODUCTS: {
    '2023_DAILY_1': auction_products_config_tr['2023_DAILY_1'],
    '2023_DAILY_2': auction_products_config_tr['2023_DAILY_2'],
    '2023_DAILY_3': auction_products_config_tr['2023_DAILY_3'],
    '2023_DAILY_4': auction_products_config_tr['2023_DAILY_4'],
    '2023_DAILY_5': auction_products_config_tr['2023_DAILY_5'],
    '2023_DAILY_6': auction_products_config_tr['2023_DAILY_6'],
    '2023_DAILY_7': auction_products_config_tr['2023_DAILY_7'],
    '2023_DAILY_8': auction_products_config_tr['2023_DAILY_8'],
    '2023_DAILY_9': auction_products_config_tr['2023_DAILY_9'],
    '2023_DAILY_10': auction_products_config_tr['2023_DAILY_10'],
    '2023_DAILY_11': auction_products_config_tr['2023_DAILY_11'],
    '2023_DAILY_12': auction_products_config_tr['2023_DAILY_12'],
    '2023_DAILY_13': auction_products_config_tr['2023_DAILY_13'],
    '2023_DAILY_14': auction_products_config_tr['2023_DAILY_14'],
    '2023_DAILY_15': auction_products_config_tr['2023_DAILY_15'],
    '2023_DAILY_16': auction_products_config_tr['2023_DAILY_16'],
    '2023_DAILY_17': auction_products_config_tr['2023_DAILY_17'],
    '2023_DAILY_18': auction_products_config_tr['2023_DAILY_18'],
    '2023_DAILY_19': auction_products_config_tr['2023_DAILY_19'],
    '2023_DAILY_20': auction_products_config_tr['2023_DAILY_20'],
    '2023_DAILY_21': auction_products_config_tr['2023_DAILY_21'],
    '2023_DAILY_22': auction_products_config_tr['2023_DAILY_22'],
    '2023_DAILY_23': auction_products_config_tr['2023_DAILY_23'],
    '2023_DAILY_24': auction_products_config_tr['2023_DAILY_24'],
    '2023_DAILY_25': auction_products_config_tr['2023_DAILY_25'],
    '2023_DAILY_26': auction_products_config_tr['2023_DAILY_26'],
    '2023_DAILY_27': auction_products_config_tr['2023_DAILY_27'],
    '2023_DAILY_28': auction_products_config_tr['2023_DAILY_28'],
    '2023_DAILY_29': auction_products_config_tr['2023_DAILY_29'],
    '2023_DAILY_30': auction_products_config_tr['2023_DAILY_30'],
    '2023_DAILY_31': auction_products_config_tr['2023_DAILY_31'],
    '2023_DAILY_32': auction_products_config_tr['2023_DAILY_32'],
    '2023_DAILY_33': auction_products_config_tr['2023_DAILY_33'],
    '2023_DAILY_34': auction_products_config_tr['2023_DAILY_34'],
    '2023_DAILY_35': auction_products_config_tr['2023_DAILY_35'],
    '2023_DAILY_36': auction_products_config_tr['2023_DAILY_36'],
    '2023_DAILY_37': auction_products_config_tr['2023_DAILY_37'],
    '2023_DAILY_38': auction_products_config_tr['2023_DAILY_38'],
    '2023_DAILY_39': auction_products_config_tr['2023_DAILY_39'],
    '2023_DAILY_40': auction_products_config_tr['2023_DAILY_40'],
    '2023_DAILY_41': auction_products_config_tr['2023_DAILY_41'],
    '2023_DAILY_42': auction_products_config_tr['2023_DAILY_42'],
    '2023_DAILY_43': auction_products_config_tr['2023_DAILY_43'],
    '2023_DAILY_44': auction_products_config_tr['2023_DAILY_6'],
    '2023_DAILY_45': auction_products_config_tr['2023_DAILY_10'],
    '2023_DAILY_46': auction_products_config_tr['2023_DAILY_46'],
    '2023_DAILY_47': auction_products_config_tr['2023_DAILY_3'],
    '2023_DAILY_48': auction_products_config_tr['2023_DAILY_48'],
    '2023_DAILY_49': auction_products_config_tr['2023_DAILY_17'],
    '2023_DAILY_50': auction_products_config_tr['2023_DAILY_18'],
    '2023_DAILY_51': auction_products_config_tr['2023_DAILY_1'],
    '2023_DAILY_52': auction_products_config_tr['2023_DAILY_52'],
    '2023_DAILY_53': auction_products_config_tr['2023_DAILY_12'],
    '2023_DAILY_54': auction_products_config_tr['2023_DAILY_14'],
    '2023_DAILY_55': auction_products_config_tr['2023_DAILY_55'],
    '2023_DAILY_56': auction_products_config_tr['2023_DAILY_56'],
    '2023_DAILY_57': auction_products_config_tr['2023_DAILY_5'],
    '2023_DAILY_58': auction_products_config_tr['2023_DAILY_15'],
    '2023_DAILY_59': auction_products_config_tr['2023_DAILY_27'],
    '2023_DAILY_60': auction_products_config_tr['2023_DAILY_19'],
    '2023_DAILY_61': auction_products_config_tr['2023_DAILY_13'],
    '2023_DAILY_62': auction_products_config_tr['2023_DAILY_32'],
    '2023_DAILY_63': auction_products_config_tr['2023_DAILY_18'],
    '2023_DAILY_64': auction_products_config_tr['2023_DAILY_16'],
    '2023_DAILY_65': auction_products_config_tr['2023_DAILY_33'],
    '2023_DAILY_66': auction_products_config_tr['2023_DAILY_21'],
    '2023_DAILY_67': auction_products_config_tr['2023_DAILY_25'],
    '2023_DAILY_68': auction_products_config_tr['2023_DAILY_68'],
    '2023_DAILY_69': auction_products_config_tr['2023_DAILY_69'],
    '2023_DAILY_70': auction_products_config_tr['2023_DAILY_70'],
    '2023_DAILY_71': auction_products_config_tr['2023_DAILY_26'],
    '2023_DAILY_72': auction_products_config_tr['2023_DAILY_29'],
    '2023_DAILY_73': auction_products_config_tr['2023_DAILY_73'],
    '2023_DAILY_74': auction_products_config_tr['2023_DAILY_74'],
    '2023_DAILY_75': auction_products_config_tr['2023_DAILY_75'],
    '2024_DAILY_1': auction_products_config_tr['2023_DAILY_19'],
    '2024_DAILY_2': auction_products_config_tr['2023_DAILY_13'],
    '2024_DAILY_3': auction_products_config_tr['2023_DAILY_32'],
    '2024_DAILY_4': auction_products_config_tr['2023_DAILY_18'],
    '2024_DAILY_5': auction_products_config_tr['2023_DAILY_16'],
    '2024_DAILY_6': auction_products_config_tr['2023_DAILY_33'],
    '2024_DAILY_7': auction_products_config_tr['2023_DAILY_21'],
    '2024_DAILY_8': auction_products_config_tr['2023_DAILY_25'],
    '2024_DAILY_9': auction_products_config_tr['2023_DAILY_68'],
    '2024_DAILY_10': auction_products_config_tr['2023_DAILY_69'],
    '2024_DAILY_11': auction_products_config_tr['2023_DAILY_70'],
    '2024_DAILY_12': auction_products_config_tr['2023_DAILY_26'],
    '2024_DAILY_13': auction_products_config_tr['2023_DAILY_29'],
    '2024_DAILY_14': auction_products_config_tr['2023_DAILY_73'],
    '2024_DAILY_15': auction_products_config_tr['2023_DAILY_74'],
    '2024_DAILY_16': auction_products_config_tr['2023_DAILY_75'],
    '2024_DAILY_17': auction_products_config_tr['2024_DAILY_17'],
    '2024_DAILY_18': auction_products_config_tr['2024_DAILY_18'],
    '2024_DAILY_19': auction_products_config_tr['2023_DAILY_27'],
    '2024_DAILY_20': auction_products_config_tr['2023_DAILY_37'],
    '2024_DAILY_21': auction_products_config_tr['2024_DAILY_21'],
    '2024_DAILY_22': auction_products_config_tr['2024_DAILY_22'],
    '2024_DAILY_23': auction_products_config_tr['2024_DAILY_23'],
    '2024_DAILY_24': auction_products_config_tr['2023_DAILY_7'],
    '2024_DAILY_25': auction_products_config_tr['2023_DAILY_32'],
    '2024_DAILY_26': auction_products_config_tr['2023_DAILY_6'],
    '2024_DAILY_27': auction_products_config_tr['2023_DAILY_10'],
    '2024_DAILY_28': auction_products_config_tr['2023_DAILY_1'],
    '2024_DAILY_29': auction_products_config_tr['2024_DAILY_29'],
    '2024_DAILY_30': auction_products_config_tr['2023_DAILY_12'],
    '2024_DAILY_31': auction_products_config_tr['2024_DAILY_31'],
    '2024_DAILY_32': auction_products_config_tr['2023_DAILY_5'],
    '2024_DAILY_33': auction_products_config_tr['2024_DAILY_33'],
    '2024_DAILY_34': auction_products_config_tr['2023_DAILY_3'],
    '2024_DAILY_35': auction_products_config_tr['2024_DAILY_35'],
    '2024_DAILY_36': auction_products_config_tr['2023_DAILY_18'],
    '2024_DAILY_37': auction_products_config_tr['2024_DAILY_37'],
    '2024_DAILY_38': auction_products_config_tr['2023_DAILY_15'],
    '2024_DAILY_39': auction_products_config_tr['2023_DAILY_27'],
    '2024_DAILY_40': auction_products_config_tr['2024_DAILY_40'],
    '2024_DAILY_41': auction_products_config_tr['2024_DAILY_41'],
    '2024_DAILY_42': auction_products_config_tr['2024_DAILY_42'],
    '2024_DAILY_43': auction_products_config_tr['2024_DAILY_43'],
    '2024_DAILY_44': auction_products_config_tr['2023_DAILY_26'],
    '2024_DAILY_45': auction_products_config_tr['2023_DAILY_25'],
    '2024_DAILY_46': auction_products_config_tr['2023_DAILY_33'],
    '2024_DAILY_47': auction_products_config_tr['2024_DAILY_18'],
    '2024_DAILY_48': auction_products_config_tr['2023_DAILY_42'],
    '2024_DAILY_49': auction_products_config_tr['2024_DAILY_49'],
    '2024_DAILY_50': auction_products_config_tr['2023_DAILY_2'],
    '2024_DAILY_51': auction_products_config_tr['2024_DAILY_51'],
    '2024_DAILY_52': auction_products_config_tr['2023_DAILY_5'],
    '2024_DAILY_53': auction_products_config_tr['2024_DAILY_23'],
    '2024_DAILY_54': auction_products_config_tr['2023_DAILY_12'],
    '2024_DAILY_55': auction_products_config_tr['2024_DAILY_55'],
    '2024_DAILY_56': auction_products_config_tr['2024_DAILY_21'],
    '2024_DAILY_57': auction_products_config_tr['2024_DAILY_57'],
    '2024_DAILY_58': auction_products_config_tr['2024_DAILY_58'],
    '2024_DAILY_59': auction_products_config_tr['2024_DAILY_59'],
    '2024_DAILY_60': auction_products_config_tr['2024_DAILY_60'],
    '2024_DAILY_61': auction_products_config_tr['2024_DAILY_61'],
    '2024_DAILY_62': auction_products_config_tr['2024_DAILY_62'],
    '2024_DAILY_63': auction_products_config_tr['2024_DAILY_63'],
    '2024_DAILY_64': auction_products_config_tr['2024_DAILY_64'],
    '2024_DAILY_65': auction_products_config_tr['2024_DAILY_65'],
    '2024_DAILY_66': auction_products_config_tr['2023_DAILY_24'],
    '2024_DAILY_67': auction_products_config_tr['2024_DAILY_67'],
    '2024_DAILY_68': auction_products_config_tr['2024_DAILY_68'],
    '2024_DAILY_69': auction_products_config_tr['2023_DAILY_15'],
    '2024_DAILY_70': auction_products_config_tr['2024_DAILY_70'],
    '2024_DAILY_71': auction_products_config_tr['2024_DAILY_71'],
    '2024_DAILY_72': auction_products_config_tr['2023_DAILY_18'],
    '2024_DAILY_73': auction_products_config_tr['2023_DAILY_3'],
    '2024_DAILY_74': auction_products_config_tr['2023_DAILY_28'],
    '2024_DAILY_75': auction_products_config_tr['2024_DAILY_75'],
    '2024_DAILY_76': auction_products_config_tr['2023_DAILY_13'],
    '2024_DAILY_77': auction_products_config_tr['2023_DAILY_26'],
    '2024_DAILY_78': auction_products_config_tr['2023_DAILY_29'],
    '2024_DAILY_79': auction_products_config_tr['2024_DAILY_79'],
    '2024_DAILY_80': auction_products_config_tr['2023_DAILY_42'],
    '2024_DAILY_81': auction_products_config_tr['2024_DAILY_81'],
    '2024_DAILY_82': auction_products_config_tr['2023_DAILY_12'],
    '2024_DAILY_83': auction_products_config_tr['2024_DAILY_83'],
    '2024_DAILY_84': auction_products_config_tr['2023_DAILY_27'],
    '2024_DAILY_85': auction_products_config_tr['2023_DAILY_33'],
    '2024_DAILY_86': auction_products_config_tr['2024_DAILY_86'],
    '2024_DAILY_87': auction_products_config_tr['2023_DAILY_25'],
    '2024_DAILY_88': auction_products_config_tr['2024_DAILY_43'],
    '2024_DAILY_89': auction_products_config_tr['2024_DAILY_89'],
    '2024_DAILY_90': auction_products_config_tr['2024_DAILY_90'],
    '2024_DAILY_91': auction_products_config_tr['2024_DAILY_91'],
    '2024_DAILY_92': auction_products_config_tr['2024_DAILY_92'],
    '2024_DAILY_93': auction_products_config_tr['2024_DAILY_93'],
    '2024_DAILY_94': auction_products_config_tr['2023_DAILY_2'],
    '2024_DAILY_95': auction_products_config_tr['2024_DAILY_95'],
    '2024_DAILY_96': auction_products_config_tr['2024_DAILY_96'],
    '2024_DAILY_97': auction_products_config_tr['2024_DAILY_97'],
    '2024_DAILY_98': auction_products_config_tr['2024_DAILY_98'],
    '2024_DAILY_99': auction_products_config_tr['2024_DAILY_99'],
    '2024_DAILY_100': auction_products_config_tr['2024_DAILY_100'],
    '2024_DAILY_101': auction_products_config_tr['2024_DAILY_71'],
    '2024_DAILY_102': auction_products_config_tr['2023_DAILY_24'],
    '2024_DAILY_103': auction_products_config_tr['2024_DAILY_103'],
    '2024_DAILY_104': auction_products_config_tr['2023_DAILY_3'],
    '2024_DAILY_105': auction_products_config_tr['2023_DAILY_26'],
    '2024_DAILY_106': auction_products_config_tr['2024_DAILY_106'],
    '2024_DAILY_107': auction_products_config_tr['2024_DAILY_107'],
    '2024_DAILY_108': auction_products_config_tr['2024_DAILY_108'],
    '2024_DAILY_109': auction_products_config_tr['2024_DAILY_109'],
    '2024_DAILY_110': auction_products_config_tr['2024_DAILY_110'],
    '2024_DAILY_111': auction_products_config_tr['2024_DAILY_111'],
    '2024_DAILY_112': auction_products_config_tr['2024_DAILY_112'],
    '2024_DAILY_113': auction_products_config_tr['2024_DAILY_113'],
    '2024_DAILY_114': auction_products_config_tr['2024_DAILY_68'],
    '2024_DAILY_115': auction_products_config_tr['2023_DAILY_12'],
    '2024_DAILY_116': auction_products_config_tr['2024_DAILY_81'],
    '2024_DAILY_117': auction_products_config_tr['2024_DAILY_117'],
    '2024_DAILY_118': auction_products_config_tr['2024_DAILY_79'],
    '2024_DAILY_119': auction_products_config_tr['2023_DAILY_26'],
    '2024_DAILY_120': auction_products_config_tr['2024_DAILY_120'],
    '2024_DAILY_121': auction_products_config_tr['2024_DAILY_121'],
    '2024_DAILY_122': auction_products_config_tr['2023_DAILY_18'],
    '2024_DAILY_123': auction_products_config_tr['2024_DAILY_123'],
    '2024_DAILY_124': auction_products_config_tr['2023_DAILY_29'],
    '2024_DAILY_125': auction_products_config_tr['2024_DAILY_125'],
    '2024_DAILY_126': auction_products_config_tr['2024_DAILY_83'],
    '2024_DAILY_127': auction_products_config_tr['2023_DAILY_13'],
    '2024_DAILY_128': auction_products_config_tr['2024_DAILY_128'],
    '2024_DAILY_129': auction_products_config_tr['2024_DAILY_129'],
    '2024_DAILY_130': auction_products_config_tr['2023_DAILY_3'],
    '2024_DAILY_131': auction_products_config_tr['2024_DAILY_131'],
    '2024_DAILY_132': auction_products_config_tr['2024_DAILY_132'],
    '2024_DAILY_133': auction_products_config_tr['2024_DAILY_86'],
    '2024_DAILY_134': auction_products_config_tr['2024_DAILY_134'],
    '2024_DAILY_135': auction_products_config_tr['2024_DAILY_135'],
    '2024_DAILY_136': auction_products_config_tr['2024_DAILY_109'],
    '2024_DAILY_137': auction_products_config_tr['2023_DAILY_29'],
    '2024_DAILY_138': auction_products_config_tr['2024_DAILY_138'],
    '2024_DAILY_139': auction_products_config_tr['2024_DAILY_139'],
    '2024_DAILY_140': auction_products_config_tr['2023_DAILY_15'],
    '2024_DAILY_141': auction_products_config_tr['2024_DAILY_110'],
    '2024_DAILY_142': auction_products_config_tr['2024_DAILY_142'],
    '2024_DAILY_143': auction_products_config_tr['2023_DAILY_32'],
    '2024_DAILY_144': auction_products_config_tr['2023_DAILY_40'],
    '2024_DAILY_145': auction_products_config_tr['2024_DAILY_93'],
    '2024_DAILY_146': auction_products_config_tr['2023_DAILY_2'],
    '2024_DAILY_147': auction_products_config_tr['2023_DAILY_26'],
    '2024_DAILY_148': auction_products_config_tr['2023_DAILY_33'],
    '2024_DAILY_149': auction_products_config_tr['2023_DAILY_7'],
    '2024_DAILY_150': auction_products_config_tr['2024_DAILY_83'],
    '2024_DAILY_151': auction_products_config_tr['2023_DAILY_24'],
    '2024_DAILY_152': auction_products_config_tr['2023_DAILY_12'],
    '2024_DAILY_153': auction_products_config_tr['2024_DAILY_79'],
    '2024_DAILY_154': auction_products_config_tr['2024_DAILY_109'],
    '2024_DAILY_155': auction_products_config_tr['2024_DAILY_68'],
    '2024_DAILY_156': auction_products_config_tr['2024_DAILY_70'],
    '2024_DAILY_157': auction_products_config_tr['2024_DAILY_86'],
    '2024_DAILY_158': auction_products_config_tr['2023_DAILY_13'],
    '2024_DAILY_159': auction_products_config_tr['2023_DAILY_15'],
    '2024_DAILY_160': auction_products_config_tr['2024_DAILY_139'],
    '2024_DAILY_161': auction_products_config_tr['2024_DAILY_110'],

    // ...test_auction_config_tr.AUCTION_PRODUCTS
  },
  AUCTION_CATALOGS: {
    '2023_DAILY': {
      NAME: 'Günlük Müzayede',
      TITLE: 'Her Gün Yeni Eser',
      TEXT: 'Günlük Müzayedeler belirlenen tarihler arasında belirlenen sanat eserlerini müzayede içerisinde sunan bir Artiox Müzayede sistemidir.',
      IMAGE_THUMBNAIL: './assets/auction_products/2023_DAILY_1/0.d.jpg',
    },
    '2024_DAILY': {
      NAME: 'Günlük Müzayede',
      TITLE: 'Her Gün Yeni Eser',
      TEXT: 'Günlük Müzayedeler belirlenen tarihler arasında belirlenen sanat eserlerini müzayede içerisinde sunan bir Artiox Müzayede sistemidir.',
      IMAGE_THUMBNAIL: './assets/auction_products/2023_DAILY_1/0.d.jpg',
    },

    // ...test_auction_config_tr.AUCTION_CATALOGS
  },
  AUCTION_ARTISTS: {
    ALI_ATMACA: {
      TITLE: 'Ali Atmaca (1947 - )',
      TEXT: '1947 yılında Elbistan, Kahramanmaraş\'ta doğan Ali Atmaca, ilkokul ve ortaokul eğitimini Kadirli\'de tamamlamış, lise öğrenimini ise Adana\'da sürdürmüştür. Sanat eğitimine olan ilgisi nedeniyle, 1971-72 yılları arasında İstanbul Güzel Sanatlar Fakültesi\'nde konuk öğrenci olarak atölye çalışmalarına katılmıştır. Daha sonra, 1982 yılında Paris Güzel Sanatlar Okulu\'nda eğitimine devam etmiş ve 1986-88 yılları arasında bir koleksiyonerin davetiyle Roma\'da resim çalışmalarına devam etmiştir.\n' +
        '\n' +
        'Ali Atmaca, eserlerinde renkli bir sanat anlayışını temel alır ve figürleri soyut biçim oluşumlarıyla denge içinde resmeder. Tuvalinde yuvarlak hatlar ve canlı renkler ağırlık taşır. Sanatçının eserleri sadece Türkiye\'de değil, aynı zamanda Fransa, İtalya, Japonya, Kanada, ABD gibi birçok ülkede özel koleksiyonlarda bulunmaktadır. Ayrıca, eserleri Fransa\'nın Nice Modern Sanatlar Müzesi ve Antibes Picasso Müzesi gibi prestijli kurumlarda da sergilenmektedir.'
    },
    MUSTAFA_AYAZ: {
      TITLE: 'Mustafa Ayaz (1938 - )',
      TEXT: 'Doğum yeri Trabzon olan Prof. Dr. Mustafa Ayaz, ressam, akademisyen ve güzel sanatlar profesörü olarak tanınmaktadır. Eğitim hayatına Gazi Eğitim Enstitüsü Resim-İş Bölümünde başlayan sanatçı, bu bölümü 1963 yılında başarıyla tamamlamıştır. 1987 yılında profesör unvanını elde eden Mustafa Ayaz, aynı yıl Bilkent Üniversitesi Güzel Sanatlar Fakültesi\'ne atanmıştır. Ancak 1988 yılında bu görevinden ayrılan Ayaz, Ankara\'da yaşamaya devam etmekte ve çalışmalarını sürdürmektedir.' +
        '\n' +
        'Sanatçı, birçok kişisel sergi düzenlemenin yanı sıra pek çok ülkede karma sergilere ve bienallere katılmıştır. Sanatçı, resim yaparken "çizerek boyayarak düşünmek, çizerek boyayarak sevmek" hissini yaşadığını ifade eder. Günlük yaşamdan ilham alır ve bu olayları, coşkuları ve düşünceleri resimleriyle kalıcı kılmayı ve tekrar yaşamayı amaçlar. Mustafa Ayaz, sanat kariyeri boyunca birçok ödül kazanmıştır.'
    },
    ADEM_GENC: {
      TITLE: 'Adem Genç (1944 - )',
      TEXT: 'Adem Genç, 1944 yılında Rize\'nin Ardeşen ilçesinde doğmuştur. Gazi Eğitim Enstitüsü\'nde Resim-İş Bölümü programını başarıyla bitirdikten sonra meslek hayatına resim öğretmeni olarak Adana ve Rize\'de başlamıştır. 1969 yılında devlet bursu kazanarak İngiltere\'ye gönderilmiştir.\n' +
        '\n' +
        'İngiltere\'de, Bournemouth College of Art\'te desen ve gravür atölyesinde eğitim almıştır. Daha sonra, 1971 yılında Londra\'da bulunan St. Martin\'s School of Art\'ın "Postgraduate/Advanced Painting" programına kabul edilmiştir. Ayrıca, 1991-92 öğretim yılında Fulbright öğretim üyesi olarak Kaliforniya Üniversitesi\'nde post-doktora  çalışmalarını tamamlamıştır. Sanatçı yurt içinde ve yurt dışında birçok kişisel sergi açmış, uluslararası sanat etkinliklerine katılmıştır. '
    },
    ALEV_OZAS: {
      TITLE: 'Alev Özas (1977 - )',
      TEXT: 'Ressam Alev Özas, 1977 yılında İstanbul\'da doğmuştur. Lise öğreniminden sonra Özel İstanbul Güzel Sanatlar Akademisi\'nin Tiyatro bölümüne katılmıştır. Tiyatro eğitiminin yanı sıra bu dönemde karakalem eskizleri de yapmıştır. 1998 yılında resim sanatına geçiş yapmış, Marmara Üniversitesi Resim bölümüne başvurarak kabul edilmiştir.\n' +
        '\n' +
        '2005 yılı itibariyle, üslup olarak soyut ekspresif figüratif tarzı benimsemeye başlamıştır. Aynı yıl, Prof. Dr. Devrim ERBİL\'in özel atölyesinde başasistan olarak çalışmaya başlamış ve bu görevi 8 yıl boyunca sürdürmüştür. Sanatçı deneyimlediği ekspresif anlayışı İstanbul\'un büyük ve karmaşık yapısını resmetmekte kullanmıştır. Alev Özas\'ın resimlerinde renk, belirgin bir şekilde öne çıkar.\n'
    },
    FIKRET_MUALLA: {
      TITLE: 'Fikret Mualla (1903 - 1967)',
      TEXT: 'Ressam Fikret Mualla, 1903 yılında İstanbul\'da doğdu. Eğitim hayatına Saint Joseph Fransız Okulu\'nda başladı ve ardından bir süre Galatasaray Lisesi\'nde okudu. Mühendislik eğitimi için Almanya\'ya gönderildi ve bu dönemde Avrupa\'da dolaşarak müzeleri gezdi. Resim yeteneği bu dönemde ortaya çıktı ve kısa sürede güçlü bir desen bilgisi kazandı. Moda çizimleri, gravürler ve başarılı resimlemeler yaptı, desenleri Alman dergilerinde yayınlandı.\n' +
        '\n' +
        'Paris\'te uzun yıllar yaşayan Fikret Mualla, özellikle Paris yaşamının ayrıntılarına odaklanarak kahvehane, sirk ve sokak gibi konuları resmetti. İçinde bulunduğu bohem çevresinin insanlarını eserlerine konu aldı. Guaj tekniğiyle hızlı çalışabilme yeteneği ile tanınırken, yağlı boya, suluboya ve guaj ustalıkla kullanmıştır. Çağdaş sanat akımlarına katılmamış ve resmin kuramsal sorunları üzerine çalışmamıştır. Bugün eserleri özel koleksiyonlarda bulunmaktadır.\n'
    },
    AHMET_ORAN: {
      TITLE: 'Ahmet Oran (1957 - )',
      TEXT: 'Çanakkale doğumlu soyut ressam Ahmet Oran, 1977\'de İstanbul Devlet Güzel Sanatlar Akademisi\'ne girdi ve 1985\'te mezun oldu. Aynı dönemde Viyana Tatbiki Güzel Sanatlar Yüksek Okulu\'nda eğitim aldı. Oran, Avusturya\'da yayınlanan "Avusturya\'da Sanat" adlı kitapta da bir resmiyle yer almıştır ve 1999\'da Linz Modern Sanatlar Müzesi\'nde büyük bir kişisel sergi düzenlemiştir. Sanatçı, İstanbul ve Viyana\'da çalışmalarını sürdürmektedir. \n' +
        '\n' +
        'Oran\'ın eserlerinde mekansal derinlik ve renklerin katmanları ön plana çıkar. Genellikle büyük tuvaller kullanarak soyut resimlerini geometrik veya lirik çizgilerle oluşturur. İstanbul\'un tarih, hareket ve çağdaş yaşamından ilham alan Oran, renk ve grafik çizgilerle özgün eserler yaratır. 1980\'den beri uluslararası sergilere katılan Oran, Türkiye\'nin önde gelen ressamlarından biridir.\n'
    },
    ILYAS_ERGIN_INAN: {
      TITLE: 'İlyas Ergin İnan (1943 - )',
      TEXT: 'Çağdaş Türk sanatının tanınmış isimlerinden Ergin İnan, soyut ve kavramsal eserleriyle uluslararası alanda öne çıkmış bir ressamdır. Malatya doğumlu İnan, İstanbul Devlet Tatbiki Güzel Sanatlar Yüksek Okulu\'ndan mezun olmuş ve sanat kariyerine yoğun bir şekilde devam etmiştir. \n' +
        'Tasavvuf felsefesini eserlerine yansıtan İnan, manevi ve ruhsal unsurları resimlerinde derinlemesine işlemiştir. Geleneksel Türk İslam sanatından izler taşıyan eserlerinde, eski kitap sayfalarını kullanarak özgün bir yaklaşım sergilemiştir. Akademik bir geçmişi olan sanatçı, Berlin\'de uzun süre araştırma görevlisi olarak çalışmış ve öğretim üyeliği yapmıştır. İnan, pek çok ulusal ve uluslararası ödül kazanmıştır. Ergin İnan, 2000 yılında emekli olduğundan beri İstanbul\'daki atölyesinde sanatsal çalışmalarına devam etmektedir.\n'
    },
    BURHAN_DOGANCAY: {
      TITLE: 'Burhan Doğançay (1929 - 2013)',
      TEXT: 'Türk ressam Burhan Doğançay, 1929 yılında İstanbul\'da doğdu. Sanat eğitimine babasının ve ressam Arif Kaptan\'ın rehberliğinde başladı. Hukuk ve ekonomi eğitimini tamamladıktan sonra Paris\'te resim çalışmalarına devam etti. New York\'a giderek Guggenheim Müzesi koleksiyonuna kabul edilen ilk Türk ressamı oldu.  Duvarlar teması üzerine çalıştığı "Duvarlar" serisi ile tanındı. \n' +
        '\n' +
        'Doğançay\'ın eserlerinde yaşamdan ilham aldığı ve bu etkiyi güçlendirmek için kolaj ve fümaj tekniklerini bir arada kullandığı görülür. Ayrıca eserlerinde kağıtlar, afişler, ipler, eski kırık tabelalar, kapı tokmakları, teller, kilitler ve boyanmış tahtalar gibi çeşitli malzemeler kullanarak sanatsal mesajlarını nesneler aracılığıyla ifade etti. Bu malzemeler, Doğançay\'ın çalışmalarında yaşamın çeşitli yönlerini ve dokusunu yansıtmada önemli bir rol oynadı. Sanatçı, 2013\'te İstanbul\'da vefat etti ve Bodrum\'da defnedildi.\n'
    },
    HUSEYIN_FEYZULLAH: {
      TITLE: 'Hüseyin Feyzullah (1990 - )',
      TEXT: 'Hüseyin Feyzullah, 1990 yılında Taşkent\'te doğdu. Sanat eğitimine 2009 yılında Hacettepe Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümü\'nde başladı. 2013 yılında aynı üniversiteden mezun oldu. Daha sonra 2017 yılında Gazi Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümü\'nde yüksek lisans eğitimini tamamladı. Hâlen resim çalışmalarına Firdevsi Feyzullah Atölyesi\'nde devam etmektedir. Hüseyin Feyzullah, dört kişisel sergi açmış ve yaklaşık 50 karma projede yer almıştır.\n'
    },
    JAVAD_GHAFFARI: {
      TITLE: 'Javad Ghaffari (-)',
      TEXT: 'İranlı ressam Javad Ghaffari, 2020 yılından hemen önce Türkiye’ye gelerek  yerleşti. Şırınga tekniği olarak bilinen "şorre" tekniğiyle resimler yapmaktadır. Farsça "şorre" olarak bilinen bu tekniği kendi geliştirmiştir. Resimlerinde çizgisel ve çarpıcı renkler kullanarak Neo Empresyonist etkileri yansıtmaktadır. Türkiye’deki çalışmalarını farklı bir yere taşımaya çalışan Ghaffari, bu nedenle Van’dan Antalya’ya taşınarak çalışmalarını orada sürdürmeye karar vermiştir.'
    },
    MAHIR_GUVEN: {
      TITLE: 'Mahir Güven (1958-)',
      TEXT: '1958 yılında İstanbul\'da doğan Mahir Güven, 1976 ile 1981 yılları arasında İstanbul Devlet Güzel Sanatlar Akademisi, Resim Bölümünde eğitim aldı. Rönesans figürlerinin de bulunduğu eserlerinde, geleneksel Türk kültüründen batı kültürüne geçiş döneminin derinlemesine sorgulamasının izlenebildiği resimlerinde eleştirel bir yaklaşım benimsemekte ve toplumsal mesajlara öncelik vermektedir. Sanatçı şu anda yaşamını ve sanatsal çalışmalarını İstanbul\'da sürdürmektedir.\n'
    },
    TANJU_DEMIRCI: {
      TITLE: 'Tanju Demirci (1961 - )',
      TEXT: 'Tanju Demirci, 1961\'de Edirne\'nin Keşan ilçesinde doğdu. Marmara Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümü\'nden mezun olduktan sonra birçok ulusal ve uluslararası yarışmada ödüller kazandı. 1984’yılında Roma, Floransa, Assisi ve Padova şehirlerinde, 1988 yılında ise Almanya’nın Köln şehrinde araştırmalar yaptı. 1988’de Esbank Yunus Emre 5. Resim Yarışmasında, 1990’da 11.Günümüz Sanatçıları İstanbul Sergisinde başarı ödülleri aldı. 1991’de 52. Devlet Resim ve Heykel Sergisi Resim Yarışmasında mansiyon ödülü aldı. Türk geleneksel kültürünün ve batı etkilerinin birleşimini resimlerine yansıtan Tanju Demirci, 8 Ağustos 2016 tarihinde vefat etti.\n'
    },
    MUSLUM_TEKE: {
      TITLE: 'Müslüm Teke (1974 - )',
      TEXT: '1974 Gaziantep doğumludur. Yüzüncü Yıl Üniversitesi Resim Öğretmenliği Bölümü\'nden mezun olan Müslüm Teke, 2003 yılından günümüze kadar yurt içi ve yurt dışı pek çok sergiye, çalıştaylara ve bienallere katıldı ve ödüller aldı. Sanatçı Müslüm Teke, portrelerinde yoğun renkler ve doku kullanarak kendine özgü bir ifade tekniği geliştirir. Özellikle göz detaylarına verdiği özel vurgu, eserlerinin duygusal derinliğini artırır.\n'
    },
    ADNAN_TURANI: {
      TITLE: 'Adnan Turani (1925 - 2016) ',
      TEXT: 'Adnan Turani, soyut resmin önde gelen temsilcilerinden biridir. 1925\'te İstanbul\'da doğan Turani, resim eğitimini Almanya\'da aldıktan sonra Türkiye\'ye dönmüş ve birçok üniversitede öğretim görevlisi olarak çalışmıştır. Sanat tarihi, Türk resim sanatı ve sanat felsefesi alanlarında önemli çalışmalar yapmıştır. Turani, soyut bir resim anlayışına sahiptir ve daha sonraki dönemlerde figüratif öğelere ve doğaya yer vermesine rağmen soyut bir yaklaşımı sürdürmüştür. 2016\'da vefat etmiştir.\n'
    },
    SAHIN_PAKSOY: {
      TITLE: 'Şahin Paksoy (1952 - )',
      TEXT: 'Şahin Paksoy, 1952 yılında Adana\'nın Ceyhan ilçesinde doğdu. İlk, orta ve lise eğitimini Adana\'da tamamladı. 1973 yılında İstanbul Devlet Güzel Sanatlar Akademisi\'nde seramik eğitimine başladı ve mezun olduktan sonra 1980 yılında ilk kişisel sergisini İstanbul\'da açtı. Sanatında geleneksel Türk sanatlarından ilham alan Paksoy, figüratif resimleri ve küçük boyutlu seramik heykelleriyle çağdaş sanatta özgün bir üslup geliştirdi. Minyatürler, kilise ikonları ve halı desenleri gibi unsurlar, eserlerine ilham kaynağı oldu. Sanatçı, gündelik yaşamdan ilham alarak insan figürlerini konu aldı ve kendi özgün dilini oluşturarak eserlerinde Anadolu\'nun yaşam tarzını ve değerlerini yansıttı. Paksoy\'un sanat kariyeri boyunca birçok sergiye katılımı oldu ve son yıllarda koleksiyonculuğa yöneldi.'
    },
    KENAN_ISIK: {
      TITLE: 'Kenan Işık (1981 - )',
      TEXT: 'Kenan Işık, 1981 yılında Van ilinde doğdu ve Erzurum Atatürk Üniversitesi Güzel Sanatlar Fakültesi\'nde resim bölümünde lisans eğitimi aldı. Daha sonra İstanbul\'a yerleşten Işık, şehrin dinamizmini ve değişkenliğini eserlerine yansıtarak tanındı. Eserlerinde şehrin güzelliklerini ve karmaşıklığını bir araya getiren sanatçı ilk kişisel sergisini 2007 yılında açtı ve hem yurt içinde hem de yurt dışında bir çok sergi düzenledi.\n'
    },
    SELIM_TURAN: {
      TITLE: 'Selim Turan (1915 - 1994)',
      TEXT: '1915 yılında doğan Selim Turan, İstanbul Güzel Sanatlar Akademisi\'nde resim eğitimi aldı ve öğrenim hayatında Nazmi Ziya Güran, Feyhaman Duran, Leopold Levy ve Zeki Kocamemi gibi önemli sanatçılardan ders aldı. 1938\'de Akademi\'den mezun olduktan sonra resim öğretmenliği yaparak ve minyatür çalışmalarına odaklanarak kariyerine devam etti. 1947\'de Fransız Hükümeti\'nin bursu ile Paris\'e gitti ve burada önemli çalışmalar gerçekleştirdi. Soyut sanata yöneldi ve Doğu sanatlarından ilham alarak özgün eserler üretti. Mobilleri ve hareketli heykelleri ile tanınan sanatçı, 1993\'te Ankara Kurtuluş Parkı\'na büyük bir mobil heykel yerleştirdi. Yolu Nuri İyem, Abidin Dino, Turgut Atalay, Ferruh, Başağa, Agop Arad, Avni Arbaş, Mümtaz Yener, Fethi Karakaş ve Haşmet Akal ile kesişen usta sanatçı, "Toplumsal Gerçekçi" anlayış temelindeki Yeniler Grubu’nun kurucuları arasında yer aldı. Fransa\'da birçok eseri bulunan Turan, 1994\'te Paris\'te vefat etti ve cenazesi Türkiye\'ye getirilerek İstanbul\'da defnedildi.'
    },
    MUMIN_CANDAS: {
      TITLE: 'Mümin Candaş (1975 - )',
      TEXT: 'Mümin Candaş, 1971\'de Trabzon\'da doğdu ve Karadeniz Teknik Üniversitesi\'nde resim öğretmenliği eğitimi aldı. İstanbul Teknik Üniversitesi\'nde yüksek lisans yaptıktan sonra Akçaabat Güzel Sanatlar Lisesi\'nde görsel sanatlar öğretmeni olarak çalıştı. Eserlerinde insanın içsel ve fiziksel derinliklerini ve insan ile doğa arasındaki ilişkiyi konu alan Candaş, on kişisel sergi açtı ve ulusal ve uluslararası sergilere katıldı. Sanatını SANKO Sanat Galerisi\'nde de sergileyen sanatçı, eserlerinde yaşam ve insan-çevre ilişkilerine odaklanmıştır.'
    },
    CAN_GENCA: {
      TITLE: 'Can Genca (1985 - )',
      TEXT: '1985 yılında İstanbul\'da doğan Can Genca, küçük yaşlardan itibaren resim ve tasarım alanına ilgi duymaya başladı. Kolej yıllarını sanat dolu bir atmosferde geçirdikten sonra, eğitimine İstanbul Üniversitesi Yabancı Diller Fakültesi ve Marmara Üniversitesi Güzel Sanatlar Fakültesi\'nde devam etti. Sanat tarihi üzerine yaptığı araştırmalar sırasında, London Camberwell College of Arts\'ta sanat eğitimine devam etti. Sanatçı özellikle konstrüktivizm, kübizm ve minimalizm akımlarından ilham almakta ve bu alanlarda birçok eser ortaya koymaktadır. Tablolarında renkleri ayıran kontur hatları kullanarak, renk kombinasyonları ve kontrast değerleri üzerinde titizlikle çalışmaktadır. Sanatçı, İstanbul\'da çalışmalarını sürdürmekte ve sanatına olan tutkusunu her geçen gün daha da derinleştirmektedir.\n' +
        'Born in Istanbul in 1985, the artist developed an interest in painting and design from a young age. After spending college years immersed in the world of art, the artist pursued education at Istanbul University Faculty of Foreign Languages and Marmara University Faculty of Fine Arts. During the period of researching art history, the artist continued art education at London Camberwell College of Arts. Drawing inspiration particularly from constructivism, cubism, and minimalism, the artist has created numerous works in these movements. Using contour lines that separate colors in their paintings, the artist meticulously captures color combinations and contrast values.'
    },
    GUVEN_ARAZ: {
      TITLE: 'Güven Araz (1964 - )',
      TEXT: '1964 yılında İstanbul\'da doğan Güven Araz, İstanbul Teknik Üniversitesi\'nde 3 yıl Fizik Mühendisliği okuduktan sonra Marmara Üniversitesi Güzel Sanatlar Fakültesi Heykel bölümüne giriş yaptı. 1989 yılında mezun olduktan sonra Heykel ve Desen dersleri vermeye başladı. 2012 yılından itibaren 6 yıl boyunca özel bir güzel sanatlar lisesinde resim, heykel ve akrilik boya dersleri verdi ve hâlâ özel bir Anadolu kolejinde resim dersleri vermektedir. Yıllar içinde birçok karma sergiye katılan sanatçı, şu anda tuval üzerine akrilik resimler üretmektedir.\n' +
        'Güven Araz was born in Istanbul in 1964. After studying Physics Engineering at Istanbul Technical University for 3 years, he entered the Sculpture department of Marmara University Faculty of Fine Arts. Upon graduating in 1989, he began teaching Sculpture and Drawing courses. Starting from 2012, he taught painting, sculpture, and acrylic painting at a private fine arts high school for 6 years and currently teaches painting at a private Anatolian high school. Over the years, he has participated in numerous group exhibitions and is currently creating acrylic paintings on canvas.'
    },
    ERTUGRUL_ATES: {
      TITLE: 'Ertuğrul Ateş (1954 - )',
      TEXT: 'Sanatçı Ertuğrul Ateş, 1954 yılında Adana\'da doğdu ve 1976\'da Gazi Eğitim Enstitüsü Resim Bölümü\'nden mezun oldu. İngiltere\'de eğitim aldıktan sonra 1980 yılında İstanbul\'da ilk sergisini açtı. 1987\'de Amerika\'ya yerleşerek çalışmalarını 2006\'ya kadar sürdürdü. Ünlü Terry Dintanfass galerisi ve Chicago Modern Sanatlar Müzesi tarafından "Yükselen Sanatçılar" sergisine dahil edildi. 2004\'te Hürrem Sultan dans gösterisini yazıp yönetti ve 2006 Pekin Olimpiyatları Bienali\'ne davet edildi. Yurt içinde ve yurt dışında birçok sergi ve etkinlik düzenleyen sanatçı, eserleriyle New York, Boston, Los Angeles, Dallas, Chicago, Pekin, Shanghai, Taipei, İstanbul, Ankara, İzmir, Adana gibi şehirlerde sanatseverlerle buluştu. "Kurdelalı Ressam" unvanını taşıyan Ertuğrul Ateş büyük sorulara cevap aramanın sanatın başlangıç noktası olduğu inancıyla, gerçekçilikten ziyade hayalî ve evrensel bir dünyanın yaratılmasını savunmaktadır.'
    },
    AHMET_GUNESTEKIN: {
      TITLE: 'Ahmet Güneştekin (1966 - )',
      TEXT: '1966 yılında Batman\'da doğan Ahmet Güneştekin, 1981 yılında Mimar Sinan Güzel Sanatlar Üniversitesi\'ne kabul edildi; ancak akademik eğitimi reddederek okulu terk etti. 1997 yılında, İstanbul\'un Beyoğlu ilçesinde ilk atölyesini kurdu. Aynı dönemde belgeselci ve gazeteci Coşkun Aral ile işbirliği yaparak Anadolu coğrafyasını keşfe çıktı.\n' +
        '\n' +
        'Güneştekin, "Güneş\'in İzinde" adlı kendi belgesel serisini başlattı. Bu seride, Anadolu\'nun çeşitli şehirlerini ziyaret ederek önemli Türk çağdaş sanatçılarla ikili sergiler düzenledi ve binlerce çocukla atölye resim çalışmaları gerçekleştirdi. Ayrıca, New York, Miami, Budapeşte, Madrid, Barselona, Monako, Hong Kong ve Venedik gibi birçok şehirde kişisel sergiler açtı ve uluslararası çağdaş sanat fuarlarına katıldı.\n'
    },
    MEMDUH_KUZAY: {
      TITLE: 'Memduh Kuzay (1957 - )',
      TEXT: '1957 yılında Kayseri\'de doğan Memduh Kuzay, Mimar Sinan Üniversitesi\'nden mezun olduktan sonra Amerika\'ya giderek Galeri Matisse\'de ressam ve sanat danışmanı olarak görev aldı. Chicago\'da Glimmer Sanat Galerisi ile çalıştıktan sonra, 1994 yılında Mersin Üniversitesi Güzel Sanatlar Fakültesinde öğretim üyesi oldu ve bölüm başkanlığı ile dekan yardımcılığı görevlerini üstlendi. 2000 yılında üniversiteden ayrıldı. Sanatçının eserleri, büyük boyutlu kompozisyonlarda kumaş ve kağıt kolajlarının renkli bütünleşmesini ve figüratif desenlerini içermektedir. 2001\'de Chicago\'da düzenlenen Outdoor Show\'da En İyi Sanatçı ödülünü kazandı.'
    },
    BEHZAT_HASAN_FEYZULLAH: {
      TITLE: 'Behzat Hasan Feyzullah (1990 - )',
      TEXT: '1990 yılında Taşkent\'te doğan Behzat Feyzullah, 2009 yılında Hacettepe Üniversitesi Güzel Sanatlar Fakültesi Grafik Bölümüne girdi ve 2013 yılında aynı bölümden mezun oldu. Sanatçının kişisel sergileri arasında 2019\'da TürkerArt Sanat Galerisi\'nde İstanbul\'da, 2018\'de Galeri Soyut\'un B Salonu\'nda Ankara\'da düzenlenen \'Kaybın Rengi\' sergisi ve 2011\'de Yağmur Sanat Galerisi\'nde düzenlenen sergi bulunmaktadır. Sanatçı, Kübizm ve Sürrealizm akımlarından etkilenmektedir. "Truva\'nın Dirilişi" temalı eserlerine devam etmektedir ve bu eserler, mitolojik betimlemelerin etkisi altında, sürreal bir tarzda şekillenmektedir.'
    },
    IBRAHIM_TAYFUR: {
      TITLE: 'İbrahim Tayfur (1971 - )',
      TEXT: 'İbrahim Tayfur, 1971 yılında Adana\'da doğmuştur. Hacettepe Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümünden mezun olan sanatçı, 2009 yılında Artıköy Sanat Evi\'nde ilk kişisel sergisini düzenlemiştir. Bugüne kadar İstanbul\'daki çeşitli sanat galerilerinde toplamda sekiz kişisel sergi gerçekleştirmiştir. Ayrıca, yaklaşık elli karma sergiye de katılmıştır. Sanatçının eserleri, hem yurt içinde hem de yurt dışında özel koleksiyonlarda yer almaktadır. Ressam, klasik ve soyut çalışmalar yapmakta olup genellikle eserlerinde İstanbul\'u konu almaktadır. Eserlerindeki asimetrik, bütünsel olmayan çizgisel dağılımlar ve ritmik düzenlemeler dikkat çekmektedir.'
    },
    ALAADDIN_HIZ: {
      TITLE: 'Alaaddin Hız (1989 - )',
      TEXT: 'Alaaddin Hız, 1989\'da Akşehir\'de doğmuş ve 2018\'de Akdeniz Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümünden üçüncülük derecesi ile mezun olmuştur. Halen aynı üniversitenin Güzel Sanatlar Enstitüsünde yüksek lisans eğitimine devam etmektedir. Sanat kariyeri boyunca ulusal ve uluslararası sergilerde yer almış, ödüller kazanmış ve sergi düzenleme kurulu üyeliği yapmıştır. Ayrıca, çeşitli araştırma konularında çalışmalarını sürdürmektedir. Hız\'ın eserleri birçok kurum ve müzede sergilenmektedir. Akdeniz Üniversitesi\'nde öğrenci temsilcisi olarak görev yapmakta ve sanatsal çalışmalarını Antalya\'daki kişisel atölyesinde sürdürmektedir. Hız\'ın üretimleri peyzaj resimleri, tipografi çalışmaları ve soyut katmanlı çalışmalardan oluşmaktadır.\n',
    },
    FARUK_CIMOK: {
      TITLE: 'Faruk Cimok (1956 - )',
      TEXT: 'Faruk Cimok, 1956 yılında Hatay\'ın Reyhanlı ilçesinde doğdu. Mimar Sinan Güzel Sanatlar Üniversitesi\'nde Devrim Erbil’in atölyesinde eğitim aldığı Devlet Güzel Sanatlar Akademisi Resim Bölümünden 1979\'da mezun oldu. İlk kişisel sergisini 1980 yılında açtı. İlk dönemlerinde Çukurova’daki pamuk işçilerini figüratif olarak resmetti, daha sonra İstanbul\'un eski manzaralarını canlı renklerle işlemeye başladı. Eserlerinde Beyoğlu, Nişantaşı, Kumkapı gibi semtlerdeki 1950’li yılların sosyal hayatını nostaljik bir üslupla yansıttı. "Kuşçu" ressam lakabını güvercin figürünü sıkça kullanmasından aldı. Sanat hayatında 35 kişisel sergi düzenledi, çeşitli ödüller kazandı. Halen Mimar Sinan Güzel Sanatlar Üniversitesi\'nde görev yapmakta ve Suadiye’deki kişisel atölyesinde sanat çalışmalarına devam etmektedir. Ayrıca tablo restorasyonunda da uzmanlaşmıştır.',
    },
    FEHIM_GULER: {
      TITLE: 'Fehim Güler (1964 - )',
      TEXT: '1964 yılında doğan Fehim Güler, 1987 yılında Marmara Üniversitesi Atatürk Eğitim Fakültesi Güzel Sanatlar Bölümü Resim Ana Sanat Dalı\'ndan mezun oldu. 1991 yılında Hacettepe Üniversitesi Güzel Sanatlar Fakültesi\'nde Grafik Tasarım alanında "Yaratıcılık ve Fonksiyonları" adlı bir çalışma gerçekleştirdi. 1994-1998 yılları arasında İstanbul Teknik Üniversitesi Sosyal Bilimler Enstitüsü\'nde Görsel ve Evresel Ana Sanat Dalı\'nda tez çalışması yaptı. 2009 yılında Beyoğlu Plastik Sanatlar Derneği\'ni kurarak iki dönem kurucu başkanlık görevini üstlendi. FMV Işık Üniversitesi Güzel Sanatlar Fakültesi\'nde 2013-2015 yılları arasında Sanat Kuramı ve Eleştiri Yüksek Lisans Programı\'nda "Ekspresyonizm\'in Türk Resmi\'ne Etkisi" konulu ikinci yüksek lisans çalışmasını tamamladı (Çalışma 1915-2015 yıllarını kapsamaktadır). 2023 yılında Ziba Art Gallery\'yi kurarak sanat danışmanlığını üstlendi. Halen Uluslararası Plastik Sanatlar Derneği (UPSD) Yönetim Kurulu Üyesi ve Genel Sekreteri olarak görev yapmaktadır.',
    },
    ZAFER_DILEKCI: {
      TITLE: "Zafer Dilekçi (1978 - )",
      TEXT: 'Zafer Dilekçi, 1978\'de Ankara\'da doğdu ve Erciyes Üniversitesi Resim Bölümü ile Kocaeli Üniversitesi\'nde eğitim aldı. Kocaeli ve Ankara\'da görsel sanatlar öğretmeni olarak çalıştı ve 2008\'den beri Eskişehir\'de aynı görevi sürdürüyor. 2002\'de Erciyes Üniversitesi resim yarışmasında "Tavla" adlı eseriyle mansiyon aldı ve eserleri birçok şehirdeki özel koleksiyonlarda bulunuyor. 2000\'den beri karikatüre de ilgi duyan sanatçı, Gırgır ve Leman gibi dergilerde karikatürleri yayımlandı ve Nehar Tüblek Karikatür yarışmasında ödül kazandı. Çin, İtalya, Azerbaycan ve Türkiye\'deki 20\'den fazla karikatür kataloğuna katıldı. 1998\'den beri mozaik resimler üretiyor.',
    },
    BIGE_AKSOY: {
      TITLE: "Bige Aksoy (1976 - )",
      TEXT: '1976 İstanbul doğumlu ve Bodrum\'da yaşayan Bige Aksoy, eserlerinde sanatı ve tasarımı ustalıkla birleştiriyor. Çalışmalarında kağıdı sadece bir malzeme olarak değil, resimlerinde anlamı derinleştiren ve tasarımlarında kendine has bir teknikle bütünlüğü sağlayan ana unsur olarak kullanıyor. Sanatçı, eserlerinde yaratıcının bilinen deneyimleri ve duyguları şekillenmeden hemen önceki varoluş hislerini, imgeleri ve soyut düşünceleri seyirciyle buluşturmayı hedefliyor.',
    },
    SERAP_CAN: {
      TITLE: "Serap Can (1981 - )",
      TEXT: "2007'de Sakarya Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümünden mezun olan Serap Can, 2010'da Anadolu Üniversitesi Eğitim Fakültesinde Pedagojik Formasyon eğitimi aldı. 2015'te Yılın Genç Sanatçısı Ödülü'ne layık görüldü ve hem yurt içinde hem de yurt dışında birçok sergiye katıldı. Şu anda İstanbul'da yaşamını ve sanat çalışmalarını sürdürüyor ve Ulus Özel Musevi Lisesi'nde Görsel Sanatlar öğretmenliği yapıyor."
    },
    MUHITTIN_ARIKAN: {
      TITLE: 'Muhittin Arıkan (1976 - )',
      TEXT: 'Muhittin Arıkan, Mersin\'de ilk ve orta öğrenimini tamamladı. İlkokulu İleri İlköğretim Okulu\'nda, ortaokulu ise Mersin Belediye Lisesi\'nde bitirdi. 2008 ile 2023 yılları arasında Kayseri\'de özel bir kurumda görsel sanatlar öğretmeni olarak çalıştı. Şu anda, Çağrı Sanat Evi\'nde sanatsal faaliyetlerini sürdürmektedir.'
    },
    ZLATA_SHYSHMAN: {
      TITLE: 'Zlata Shyshman (1995 -)',
      TEXT: "Zlata Shyshman, 23 Ekim 1995’te Ukrayna'nın İshmael şehrinde, Ukrayna'nın tanınmış sanatçısı Ivan Shyshman'ın ailesinde dünyaya gelmiştir. Empresyonist sanatçı eserlerinde genellikle yağlı boya tekniğiyle çalışmaktadır. Shyshman’ın eserleri dünya çapında çeşitli koleksiyonlarda yer almaktadır ve birçok ödüle layık görülmüştür. Ukrayna Sanatçılar Ulusal Birliği'nin bir üyesidir ve şimdilerde ise çalışmalarına paralel olarak K.K. Kostandi Sanat Okulu'nda resim, resim ve kompozisyon dersleri veriyor."
    },
    REMZI_KARABULUT: {
      TITLE: 'Remzi Karabulut (1963 -)',
      TEXT: "1963 yılında Sarıkamış’ta dünyaya gelen sanatçı aynı zamanda bir yazardır. Bir dönem soyut resimle ilgilendi ve etkisinde kaldığı metinlerin resimlerini yaptı. Yaptığı resimlerle ikisi kişisel olmak üzere, üç sergi açtı. Yazıları ise Varlık, Çağdaş Türk Dili, Can Öykü Gazetesi gibi birçok önemli yayında yayınlanmıştır. Remzi Karabulut, 1975 yılından bu yana Tarsus’ta yaşıyor."
    },
    ELIF_SULE_SANLI: {
      TITLE: "Elif Şule Şanlı (2000 - )",
      TEXT: "2000 yılında Ankara’da ressam bir annenin kızı olarak dünyaya gelen genç sanatçı, 2023 yılında ODTÜ psikoloji bölümünden mezun olmuştur. İlk olarak annesinden, sonrasında çeşitli resim atölyelerinden aldığı sanat eğitimlerini psikolojik bilgiyle harmanlayarak ürettiği eserleriyle çeşitli sergi ve fuarlarda yer almıştır. Lisans hayatı boyunca “sanat terapisi” alanında aktif olarak faaliyet göstermiş ve özel bir psikiyatri hastanesinde danışanlarla resim analizi çalışmaları ve sanat terapi atölyeleri yürütmüştür. Şu anda disiplinlerarası sanatçı olarak üretim yapmaya ve sanat danışmanı olarak çalışmalarına devam etmektedir."
    },
    HULYA_ELIBOL:{
      TITLE: "Hülya Elibol (1994 - )",
      TEXT: "1993 Van doğumlu Hülya Elibol, ilk ve orta öğrenimini Konya'da tamamlayarak, 2012 yılında Zübeyde Hanım Kız Teknik Meslek ve Anadolu Lisesi'nden grafik ve fotoğrafçılık dalında mezun oldu. Eğitimine Selçuk Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümünde devam eden Elibol, 2018'de buradan başarıyla mezun oldu. Sanat eğitimini tamamladıktan sonra, Necmettin Erbakan Üniversitesi Ahmet Keleşoğlu Eğitim Fakültesinde pedagojik formasyon eğitimi aldı. Sanatsal yeteneklerini 12 karma sergide sergileyen Elibol, sanat ve eğitim alanında kendini sürekli geliştiren, başarılı bir sanatçı ve eğitimci."
    },
    ENSIEH_NAJARIAN:{
      TITLE: "Ensieh Najarian (1982 - )",
      TEXT: "Ensieh Najarian, 1982 yılında Hamadan'da doğdu. Çocukluğundan beri sanatsal çalışmaları öğrenme ve yaratma ile çok ilgiliydi ve eğitim alırken, Hamadan'ın sanatsal yarışmalarında birçok sertifika aldı. 2001 yılında üniversiteye kabul edildi Ve Lisans Derecesi Matematik dalında Tahran Üniversitesi'nden mezun olduktan sonra, minyatür çizimi (geleneksel İran çizimi) öğrenmeye çalıştı. Geleneksel ve modern sanatı birleştirmek için yeni bir tarz yaratmıştır. 5. Uluslararası Fajr Görsel Sanatlar Festivali, 3. Tolo Khordad Görsel Sanatlar Festivali ve 14. İmam Rıza festivalinde birinci seçildi. Halen mesleğini başarıyla inşaa etmektedir desiner Fashion and Art defileler düzenlemektedir."
    },
    SEVGI_AGANOGLU: {
      TITLE: "Sevgi Ağanoğlu (1966 - )",
      TEXT: "1966 doğumlu Sevgi Ağanoğlu ortaokul yıllarında karakalem ile başlayan resim hayatını yağlı boya çalışmaları ile sürdürmektedir. Anadolu Üniversitesi Halkla İlişkiler ve Reklam Yönetimi Uzmanlığı eğitimini tamamlayan Ağanoğlu, ayrıca Siyaset Akademisi’nde ve Sakarya Üniversitesi’nde Proje Yazımı ve Yürütümü Uzmanlığı bölümlerinde eğitim almıştır.  İş hayatı boyunca farklı ülkelere yaptığı seyahatlerde edindiği kültürel  izlenimler 2010 yılından itibaren yaptığı resimlere ilham vermiştir. Sanatçı bugün yazılım endüstrisindeki Genel Koordinatörlük rolünden ayrılmış olup profesyonel olarak sanat hayatına devam etmektedir."
    },
    CETIN_PIRECI: {
      TITLE: "Çetin Pireci (1960 - )",
      TEXT: "Çetin Pireci, Marmara Üniversitesi Güzel Sanatlar Fakültesi Seramik Anasanat Dalından başarılı bir eğitim sürecinin ardından mezun olmuş, ardından 1987 yılında Mimar Sinan Üniversitesi Güzel Sanatlar Enstitüsü'nde Seramik-Cam Ana Sanat Dalı'nda yüksek lisans derecesini elde etmiştir. Sanat hayatı boyunca altısı kişisel olmak üzere, seksenden fazla sergi ve bienale katılarak ulusal ve uluslararası arenada adını duyurmuştur."
    },
    ERDEM_BAYTEMUR: {
      TITLE: "Erdem Baytemur (1998 - ) ",
      TEXT: "Erdem Baytemur 1998 yılında Elazığ'da doğdu. İlkokul ve ortaokulu orada tamamladıktan sonra Malatya Abdulkadir Eriş Güzel Sanatlar Lisesinde 4 yıl yatılı okudu. Ardından İnönü Üniversitesi Gsf bölümünde öğrenimine devam edip şuan Ankarada çalışmalarına devam etmektedir."
    },
    FILIZ_AKSU: {
      TITLE: "Filiz Aksu (1977 - ) ",
      TEXT: "Filiz Aksu 1977 yılında İstanbul'da doğdu ve iç mimarlık eğitimi aldı. Sektörde iç mimar olarak uzun yıllar boyunca çalıştı ve son 8 yıldır öğretim görevlisi olarak mimari proje dersleri vermektedir. Sanata olan ilgisi uzun yıllar öncesine dayanır ve bu alanda kendisini sürekli olarak geliştirmiştir. Özellikle, tuval üzerine akrilik boya ile yaptığı tasarımları büyük bir keyif ve özenle hazırlamaktadır. Tezhip sanatı eğitimi alarak müzehhib olarak birçok eserde çalışma şansı elde etmiştir."
    },
    MEHMET_DEMIR: {
      TITLE: 'Mehmet Demir (1995 - )',
      TEXT: '1995 yılında Gaziantep’te doğmuştur. Lise eğitimini 2009-2014 yılları arasında Gaziantep Güzel Sanatlar Lisesi’nde tamamlamıştır. 2015-2019 yılları arasında Akdeniz Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümünde lisans eğitimini tamamlamış olup, 2019 yılında Akdeniz Üniversitesi Güzel Sanatlar Enstitüsü Resim Anasanat dalını kazanmıştır. 2021 yılında yüksek lisans eğitimini bitirerek Antalya’da sanat üretimine devam etmektedir. Çalışmalarında toplumun uyumsuzluğuna karşı çıkarak formlardan ve renklerden oluşan kompozisyonlar görülmektedir.'
    },
    MUGE_CUCU: {
      TITLE: 'Müge Cücü (1978 - )',
      TEXT: 'Müge Cücü, İstanbul\'da doğmuş ve 2002 yılında Anadolu Üniversitesi Halkla İlişkiler ve Reklamcılık bölümünden mezun olmuştur. Çocukluğundan beri resim sanatına büyük bir ilgisi olan Cücü, 2003-2004 yılları arasında TEGV\'de Sokak Çocukları Rehabilite programında gönüllü olarak resim dersleri vermiştir. 2005 yılında Antalya\'ya yerleşmiş ve burada 4 yıl boyunca alanında kendini kanıtlamış hocalardan seramik ve resim eğitimi almıştır. Müge Cücü, birçok karma ve online sergide eserleriyle yer almanın yanı sıra, bir çocuk kitabını resimlemiş ve kolajları ile illüstrasyonları birçok dergide yayımlanmıştır.'
    },
    FERHAT_ARAT: {
      TITLE: 'Ferhat Arat (1996 - )',
      TEXT: 'Ferhat Arat, 1996 yılında Konya\'da doğmuş ve 2015-2019 yılları arasında Akdeniz Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümünden mezun olmuştur. Ardından aynı üniversitenin Güzel Sanatlar Enstitüsü Resim Anasanat Dalı Yüksek Lisans Programını 2019-2021 yılları arasında tamamlamıştır. Arat\'ın eserleri pek çok özel ve devlet koleksiyonunda yer almaktadır. Sanat çalışmalarına Antalya\'da bulunan sanat atölyesinde devam etmektedir.'
    },
    YUSUF_DURAN: {
      TITLE: 'Yusuf Duran (1985 - )',
      TEXT: 'Yusuf Duran, 1985 yılında Ankara\'da dünyaya gelmiş bir sanatçıdır. Çocukluk yıllarından itibaren resim sanatına büyük bir ilgi duymuş ve bu alanda kendini geliştirmiştir. Eğitim hayatına Ankara\'da başlayıp, lisans derecesini Eskişehir Anadolu Üniversitesi\'nde, yüksek lisansını ise Ankara Atılım Üniversitesi İşletme Yönetimi bölümünde tamamlamıştır. Profesyonel kariyerine iç mimarlık ve tasarım alanlarında global bir şirkette devam eden Duran, aynı zamanda sürdürülebilirlik temalı sanatsal faaliyetler gerçekleştirmekte ve işletme yönetimi üzerine akademik çalışmalar yapmaktadır. 2020 yılında kendi atölyesini kurarak Ankara\'da profesyonel resim kariyerine devam eden sanatçı, realizm akımlarından etkilenmiş olup, eserlerinde bu tarzı benimsemiştir.'
    },
    HAKAN_ESMER: {
      TITLE: 'Hakan Esmer (1973 - )',
      TEXT: '1973 yılında Trabzon\'un Vakfıkebir ilçesinde doğan Hakan Esmer, 1994\'te Karadeniz Teknik Üniversitesi\'nden ve aynı yıl Anadolu Üniversitesi\'nden yüksek lisans derecesiyle mezun olmuştur. 1996\'dan itibaren Anadolu Üniversitesi\'nde öğretim görevlisi olarak görev yapmış, çeşitli sanat dersleri vermiştir. Esmer, 37 kişisel sergi açmış ve 15 ödül kazanmıştır, ödülleri arasında uluslararası yarışmalar da bulunmaktadır. T.C. Dışişleri Bakanlığı tarafından yürütülen bir proje kapsamında eserleri uluslararası koleksiyonlara dahil edilmiştir. 2012\'de akademik görevinden ayrılarak sanatını kendi atölyesinde sürdürmektedir.'
    },
    IBRAHIM_MISIRLIOGLU: {
      TITLE: 'İbrahim Mısırlıoğlu (1959 - )',
      TEXT: '1959 yılı İzmit doğumlu İbrahim Mısırlıoğlu, 1985\'te Marmara Üniversitesi Güzel Sanatlar Fakültesinden mezun oldu. Hüsamettin Koçan, Ergin İnan ve Mustafa Pilevneli gibi ustaların rehberliğinde resim üzerine eğitim aldı. Sanat hayatını İstanbul\'daki atölyesinde sürdüren Mısırlıoğlu, sanatının çeşitli yönlerini bu mekanda geliştirmeye devam etmektedir.'
    },

    GULSAH_KURT: {
      TITLE: 'Gülşah Kurt (1995 - )',
      TEXT: 'Gülşah Kurt, 1995 yılında Bilecik\'te doğdu. 2023 senesinde Bilecik Şeyh Edebali Üniversitesi Güzel Sanatlar ve Tasarım Fakültesinde Fakülte ikinciliği derecesi ve resim bölümünde ise birincilik derecesi alarak mezun oldu. Üniversite\'de aldığı eğitime ek olarak Pedagojik Formasyon eğitimi almıştır.Eserlerinde Soyut dışavurumculuk akımından etkilenerek kendine özgü teknik ile özgürce renkler, farklı doku teknikleri kullanarak kendini ifade ettiği bir tarz oluşturmuştur.Sanat onun için hayatın tüm renklerini kapsayan bir alan olmuştur.'
    },
    UGUR_PEKCAN: {
      TITLE: 'Uğur Pekcan (1992 - )',
      TEXT: 'Uğur Pekcan, 1998 ile 2003 yılları arasında Çorlu\'daki Hanife Şefik İlköğretim Okulunda eğitim aldı. Orta öğrenimine Uncular Süleyman Peker Ortaokulu\'nda devam etti ve ardından Tekirdağ Güzel Sanatlar ve Spor Lisesi\'nden mezun oldu. Üniversite eğitimini Trakya Üniversitesi Güzel Sanatlar Fakültesi\'nde tamamlayan sanatçı, daha sonra 2016 ile 2018 yılları arasında Tekirdağ Namık Kemal Üniversitesi Güzel Sanatlar ve Mimarlık Fakültesinde yüksek lisans derecesi aldı. Görsel sanatlar eğitmenliği de yapan sanatçının kişisel sergileri arasında, 2 Mayıs 2016\'da Edirne Karaağaç\'taki Siyah Beyaz Sanat Galerisi\'nde düzenlenen "Karmaşık" sergisi ve 21 Mart 2016\'da yine Edirne Karaağaç\'taki Trakya Üniversitesi Güzel Sanatlar Fakültesi\'nde açılan "Hayat Ağacı" sergisi bulunmaktadır.'
    },
    KIVANC_GULHAN: {
      TITLE: 'Kıvanç Gülhan (1963 - )',
      TEXT: 'Kıvanç Gülhan, 15 Şubat 1963\'te doğmuş ve farklı şehirlerde hayatına devam etmiştir. Ankara\'da mühendislik eğitimi alan sanatçı 26 kişisel sergi açmıştır. Sanatçının eserleri, özel koleksiyonlarda ve Roma\'daki Moseo Di Villa Vecchia\'da bulunmaktadır. İnşaat Mühendisliği kariyerine paralel olarak sanatla da ilgilenen Gülhan, Design Turkey \'05 Ödülüne finalist olmuştur. Güzel Sanatlar Fakültelerinde dersler veren Gülhan, Gaziantep\'te yaşamaktadır ve burada 27. kişisel sergisini açmıştır. Sergi, Gaziantep Büyükşehir Belediyesi ve Gaski tarafından "Dünya Su Günü" dolayısıyla düzenlenmiş ve eserler suluboya tekniğiyle üretilmiştir.'
    },
    ISIL_CALIK: {
      TITLE: 'Işıl Çalık (1965 - )',
      TEXT: 'Işıl Çalık 1965 yılında Ankara\'da doğdu. Evli ve üç çocuk annesi olan sanatçı 1987 yılında Ankara Üniversitesi Dil ve Tarih Coğrafya Fakültesinden mezun oldu. Ancak ilkokul döneminden itibaren resme olan tutkusu her zaman ön planda olduğu için bu tutkusu onu resim konusunda eğitim almaya yönlendirdi. Zaman içerisinde çeşitli kurslardan ve usta ressamlardan ders aldı. Birçok karma sergide yer alma fırsatı buldu. Sanat hayatına yaklaşık 20 yıldır resim eğitmenliği yaparak devam etmektedir.'
    },
    YONCA_USTA: {
      TITLE: 'Yonca Usta (1992 - )',
      TEXT: 'Yonca Usta, 30 Eylül 1992 tarihinde Trabzon\'da doğdu. Anadolu Güzel Sanatlar Lisesi Resim bölümünü 2010 yılında bitirdi. 2017 yılında Yeditepe Üniversitesi Plastik Sanatlar Resim, Heykel ve Seramik bölümünden mezun oldu. Resim dahil olmak üzere birçok sanat alanında 14 yıllık bir eğitime sahip. Uzun yıllar boyunca Mustafa Ata atölyesinde asistanlık yaptı. İlk etkilendiği akım soyut dışavurumculuk olup, yaptığı eserler özgün ve modern sanat üzerine. Birçok koleksiyon oluşturuyor ve her koleksiyonun özel hikayesini içsel bir şekilde yazıyor. Kendini en iyi ve kaliteli sanatla ifade ediyor. Kişisel sergisi dahil olmak üzere birçok sergide yer aldı.'
    },
    // ...test_auction_config_tr.AUCTION_ARTISTS
  },
};
