<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE"
     class="d-flex flex-column w-100 h-100 mx-3">
  <div class="w-100 d-flex flex-column"
       style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 150px; min-height: 150px; border-radius: 4px; margin-bottom: 10px;">
    <div class="my-3 ml-4"
         style="font-family: Roboto, sans-serif; font-size: 15px; line-height: 0.7; color: #404352;">
      {{managerService.userService.user?.email | managerPipe}}
    </div>
    <div class="my-2 ml-4"
         style="font-family: bebas-neue-bold, sans-serif;font-size: 40px; line-height: 1.2; letter-spacing: 4px; color: #404352;">
      {{managerService.config.SECURITY.HEADING | managerPipe}}
    </div>
    <div class="my-1 ml-4">
      <button (click)="managerService.active_security_panel = managerService.active_security_panel_template.CHANGE_PASSWORD"
              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD"
              class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.SECURITY.CHANGE_PASSWORD.HEADING | managerPipe}}</button>
      <button (click)="managerService.active_security_panel = managerService.active_security_panel_template.TWO_FACTOR_AUTHENTICATOR"
              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR"
              class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.HEADING | managerPipe}}</button>
<!--      <button (click)="managerService.active_security_panel = managerService.active_security_panel_template.IDENTITY_VERIFICATION"-->
<!--              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION"-->
<!--              class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.SECURITY.IDENTITY_VERIFICATION.HEADING | managerPipe}}</button>-->
      <button (click)="managerService.navigationManager({url: '/download-app', current_component_index: 0})"
              [class.btn-account-navigator-active]="managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION"
              class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.SECURITY.IDENTITY_VERIFICATION.HEADING | managerPipe}}</button>
    </div>
  </div>
  <app-change-password-desktop [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD)"></app-change-password-desktop>
  <app-authenticator-desktop
    [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR)"></app-authenticator-desktop>
  <app-identity-verification-desktop
    [class.d-none]="!(managerService.active_security_panel | managerPipe: managerService.mpa_template.ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION)"></app-identity-verification-desktop>
</div>
