import { Component } from '@angular/core';
import {ManagerService} from "../../../../services/manager.service";

@Component({
  selector: 'app-download-app-desktop',
  templateUrl: './download-app-desktop.component.html',
  styleUrl: './download-app-desktop.component.css'
})
export class DownloadAppDesktopComponent {
  constructor(public managerService: ManagerService) {
  }

  openGooglePlay(): void {
    window.open('https://play.google.com/store/apps/details?id=com.artiox.artiox', '_blank');
  }

  openAppStore(): void {
    window.open('itms-apps://apple.com/app/id1579037676?l=tr', '_blank');
  }
}
