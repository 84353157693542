import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActiveMobileNavbarPanelTemplate, ManagerService} from '../../services/manager.service';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('scroll_button') scroll_button: any = undefined;

  public handleDragStart: any = undefined;
  public handleDragMove: any = undefined;
  public handleDragEnd: any = undefined;

  public handleDragMovePositionCalculator: any = undefined;
  public scrollTimeout: any = undefined;

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // if (this.managerService.view_port === 'mobile' && !(this.managerService.environment.app)) {
    // this.constructScrollButton();
    // }
  }

  ngOnDestroy(): void {
    // if (this.managerService.view_port === 'mobile' && !(this.managerService.environment.app)) {
    // this.destructScrollButton();
    // }
  }

  constructScrollButton(): void {
    let originY: number = 0;
    let eventY: number = 0;
    let moveY: number = 0;
    let posY: number = 0;
    let touchMove: boolean = false;

    this.handleDragStart = (e: any): void => {
      e.preventDefault();
      originY = e.clientY || e.touches[0].clientY;
      this.scroll_button.nativeElement.style.opacity = '0.75';
      touchMove = true;
    };

    this.handleDragMove = (e: any): void => {
      e.preventDefault();
      eventY = e.clientY || e.touches[0].clientY;
      moveY = eventY - originY;
      if (posY + moveY >= 0 && posY + moveY <= window.innerHeight - 152) {
        this.scroll_button.nativeElement.style.transform = 'translateY(' + (posY + moveY).toString() + 'px)';
        window.scroll({top: Math.floor((document.body.offsetHeight - window.innerHeight) * ((posY + moveY) / (window.innerHeight - 152)))});
      }
    };

    this.handleDragEnd = (e: any): void => {
      e.preventDefault();
      if (posY + moveY < 0) {
        posY = 0;
        this.scroll_button.nativeElement.style.transform = 'translateY(' + (posY).toString() + 'px)';
        window.scroll({top: Math.floor((document.body.offsetHeight - window.innerHeight) * ((posY) / (window.innerHeight - 152)))});
      } else if (posY + moveY > window.innerHeight - 152) {
        posY = window.innerHeight - 152;
        this.scroll_button.nativeElement.style.transform = 'translateY(' + (posY).toString() + 'px)';
        window.scroll({top: Math.floor((document.body.offsetHeight - window.innerHeight) * ((posY) / (window.innerHeight - 152)))});
      } else {
        posY = posY + moveY;
        this.scroll_button.nativeElement.style.transform = 'translateY(' + (posY).toString() + 'px)';
        window.scroll({top: Math.floor((document.body.offsetHeight - window.innerHeight) * ((posY) / (window.innerHeight - 152)))});
      }
      touchMove = false;
    };

    this.handleDragMovePositionCalculator = (e: any): void => {
      e.preventDefault();
      if (!touchMove) {
        posY = Math.floor((window.innerHeight - 152) * (window.scrollY / (document.body.offsetHeight - window.innerHeight)));
        this.scroll_button.nativeElement.style.transform = 'translateY(' + posY.toString() + 'px)';
        this.scroll_button.nativeElement.style.opacity = '0.75';
      }
      clearInterval(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.scroll_button.nativeElement.style.opacity = '0';
      }, 2000);
    };

    this.scroll_button.nativeElement.addEventListener('touchstart', this.handleDragStart);
    this.scroll_button.nativeElement.addEventListener('touchmove', this.handleDragMove);
    this.scroll_button.nativeElement.addEventListener('touchend', this.handleDragEnd);
    document.addEventListener('scroll', this.handleDragMovePositionCalculator);
  }

  destructScrollButton(): void {
    this.scroll_button.nativeElement.removeEventListener('touchstart', this.handleDragStart);
    this.scroll_button.nativeElement.removeEventListener('touchmove', this.handleDragMove);
    this.scroll_button.nativeElement.removeEventListener('touchend', this.handleDragEnd);
    document.removeEventListener('scroll', this.handleDragMovePositionCalculator);
  }

  activateMobilNavbar(active_mobile_navbar_panel: ActiveMobileNavbarPanelTemplate): void {
    if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.HOME) {
      if (this.managerService.logged_in || !this.managerService.utilityService.checkReleaseUrl()) {
        this.managerService.navigationManager({url: '/home', current_component_index: 0});
      } else {
        this.managerService.navigateToTrArtiox();
      }
    } else if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.BASIC_TRADE) {
      this.managerService.navigateIfLoggedIn({url: '/exchange/basic', current_component_index: 0});
    } else if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.ADVANCED_TRADE) {
      this.managerService.navigateIfLoggedIn({url: '/exchange/advanced', current_component_index: 0});
    } else if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.AUCTION) {
      this.managerService.navigateIfLoggedIn({url: '/auction', current_component_index: 0});
    } else if (active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.ACCOUNT) {
      this.managerService.navigateIfLoggedIn({url: '/account/balance', current_component_index: 0});
    }

    if (this.managerService.logged_in) {
      this.managerService.active_mobile_navbar_panel = active_mobile_navbar_panel;
    }
    this.managerService.account_navbar_menu_display_status = false;
    if (this.managerService.logged_in || active_mobile_navbar_panel === this.managerService.active_mobile_navbar_panel_template.HOME) {
      this.managerService.navigationService.clearNavigationHistory();
    }
  }

}
