import {enableProdMode, Injectable, NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
// module //
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from './modules/modal/modal.module';
import {TooltipModule} from './modules/tooltip/tooltip.module';
import {SnackBarModule} from './modules/snack-bar/snack-bar.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
// hammerjs //
import 'hammerjs';

import {environment} from '../environments/environment';
// providers //
import {CookieService} from 'ngx-cookie-service';
import {HttpClientInterceptorProvider} from './services/http-client.interceptor';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ManagerPipe} from './services/manager.pipe';
import {HeaderComponent} from './components/header/header.component';
import {BodyComponent} from './components/body/body.component';
import {FooterComponent} from './components/footer/footer.component';
import {AccountComponent} from './components/body/account/account.component';
import {AdminPanelComponent} from './components/body/admin-panel/admin-panel.component';
import {AnnouncementComponent} from './components/body/announcement/announcement.component';
import {AuxiliaryComponent} from './components/body/auxiliary/auxiliary.component';
import {ExchangeComponent} from './components/body/exchange/exchange.component';
import {HomeComponent} from './components/body/home/home.component';
// import {LandingPageComponent} from './components/body/landing-page/landing-page.component';
import {PreSaleComponent} from './components/body/pre-sale/pre-sale.component';
import {AuctionComponent} from './components/body/auction/auction.component';
import {SignUpComponent} from './components/body/sign-up/sign-up.component';
import {SignUpSuccessfulComponent} from './components/body/sign-up-successful/sign-up-successful.component';
import {UserChangePasswordComponent} from './components/body/user-change-password/user-change-password.component';
import {WithdrawalConfirmedComponent} from './components/body/withdrawal-confirmed/withdrawal-confirmed.component';
import {WithdrawalAlreadyConfirmedComponent} from './components/body/withdrawal-already-confirmed/withdrawal-already-confirmed.component';
import {WithdrawalExpiredComponent} from './components/body/withdrawal-expired/withdrawal-expired.component';

// exchange //
import {AdvancedComponent} from './components/body/exchange/advanced/advanced.component';
import {BasicComponent} from './components/body/exchange/basic/basic.component';

// account //
import {BalanceComponent} from './components/body/account/balance/balance.component';
import {DashboardComponent} from './components/body/account/dashboard/dashboard.component';
import {DepositComponent} from './components/body/account/deposit/deposit.component';
import {NavigationSideBarComponent} from './components/body/account/navigation-side-bar/navigation-side-bar.component';
import {SecurityComponent} from './components/body/account/security/security.component';
import {WithdrawalComponent} from './components/body/account/withdrawal/withdrawal.component';
import {AccountAuctionComponent} from './components/body/account/account-auction/account-auction.component';
import {UserInfoComponent} from './components/body/account/user-info/user-info.component';

// mobile //
import {HeaderMobileComponent} from './components/header/header-mobile/header-mobile.component';
import {AdminPanelMobileComponent} from './components/body/admin-panel/admin-panel-mobile/admin-panel-mobile.component';
import {AnnouncementMobileComponent} from './components/body/announcement/announcement-mobile/announcement-mobile.component';
import {AuxiliaryMobileComponent} from './components/body/auxiliary/auxiliary-mobile/auxiliary-mobile.component';
import {HomeMobileComponent} from './components/body/home/home-mobile/home-mobile.component';
// import {LandingPageMobileComponent} from './components/body/landing-page/landing-page-mobile/landing-page-mobile.component';
import {PreSaleMobileComponent} from './components/body/pre-sale/pre-sale-mobile/pre-sale-mobile.component';
import {FooterMobileComponent} from './components/footer/footer-mobile/footer-mobile.component';
import {SignUpSuccessfulMobileComponent} from './components/body/sign-up-successful/sign-up-successful-mobile/sign-up-successful-mobile.component';
import {UserChangePasswordMobileComponent} from './components/body/user-change-password/user-change-password-mobile/user-change-password-mobile.component';
import {WithdrawalConfirmedMobileComponent} from './components/body/withdrawal-confirmed/withdrawal-confirmed-mobile/withdrawal-confirmed-mobile.component';
import {WithdrawalAlreadyConfirmedMobileComponent} from './components/body/withdrawal-already-confirmed/withdrawal-already-confirmed-mobile/withdrawal-already-confirmed-mobile.component';
import {WithdrawalExpiredMobileComponent} from './components/body/withdrawal-expired/withdrawal-expired-mobile/withdrawal-expired-mobile.component';
// account mobile level 1 //
import {BalanceMobileComponent} from './components/body/account/balance/balance-mobile/balance-mobile.component';
import {DashboardMobileComponent} from './components/body/account/dashboard/dashboard-mobile/dashboard-mobile.component';
import {DepositMobileComponent} from './components/body/account/deposit/deposit-mobile/deposit-mobile.component';
import {WithdrawalMobileComponent} from './components/body/account/withdrawal/withdrawal-mobile/withdrawal-mobile.component';
import {NavigationSideBarMobileComponent} from './components/body/account/navigation-side-bar/navigation-side-bar-mobile/navigation-side-bar-mobile.component';
import {SecurityMobileComponent} from './components/body/account/security/security-mobile/security-mobile.component';
// account mobile level 2 //
import {OperationHistoryMobileComponent} from './components/body/account/balance/balance-mobile/operation-history-mobile/operation-history-mobile.component';
import {OrderHistoryMobileComponent} from './components/body/account/balance/balance-mobile/order-history-mobile/order-history-mobile.component';
import {SummaryMobileComponent} from './components/body/account/balance/balance-mobile/summary-mobile/summary-mobile.component';
import {DepositFiatMobileComponent} from './components/body/account/deposit/deposit-mobile/deposit-fiat-mobile/deposit-fiat-mobile.component';
import {DepositCryptocurrencyMobileComponent} from './components/body/account/deposit/deposit-mobile/deposit-cryptocurrency-mobile/deposit-cryptocurrency-mobile.component';
import {DepositHistoryMobileComponent} from './components/body/account/deposit/deposit-mobile/deposit-history-mobile/deposit-history-mobile.component';
import {WithdrawalFiatMobileComponent} from './components/body/account/withdrawal/withdrawal-mobile/withdrawal-fiat-mobile/withdrawal-fiat-mobile.component';
import {WithdrawalHistoryMobileComponent} from './components/body/account/withdrawal/withdrawal-mobile/withdrawal-history-mobile/withdrawal-history-mobile.component';
import {WithdrawalCryptocurrencyMobileComponent} from './components/body/account/withdrawal/withdrawal-mobile/withdrawal-cryptocurrency-mobile/withdrawal-cryptocurrency-mobile.component';
import {AuthenticatorMobileComponent} from './components/body/account/security/security-mobile/authenticator-mobile/authenticator-mobile.component';
import {ChangePasswordMobileComponent} from './components/body/account/security/security-mobile/change-password-mobile/change-password-mobile.component';
import {IdentityVerificationMobileComponent} from './components/body/account/security/security-mobile/identity-verification-mobile/identity-verification-mobile.component';
import {OverviewMobileComponent} from './components/body/account/security/security-mobile/overview-mobile/overview-mobile.component';
// announcement mobile level 1 //
// auxiliary mobile level 1 //
import {ArtMarketMobileComponent} from './components/body/auxiliary/auxiliary-mobile/art-market-mobile/art-market-mobile.component';
import {CurrencyConverterMobileComponent} from './components/body/auxiliary/auxiliary-mobile/currency-converter-mobile/currency-converter-mobile.component';
import {HowItWorksMobileComponent} from './components/body/auxiliary/auxiliary-mobile/how-it-works-mobile/how-it-works-mobile.component';
import {WhatIsArtioxMobileComponent} from './components/body/auxiliary/auxiliary-mobile/what-is-artiox-mobile/what-is-artiox-mobile.component';
import {ForgotPasswordMobileComponent} from './components/body/auxiliary/auxiliary-mobile/forgot-password-mobile/forgot-password-mobile.component';
import {LoginMobileComponent} from './components/body/auxiliary/auxiliary-mobile/login-mobile/login-mobile.component';
import {CaptchaMobileComponent} from './components/body/auxiliary/auxiliary-mobile/captcha-mobile/captcha-mobile.component';
import {AuctionBidRulesMobileComponent} from './components/body/auxiliary/auxiliary-mobile/auction-bid-rules-mobile/auction-bid-rules-mobile.component';
import {AuctionDurationRulesMobileComponent} from './components/body/auxiliary/auxiliary-mobile/auction-duration-rules-mobile/auction-duration-rules-mobile.component';
import {AuctionShareMobileComponent} from './components/body/auxiliary/auxiliary-mobile/auction-share-mobile/auction-share-mobile.component';
import {AuctionBidReportMobileComponent} from './components/body/auxiliary/auxiliary-mobile/auction-bid-report-mobile/auction-bid-report-mobile.component';
import {AuctionBidApprovedMobileComponent} from './components/body/auxiliary/auxiliary-mobile/auction-bid-approved-mobile/auction-bid-approved-mobile.component';
import {AuctionPriceEstimationDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/auction-price-estimation-desktop/auction-price-estimation-desktop.component';
import {AddBankAccountMobileComponent} from './components/body/auxiliary/auxiliary-mobile/add-bank-account-mobile/add-bank-account-mobile.component';
import {AddCryptoAccountMobileComponent} from './components/body/auxiliary/auxiliary-mobile/add-crypto-account-mobile/add-crypto-account-mobile.component';
import {FiatWithdrawalRequestMobileComponent} from './components/body/auxiliary/auxiliary-mobile/fiat-withdrawal-request-mobile/fiat-withdrawal-request-mobile.component';
import {CryptoWithdrawalRequestMobileComponent} from './components/body/auxiliary/auxiliary-mobile/crypto-withdrawal-request-mobile/crypto-withdrawal-request-mobile.component';
import {TestEnvironmentWarningMobileComponent} from './components/body/auxiliary/auxiliary-mobile/test-environment-warning-mobile/test-environment-warning-mobile.component';
import {AnnouncementSliderMobileComponent} from './components/body/auxiliary/auxiliary-mobile/announcement-slider-mobile/announcement-slider-mobile.component';
import {ConnectionFailMobileComponent} from './components/body/auxiliary/auxiliary-mobile/connection-fail-mobile/connection-fail-mobile.component';
import {FiatDepositRulesMobileComponent} from './components/body/auxiliary/auxiliary-mobile/fiat-deposit-rules-mobile/fiat-deposit-rules-mobile.component';
import {CryptoDepositRulesMobileComponent} from './components/body/auxiliary/auxiliary-mobile/crypto-deposit-rules-mobile/crypto-deposit-rules-mobile.component';
import {OnePagerMobileComponent} from './components/body/auxiliary/auxiliary-mobile/one-pager-mobile/one-pager-mobile.component';

// exchange mobile level 1 //
import {AdvancedMobileComponent} from './components/body/exchange/advanced/advanced-mobile/advanced-mobile.component';
import {BasicMobileComponent} from './components/body/exchange/basic/basic-mobile/basic-mobile.component';
// exchange mobile level 2 //
import {OrderBookBalanceMobileComponent} from './components/body/exchange/advanced/advanced-mobile/order-book-balance-mobile/order-book-balance-mobile.component';
import {PairHeaderMobileComponent} from './components/body/exchange/advanced/advanced-mobile/pair-header-mobile/pair-header-mobile.component';
import {PairListMobileComponent} from './components/body/exchange/advanced/advanced-mobile/pair-list-mobile/pair-list-mobile.component';
import {TradeMobileComponent} from './components/body/exchange/advanced/advanced-mobile/trade-mobile/trade-mobile.component';
import {TradeChartMobileComponent} from './components/body/exchange/advanced/advanced-mobile/trade-chart-mobile/trade-chart-mobile.component';
import {TradeHistoryMobileComponent} from './components/body/exchange/advanced/advanced-mobile/trade-history-mobile/trade-history-mobile.component';
import {UserTradeHistoryMobileComponent} from './components/body/exchange/advanced/advanced-mobile/user-trade-history-mobile/user-trade-history-mobile.component';
import {UserOpenOrdersMobileComponent} from './components/body/exchange/advanced/advanced-mobile/user-open-orders-mobile/user-open-orders-mobile.component';
import {OrderBookMobileComponent} from './components/body/exchange/advanced/advanced-mobile/order-book-balance-mobile/order-book-mobile/order-book-mobile.component';
import {UserBalanceMobileComponent} from './components/body/exchange/advanced/advanced-mobile/order-book-balance-mobile/user-balance-mobile/user-balance-mobile.component';
import {ArtProductMobileComponent} from './components/body/exchange/basic/basic-mobile/art-product-mobile/art-product-mobile.component';
import {ArtProductInfoMobileComponent} from './components/body/exchange/basic/basic-mobile/art-product-info-mobile/art-product-info-mobile.component';
import {ArtProductReportListMobileComponent} from './components/body/exchange/basic/basic-mobile/art-product-report-list-mobile/art-product-report-list-mobile.component';
import {LastTradeMobileComponent} from './components/body/exchange/basic/basic-mobile/last-trade-mobile/last-trade-mobile.component';
import {BasicPairHeaderMobileComponent} from './components/body/exchange/basic/basic-mobile/basic-pair-header-mobile/basic-pair-header-mobile.component';
import {BasicPairListMobileComponent} from './components/body/exchange/basic/basic-mobile/basic-pair-list-mobile/basic-pair-list-mobile.component';
import {BasicTradeMobileComponent} from './components/body/exchange/basic/basic-mobile/basic-trade-mobile/basic-trade-mobile.component';
import {BasicUserBalanceMobileComponent} from './components/body/exchange/basic/basic-mobile/basic-user-balance-mobile/basic-user-balance-mobile.component';
import {BasicArtProductCanvasMobileComponent} from './components/body/exchange/basic/basic-mobile/basic-art-product-canvas-mobile/basic-art-product-canvas-mobile.component';
// exchange mobile level 3 //
import {LimitTradeMobileComponent} from './components/body/exchange/advanced/advanced-mobile/trade-mobile/limit-trade-mobile/limit-trade-mobile.component';
import {MarketTradeMobileComponent} from './components/body/exchange/advanced/advanced-mobile/trade-mobile/market-trade-mobile/market-trade-mobile.component';
// home mobile level 1 //
import {SliderMobileComponent} from './components/body/home/home-mobile/slider-mobile/slider-mobile.component';
// landing-page mobile level 1 //
// import {GreeterMobileComponent} from './components/body/landing-page/landing-page-mobile/greeter-mobile/greeter-mobile.component';
// import {FaqMobileComponent} from './components/body/landing-page/landing-page-mobile/faq-mobile/faq-mobile.component';
// pre-sale desktop level 1 //
import {FormerSalesDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/former-sales-desktop/former-sales-desktop.component';
import {LeftInfoDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/left-info-desktop/left-info-desktop.component';
import {PreSaleArtProductDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/pre-sale-art-product-desktop/pre-sale-art-product-desktop.component';
import {PreSaleArtProductCanvasDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/pre-sale-art-product-canvas-desktop/pre-sale-art-product-canvas-desktop.component';
import {PreSaleArtProductInfoDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/pre-sale-art-product-info-desktop/pre-sale-art-product-info-desktop.component';
import {PreSaleArtProductReportListDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/pre-sale-art-product-report-list-desktop/pre-sale-art-product-report-list-desktop.component';
import {PreSaleHeaderDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/pre-sale-header-desktop/pre-sale-header-desktop.component';
import {PreSaleNavigatorDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/pre-sale-navigator-desktop/pre-sale-navigator-desktop.component';
import {PreSaleTradeDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/pre-sale-trade-desktop/pre-sale-trade-desktop.component';

// auction mobile //
import {AuctionMobileComponent} from './components/body/auction/auction-mobile/auction-mobile.component';

// announcement desktop level 1 //
import {AnnouncementCategoryListDesktopComponent} from './components/body/announcement/announcement-desktop/announcement-category-list-desktop/announcement-category-list-desktop.component';
import {ArtistAnnouncementListDesktopComponent} from './components/body/announcement/announcement-desktop/artist-announcement-list-desktop/artist-announcement-list-desktop.component';
import {OperationAnnouncementListDesktopComponent} from './components/body/announcement/announcement-desktop/operation-announcement-list-desktop/operation-announcement-list-desktop.component';

// desktop //
import {HeaderDesktopComponent} from './components/header/header-desktop/header-desktop.component';
import {AdminPanelDesktopComponent} from './components/body/admin-panel/admin-panel-desktop/admin-panel-desktop.component';
import {AnnouncementDesktopComponent} from './components/body/announcement/announcement-desktop/announcement-desktop.component';
import {AuxiliaryDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/auxiliary-desktop.component';
import {HomeDesktopComponent} from './components/body/home/home-desktop/home-desktop.component';
// import {LandingPageDesktopComponent} from './components/body/landing-page/landing-page-desktop/landing-page-desktop.component';
import {PreSaleDesktopComponent} from './components/body/pre-sale/pre-sale-desktop/pre-sale-desktop.component';
import {FooterDesktopComponent} from './components/footer/footer-desktop/footer-desktop.component';
import {SignUpSuccessfulDesktopComponent} from './components/body/sign-up-successful/sign-up-successful-desktop/sign-up-successful-desktop.component';
import {UserChangePasswordDesktopComponent} from './components/body/user-change-password/user-change-password-desktop/user-change-password-desktop.component';
import {WithdrawalConfirmedDesktopComponent} from './components/body/withdrawal-confirmed/withdrawal-confirmed-desktop/withdrawal-confirmed-desktop.component';
import {WithdrawalAlreadyConfirmedDesktopComponent} from './components/body/withdrawal-already-confirmed/withdrawal-already-confirmed-desktop/withdrawal-already-confirmed-desktop.component';
import {WithdrawalExpiredDesktopComponent} from './components/body/withdrawal-expired/withdrawal-expired-desktop/withdrawal-expired-desktop.component';
// account desktop level 1 //
import {BalanceDesktopComponent} from './components/body/account/balance/balance-desktop/balance-desktop.component';
import {DashboardDesktopComponent} from './components/body/account/dashboard/dashboard-desktop/dashboard-desktop.component';
import {DepositDesktopComponent} from './components/body/account/deposit/deposit-desktop/deposit-desktop.component';
import {WithdrawalDesktopComponent} from './components/body/account/withdrawal/withdrawal-desktop/withdrawal-desktop.component';
import {NavigationSideBarDesktopComponent} from './components/body/account/navigation-side-bar/navigation-side-bar-desktop/navigation-side-bar-desktop.component';
import {SecurityDesktopComponent} from './components/body/account/security/security-desktop/security-desktop.component';
// account desktop level 2 //
import {OperationHistoryDesktopComponent} from './components/body/account/balance/balance-desktop/operation-history-desktop/operation-history-desktop.component';
import {OrderHistoryDesktopComponent} from './components/body/account/balance/balance-desktop/order-history-desktop/order-history-desktop.component';
import {SummaryDesktopComponent} from './components/body/account/balance/balance-desktop/summary-desktop/summary-desktop.component';
import {DepositCryptocurrencyDesktopComponent} from './components/body/account/deposit/deposit-desktop/deposit-cryptocurrency-desktop/deposit-cryptocurrency-desktop.component';
import {DepositFiatDesktopComponent} from './components/body/account/deposit/deposit-desktop/deposit-fiat-desktop/deposit-fiat-desktop.component';
import {DepositHistoryDesktopComponent} from './components/body/account/deposit/deposit-desktop/deposit-history-desktop/deposit-history-desktop.component';
import {WithdrawalCryptocurrencyDesktopComponent} from './components/body/account/withdrawal/withdrawal-desktop/withdrawal-cryptocurrency-desktop/withdrawal-cryptocurrency-desktop.component';
import {WithdrawalFiatDesktopComponent} from './components/body/account/withdrawal/withdrawal-desktop/withdrawal-fiat-desktop/withdrawal-fiat-desktop.component';
import {WithdrawalHistoryDesktopComponent} from './components/body/account/withdrawal/withdrawal-desktop/withdrawal-history-desktop/withdrawal-history-desktop.component';
import {AuthenticatorDesktopComponent} from './components/body/account/security/security-desktop/authenticator-desktop/authenticator-desktop.component';
import {ChangePasswordDesktopComponent} from './components/body/account/security/security-desktop/change-password-desktop/change-password-desktop.component';
import {IdentityVerificationDesktopComponent} from './components/body/account/security/security-desktop/identity-verification-desktop/identity-verification-desktop.component';
import {OverviewDesktopComponent} from './components/body/account/security/security-desktop/overview-desktop/overview-desktop.component';
// announcement desktop level 1 //
// auxiliary desktop level 1 //
import {ArtMarketDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/art-market-desktop/art-market-desktop.component';
import {CurrencyConverterDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/currency-converter-desktop/currency-converter-desktop.component';
import {HowItWorksDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/how-it-works-desktop/how-it-works-desktop.component';
import {WhatIsArtioxDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/what-is-artiox-desktop/what-is-artiox-desktop.component';
import {ForgotPasswordDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/forgot-password-desktop/forgot-password-desktop.component';
import {LoginDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/login-desktop/login-desktop.component';
import {CaptchaDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/captcha-desktop/captcha-desktop.component';
import {AuctionBidRulesDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/auction-bid-rules-desktop/auction-bid-rules-desktop.component';
import {AuctionDurationRulesDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/auction-duration-rules-desktop/auction-duration-rules-desktop.component';
import {AuctionShareDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/auction-share-desktop/auction-share-desktop.component';
import {AuctionBidReportDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/auction-bid-report-desktop/auction-bid-report-desktop.component';
import {AuctionBidApprovedDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/auction-bid-approved-desktop/auction-bid-approved-desktop.component';
import {AuctionPriceEstimationMobileComponent} from './components/body/auxiliary/auxiliary-mobile/auction-price-estimation-mobile/auction-price-estimation-mobile.component';
import {AddBankAccountDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/add-bank-account-desktop/add-bank-account-desktop.component';
import {AddCryptoAccountDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/add-crypto-account-desktop/add-crypto-account-desktop.component';
import {FiatWithdrawalRequestDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/fiat-withdrawal-request-desktop/fiat-withdrawal-request-desktop.component';
import {CryptoWithdrawalRequestDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/crypto-withdrawal-request-desktop/crypto-withdrawal-request-desktop.component';
import {TestEnvironmentWarningDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/test-environment-warning-desktop/test-environment-warning-desktop.component';
import {AnnouncementSliderDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/announcement-slider-desktop/announcement-slider-desktop.component';
import {ConnectionFailDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/connection-fail-desktop/connection-fail-desktop.component';
import {FiatDepositRulesDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/fiat-deposit-rules-desktop/fiat-deposit-rules-desktop.component';
import {CryptoDepositRulesDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/crypto-deposit-rules-desktop/crypto-deposit-rules-desktop.component';
import {OnePagerDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/one-pager-desktop/one-pager-desktop.component';


// exchange desktop level 1 //
import {AdvancedDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/advanced-desktop.component';
import {BasicDesktopComponent} from './components/body/exchange/basic/basic-desktop/basic-desktop.component';
// exchange desktop level 2 //
import {OrderBookBalanceDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/order-book-balance-desktop/order-book-balance-desktop.component';
import {PairHeaderDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/pair-header-desktop/pair-header-desktop.component';
import {PairListDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/pair-list-desktop/pair-list-desktop.component';
import {TradeChartDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/trade-chart-desktop/trade-chart-desktop.component';
import {TradeHistoryDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/trade-history-desktop/trade-history-desktop.component';
import {TradeDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/trade-desktop/trade-desktop.component';
import {UserOpenOrdersDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/user-open-orders-desktop/user-open-orders-desktop.component';
import {UserTradeHistoryDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/user-trade-history-desktop/user-trade-history-desktop.component';
import {OrderBookDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/order-book-balance-desktop/order-book-desktop/order-book-desktop.component';
import {UserBalanceDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/order-book-balance-desktop/user-balance-desktop/user-balance-desktop.component';
import {ArtProductInfoDesktopComponent} from './components/body/exchange/basic/basic-desktop/art-product-info-desktop/art-product-info-desktop.component';
import {ArtProductDesktopComponent} from './components/body/exchange/basic/basic-desktop/art-product-desktop/art-product-desktop.component';
import {ArtProductRepostListDesktopComponent} from './components/body/exchange/basic/basic-desktop/art-product-repost-list-desktop/art-product-repost-list-desktop.component';
import {BasicArtProductCanvasDesktopComponent} from './components/body/exchange/basic/basic-desktop/basic-art-product-canvas-desktop/basic-art-product-canvas-desktop.component';
import {BasicPairHeaderDesktopComponent} from './components/body/exchange/basic/basic-desktop/basic-pair-header-desktop/basic-pair-header-desktop.component';
import {BasicPairListDesktopComponent} from './components/body/exchange/basic/basic-desktop/basic-pair-list-desktop/basic-pair-list-desktop.component';
import {BasicTradeDesktopComponent} from './components/body/exchange/basic/basic-desktop/basic-trade-desktop/basic-trade-desktop.component';
import {BasicUserBalanceDesktopComponent} from './components/body/exchange/basic/basic-desktop/basic-user-balance-desktop/basic-user-balance-desktop.component';
import {LastTradeDesktopComponent} from './components/body/exchange/basic/basic-desktop/last-trade-desktop/last-trade-desktop.component';
// exchange desktop level 3 //
import {LimitTradeDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/trade-desktop/limit-trade-desktop/limit-trade-desktop.component';
import {MarketTradeDesktopComponent} from './components/body/exchange/advanced/advanced-desktop/trade-desktop/market-trade-desktop/market-trade-desktop.component';
// home desktop level 1 //
import {SliderDesktopComponent} from './components/body/home/home-desktop/slider-desktop/slider-desktop.component';
// landing-page desktop level 1 //
// import {GreeterDesktopComponent} from './components/body/landing-page/landing-page-desktop/greeter-desktop/greeter-desktop.component';
// import {FaqDesktopComponent} from './components/body/landing-page/landing-page-desktop/faq-desktop/faq-desktop.component';
// pre-sale desktop level 1 //
import {FormerSalesMobileComponent} from './components/body/pre-sale/pre-sale-mobile/former-sales-mobile/former-sales-mobile.component';
import {LeftInfoMobileComponent} from './components/body/pre-sale/pre-sale-mobile/left-info-mobile/left-info-mobile.component';
import {PreSaleArtProductMobileComponent} from './components/body/pre-sale/pre-sale-mobile/pre-sale-art-product-mobile/pre-sale-art-product-mobile.component';
import {PreSaleArtProductCanvasMobileComponent} from './components/body/pre-sale/pre-sale-mobile/pre-sale-art-product-canvas-mobile/pre-sale-art-product-canvas-mobile.component';
import {PreSaleArtProductInfoMobileComponent} from './components/body/pre-sale/pre-sale-mobile/pre-sale-art-product-info-mobile/pre-sale-art-product-info-mobile.component';
import {PreSaleArtProductReportListMobileComponent} from './components/body/pre-sale/pre-sale-mobile/pre-sale-art-product-report-list-mobile/pre-sale-art-product-report-list-mobile.component';
import {PreSaleHeaderMobileComponent} from './components/body/pre-sale/pre-sale-mobile/pre-sale-header-mobile/pre-sale-header-mobile.component';
import {PreSaleNavigatorMobileComponent} from './components/body/pre-sale/pre-sale-mobile/pre-sale-navigator-mobile/pre-sale-navigator-mobile.component';
import {PreSaleTradeMobileComponent} from './components/body/pre-sale/pre-sale-mobile/pre-sale-trade-mobile/pre-sale-trade-mobile.component';

// auction desktop //
import {AuctionDesktopComponent} from './components/body/auction/auction-desktop/auction-desktop.component';
import {AuctionArtProductDesktopComponent} from './components/body/auction/auction-desktop/auction-art-product-desktop/auction-art-product-desktop.component';
import {AuctionBidDesktopComponent} from './components/body/auction/auction-desktop/auction-bid-desktop/auction-bid-desktop.component';
import {AuctionArtistBidHistoryDesktopComponent} from './components/body/auction/auction-desktop/auction-artist-bid-history-desktop/auction-artist-bid-history-desktop.component';
import {AuctionCatalogDesktopComponent} from './components/body/auction/auction-desktop/auction-catalog-desktop/auction-catalog-desktop.component';
import {AuctionCatalogListDesktopComponent} from './components/body/auction/auction-desktop/auction-catalog-list-desktop/auction-catalog-list-desktop.component';
import {AuctionCatalogTypeDesktopComponent} from './components/body/auction/auction-desktop/auction-catalog-type-desktop/auction-catalog-type-desktop.component';

// announcement mobile level 1 //
import {AnnouncementCategoryListMobileComponent} from './components/body/announcement/announcement-mobile/announcement-category-list-mobile/announcement-category-list-mobile.component';
import {ArtistAnnouncementListMobileComponent} from './components/body/announcement/announcement-mobile/artist-announcement-list-mobile/artist-announcement-list-mobile.component';
import {OperationAnnouncementListMobileComponent} from './components/body/announcement/announcement-mobile/operation-announcement-list-mobile/operation-announcement-list-mobile.component';
import {AnnouncementNavigationBarDesktopComponent} from './components/body/announcement/announcement-desktop/announcement-navigation-bar-desktop/announcement-navigation-bar-desktop.component';
import {AnnouncementNavigationBarMobileComponent} from './components/body/announcement/announcement-mobile/announcement-navigation-bar-mobile/announcement-navigation-bar-mobile.component';

// corporate //
import {CorporateComponent} from './components/body/corporate/corporate.component';
import {AboutComponent} from './components/body/corporate/about/about.component';
import {ContactComponent} from './components/body/corporate/contact/contact.component';
import {CorporateHeaderComponent} from './components/body/corporate/corporate-header/corporate-header.component';
// corporate desktop level 1//
import {CorporateHeaderDesktopComponent} from './components/body/corporate/corporate-header/corporate-header-desktop/corporate-header-desktop.component';
import {ContactDesktopComponent} from './components/body/corporate/contact/contact-desktop/contact-desktop.component';
import {AboutDesktopComponent} from './components/body/corporate/about/about-desktop/about-desktop.component';
// corporate mobile level 1//
import {CorporateHeaderMobileComponent} from './components/body/corporate/corporate-header/corporate-header-mobile/corporate-header-mobile.component';
import {ContactMobileComponent} from './components/body/corporate/contact/contact-mobile/contact-mobile.component';
import {AboutMobileComponent} from './components/body/corporate/about/about-mobile/about-mobile.component';

// about-artist //
import {AboutArtistComponent} from './components/body/about-artist/about-artist.component';
import {AboutArtistMobileComponent} from './components/body/about-artist/about-artist-mobile/about-artist-mobile.component';
import {AboutArtistDesktopComponent} from './components/body/about-artist/about-artist-desktop/about-artist-desktop.component';

// career //
import {CareerComponent} from './components/body/corporate/career/career.component';
import {CareerMobileComponent} from './components/body/corporate/career/career-mobile/career-mobile.component';
import {CareerDesktopComponent} from './components/body/corporate/career/career-desktop/career-desktop.component';

// service //
import {ServiceComponent} from './components/body/service/service.component';
import {RulesComponent} from './components/body/service/rules/rules.component';
import {CommissionsComponent} from './components/body/service/commissions/commissions.component';
import {ServiceHeaderComponent} from './components/body/service/service-header/service-header.component';
// service desktop level 1//
import {RulesDesktopComponent} from './components/body/service/rules/rules-desktop/rules-desktop.component';
import {ServiceHeaderDesktopComponent} from './components/body/service/service-header/service-header-desktop/service-header-desktop.component';
import {CommissionsDesktopComponent} from './components/body/service/commissions/commissions-desktop/commissions-desktop.component';
// service mobile level 1//
import {RulesMobileComponent} from './components/body/service/rules/rules-mobile/rules-mobile.component';
import {ServiceHeaderMobileComponent} from './components/body/service/service-header/service-header-mobile/service-header-mobile.component';
import {CommissionsMobileComponent} from './components/body/service/commissions/commissions-mobile/commissions-mobile.component';

// page-not-found //
import {PageNotFoundComponent} from './components/body/page-not-found/page-not-found.component';
import {PageNotFoundDesktopComponent} from './components/body/page-not-found/page-not-found-desktop/page-not-found-desktop.component';
import {PageNotFoundMobileComponent} from './components/body/page-not-found/page-not-found-mobile/page-not-found-mobile.component';

// // guidebook //
// import {GuidebookComponent} from './components/body/guidebook/guidebook.component';
// import {GuidebookDesktopComponent} from './components/body/guidebook/guidebook-desktop/guidebook-desktop.component';
// import {GuidebookMobileComponent} from './components/body/guidebook/guidebook-mobile/guidebook-mobile.component';
// // account level //
// import {AccountLevelComponent} from './components/body/account-level/account-level.component';
// import {AccountLevelDesktopComponent} from './components/body/account-level/account-level-desktop/account-level-desktop.component';
// import {AccountLevelMobileComponent} from './components/body/account-level/account-level-mobile/account-level-mobile.component';
import {AuctionCatalogListMobileComponent} from './components/body/auction/auction-mobile/auction-catalog-list-mobile/auction-catalog-list-mobile.component';
import {AuctionCatalogMobileComponent} from './components/body/auction/auction-mobile/auction-catalog-mobile/auction-catalog-mobile.component';
import {AuctionBidMobileComponent} from './components/body/auction/auction-mobile/auction-bid-mobile/auction-bid-mobile.component';
import {AuctionArtistBidHistoryMobileComponent} from './components/body/auction/auction-mobile/auction-artist-bid-history-mobile/auction-artist-bid-history-mobile.component';
import {AuctionArtProductMobileComponent} from './components/body/auction/auction-mobile/auction-art-product-mobile/auction-art-product-mobile.component';
import {AuctionCatalogTypeMobileComponent} from './components/body/auction/auction-mobile/auction-catalog-type-mobile/auction-catalog-type-mobile.component';
import {AccountAuctionDesktopComponent} from './components/body/account/account-auction/account-auction-desktop/account-auction-desktop.component';
import {AccountAuctionMobileComponent} from './components/body/account/account-auction/account-auction-mobile/account-auction-mobile.component';
import {UserInfoDesktopComponent} from './components/body/account/user-info/user-info-desktop/user-info-desktop.component';
import {UserInfoMobileComponent} from './components/body/account/user-info/user-info-mobile/user-info-mobile.component';
import {MyBidsDesktopComponent} from './components/body/account/account-auction/account-auction-desktop/my-bids-desktop/my-bids-desktop.component';
import {MyWonsDesktopComponent} from './components/body/account/account-auction/account-auction-desktop/my-wons-desktop/my-wons-desktop.component';
import {MyFollowingsDesktopComponent} from './components/body/account/account-auction/account-auction-desktop/my-followings-desktop/my-followings-desktop.component';
import {MyFollowingsMobileComponent} from './components/body/account/account-auction/account-auction-mobile/my-followings-mobile/my-followings-mobile.component';
import {MyWonsMobileComponent} from './components/body/account/account-auction/account-auction-mobile/my-wons-mobile/my-wons-mobile.component';
import {MyBidsMobileComponent} from './components/body/account/account-auction/account-auction-mobile/my-bids-mobile/my-bids-mobile.component';
import {UserInfoProfileDesktopComponent} from './components/body/account/user-info/user-info-desktop/user-info-profile-desktop/user-info-profile-desktop.component';
import {AuctionPaymentComponent} from './components/body/account/auction-payment/auction-payment.component';
import {AddDeliveryAddressDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/add-delivery-address-desktop/add-delivery-address-desktop.component';
import {ApprovePaymentDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/approve-payment-desktop/approve-payment-desktop.component';
import {DeleteAddressDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/delete-address-desktop/delete-address-desktop.component';


// award //
import {AwardComponent} from './components/body/award/award.component';
import {AwardDesktopComponent} from './components/body/award/award-desktop/award-desktop.component';
import {AwardMobileComponent} from './components/body/award/award-mobile/award-mobile.component';
import {AddBillingAddressDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/add-billing-address-desktop/add-billing-address-desktop.component';
import {AddDeliveryAddressMobileComponent} from './components/body/auxiliary/auxiliary-mobile/add-delivery-address-mobile/add-delivery-address-mobile.component';
import {AddBillingAddressMobileComponent} from './components/body/auxiliary/auxiliary-mobile/add-billing-address-mobile/add-billing-address-mobile.component';

// legal //
import {LegalComponent} from './components/body/corporate/legal/legal.component';
import {LegalDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-desktop.component';
import {LegalMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-mobile.component';
import {LegalUserAgreementDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-user-agreement-desktop/legal-user-agreement-desktop.component';
import {LegalProtectionLawPolicyDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-protection-law-policy-desktop/legal-protection-law-policy-desktop.component';
import {
  LegalInformationSecurityPolicyDesktopComponent
} from './components/body/corporate/legal/legal-desktop/legal-information-security-policy-desktop/legal-information-security-policy-desktop.component';
import {LegalDataDeletionPolicyDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-data-deletion-policy-desktop/legal-data-deletion-policy-desktop.component';
import {LegalKvkkApplicationFormDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-kvkk-application-form-desktop/legal-kvkk-application-form-desktop.component';
import {LegalCookieClarificationTextDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-cookie-clarification-text-desktop/legal-cookie-clarification-text-desktop.component';
import {LegalUserAgreementMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-user-agreement-mobile/legal-user-agreement-mobile.component';
import {LegalProtectionLawPolicyMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-protection-law-policy-mobile/legal-protection-law-policy-mobile.component';
import {
  LegalInformationSecurityPolicyMobileComponent
} from './components/body/corporate/legal/legal-mobile/legal-information-security-policy-mobile/legal-information-security-policy-mobile.component';
import {LegalDataDeletionPolicyMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-data-deletion-policy-mobile/legal-data-deletion-policy-mobile.component';
import {LegalKvkkApplicationFormMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-kvkk-application-form-mobile/legal-kvkk-application-form-mobile.component';
import {LegalCookieClarificationTextMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-cookie-clarification-text-mobile/legal-cookie-clarification-text-mobile.component';
import {LegalKvkkClarificationDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-kvkk-clarification-desktop/legal-kvkk-clarification-desktop.component';
import {LegalMarketingConsentDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-marketing-consent-desktop/legal-marketing-consent-desktop.component';
import {LegalForeignConsentDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-foreign-consent-desktop/legal-foreign-consent-desktop.component';
import {LegalKvkkClarificationMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-kvkk-clarification-mobile/legal-kvkk-clarification-mobile.component';
import {LegalMarketingConsentMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-marketing-consent-mobile/legal-marketing-consent-mobile.component';
import {LegalForeignConsentMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-foreign-consent-mobile/legal-foreign-consent-mobile.component';
import {LoginComponent} from './components/body/login/login.component';
import {LoginPageMobileComponent} from './components/body/login/login-page-mobile/login-page-mobile.component';
import {LoginPageDesktopComponent} from './components/body/login/login-page-desktop/login-page-desktop.component';

import {AuctionPaymentDesktopComponent} from './components/body/account/auction-payment/auction-payment-desktop/auction-payment-desktop.component';
import {AuctionPaymentMobileComponent} from './components/body/account/auction-payment/auction-payment-mobile/auction-payment-mobile.component';
import {ApprovePaymentMobileComponent} from './components/body/auxiliary/auxiliary-mobile/approve-payment-mobile/approve-payment-mobile.component';
import {DeleteAddressMobileComponent} from './components/body/auxiliary/auxiliary-mobile/delete-address-mobile/delete-address-mobile.component';
import {ForgotPasswordComponent} from './components/body/forgot-password/forgot-password.component';
import {ForgotPasswordPageMobileComponent} from './components/body/forgot-password/forgot-password-page-mobile/forgot-password-page-mobile.component';
import {ForgotPasswordPageDesktopComponent} from './components/body/forgot-password/forgot-password-page-desktop/forgot-password-page-desktop.component';
import {SignUpDesktopComponent} from './components/body/sign-up/sign-up-desktop/sign-up-desktop.component';
import {SignUpMobileComponent} from './components/body/sign-up/sign-up-mobile/sign-up-mobile.component';
import {UserInfoProfileMobileComponent} from './components/body/account/user-info/user-info-mobile/user-info-profile-mobile/user-info-profile-mobile.component';
import {UserInfoAuctionAddressDesktopComponent} from './components/body/account/user-info/user-info-desktop/user-info-auction-address-desktop/user-info-auction-address-desktop.component';
import {AuctionProductInformationDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/auction-product-information-desktop/auction-product-information-desktop.component';
import {AuctionProductInformationMobileComponent} from './components/body/auxiliary/auxiliary-mobile/auction-product-information-mobile/auction-product-information-mobile.component';
import {NgOptimizedImage} from '@angular/common';
import {AccountLevelMobileComponent} from './components/body/auxiliary/auxiliary-mobile/account-level-mobile/account-level-mobile.component';
import {AccountLevelDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/account-level-desktop/account-level-desktop.component';
import {AuctionAgreementDesktopComponent} from './components/body/auxiliary/auxiliary-desktop/auction-agreement-desktop/auction-agreement-desktop.component';
import {AuctionAgreementMobileComponent} from './components/body/auxiliary/auxiliary-mobile/auction-agreement-mobile/auction-agreement-mobile.component';
import {LegalDistanceSellingContractDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-distance-selling-contract-desktop/legal-distance-selling-contract-desktop.component';
import {LegalDistanceSellingContractMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-distance-selling-contract-mobile/legal-distance-selling-contract-mobile.component';
import {
  LegalAuctionCancellationAndRefundAgreementDesktopComponent
} from './components/body/corporate/legal/legal-desktop/legal-auction-cancellation-and-refund-agreement-desktop/legal-auction-cancellation-and-refund-agreement-desktop.component';
import {
  LegalAuctionCancellationAndRefundAgreementMobileComponent
} from './components/body/corporate/legal/legal-mobile/legal-auction-cancellation-and-refund-agreement-mobile/legal-auction-cancellation-and-refund-agreement-mobile.component';
import {LegalAuctionUserAgreementMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-auction-user-agreement-mobile/legal-auction-user-agreement-mobile.component';
import {LegalAuctionUserAgreementDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-auction-user-agreement-desktop/legal-auction-user-agreement-desktop.component';
import {LegalAuctionRulesDesktopComponent} from './components/body/corporate/legal/legal-desktop/legal-auction-rules-desktop/legal-auction-rules-desktop.component';
import {LegalAuctionRulesMobileComponent} from './components/body/corporate/legal/legal-mobile/legal-auction-rules-mobile/legal-auction-rules-mobile.component';
import {
  LegalAuctionPreliminaryInformationFormMobileComponent
} from './components/body/corporate/legal/legal-mobile/legal-auction-preliminary-information-form-mobile/legal-auction-preliminary-information-form-mobile.component';
import {
  LegalAuctionPreliminaryInformationFormDesktopComponent
} from './components/body/corporate/legal/legal-desktop/legal-auction-preliminary-information-form-desktop/legal-auction-preliminary-information-form-desktop.component';
import {UserAgreementDesktopComponent} from "./components/body/auxiliary/auxiliary-desktop/user-agreement-desktop/user-agreement-desktop.component";
import {UserAgreementMobileComponent} from "./components/body/auxiliary/auxiliary-mobile/user-agreement-mobile/user-agreement-mobile.component";
import {SupportComponent} from './components/body/support/support.component';
import {SupportDesktopComponent} from './components/body/support/support-desktop/support-desktop.component';
import {SupportMobileComponent} from './components/body/support/support-mobile/support-mobile.component';
import {SupportDrawerComponent} from './components/body/support/support-utils/support-drawer/support-drawer.component';
import {SupportCardComponent} from './components/body/support/support-utils/support-card/support-card.component';
import {SupportCategoryComponent} from './components/body/support/support-category/support-category.component';
import {SupportCategoryDesktopComponent} from './components/body/support/support-category/support-category-desktop/support-category-desktop.component';
import {SupportCategoryMobileComponent} from './components/body/support/support-category/support-category-mobile/support-category-mobile.component';
import {
  AccountVerificationDesktopComponent
} from './components/body/account-verification/account-verification-desktop/account-verification-desktop.component';
import {AccountVerificationComponent} from './components/body/account-verification/account-verification.component';
import {
  AccountVerificationMobileComponent
} from './components/body/account-verification/account-verification-mobile/account-verification-mobile.component';
import {
  DelistingMobileComponent
} from './components/body/auxiliary/auxiliary-mobile/delisting-mobile/delisting-mobile.component';
import {
  DelistingDesktopComponent
} from './components/body/auxiliary/auxiliary-desktop/delisting-desktop/delisting-desktop.component';
import {
  LegalCommissionsDesktopComponent
} from './components/body/corporate/legal/legal-desktop/legal-commissions-desktop/legal-commissions-desktop.component';
import {
  LegalCommissionsMobileComponent
} from './components/body/corporate/legal/legal-mobile/legal-commissions-mobile/legal-commissions-mobile.component';
import {
  LegalLimitsDesktopComponent
} from './components/body/corporate/legal/legal-desktop/legal-limits-desktop/legal-limits-desktop.component';
import {
  LegalLimitsMobileComponent
} from './components/body/corporate/legal/legal-mobile/legal-limits-mobile/legal-limits-mobile.component';
import {
  WithdrawalEthereumDesktopComponent
} from './components/body/account/withdrawal/withdrawal-desktop/withdrawal-ethereum-desktop/withdrawal-ethereum-desktop.component';
import {
  DepositEthereumDesktopComponent
} from './components/body/account/deposit/deposit-desktop/deposit-ethereum-desktop/deposit-ethereum-desktop.component';
import {
  WithdrawalEthereumMobileComponent
} from './components/body/account/withdrawal/withdrawal-mobile/withdrawal-ethereum-mobile/withdrawal-ethereum-mobile.component';
import {
  DepositEthereumMobileComponent
} from './components/body/account/deposit/deposit-mobile/deposit-ethereum-mobile/deposit-ethereum-mobile.component';
import {
  EthereumDepositRulesDesktopComponent
} from './components/body/auxiliary/auxiliary-desktop/ethereum-deposit-rules-desktop/ethereum-deposit-rules-desktop.component';
import {
  EthereumDepositRulesMobileComponent
} from './components/body/auxiliary/auxiliary-mobile/ethereum-deposit-rules-mobile/ethereum-deposit-rules-mobile.component';
import {
  EthereumWithdrawalRequestDesktopComponent
} from './components/body/auxiliary/auxiliary-desktop/ethereum-withdrawal-request-desktop/ethereum-withdrawal-request-desktop.component';
import {
  EthereumWithdrawalRequestMobileComponent
} from './components/body/auxiliary/auxiliary-mobile/ethereum-withdrawal-request-mobile/ethereum-withdrawal-request-mobile.component';
import { DownloadAppComponent } from './components/body/download-app/download-app.component';
import { DownloadAppDesktopComponent } from './components/body/download-app/download-app-desktop/download-app-desktop.component';
import { DownloadAppMobileComponent } from './components/body/download-app/download-app-mobile/download-app-mobile.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides: any = {
    pinch: {enable: false},
    rotate: {enable: false}
  };
}

const HammerProvider = {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig};

// app only //

if (environment.production) {
  enableProdMode();
}

// const BaseHrefProvider = {provide: APP_BASE_HREF, useValue: '/v2'};

let providers: any[];
// if (environment.app) {
//     providers = [CookieService, HttpClientInterceptorProvider, HammerProvider, Title];
// } else {
providers = [CookieService, HttpClientInterceptorProvider, HammerProvider];
// providers = [CookieService, HttpClientInterceptorProvider, HammerProvider, BaseHrefProvider];
// }


@NgModule({
  declarations: [
    AppComponent,
    ManagerPipe,
    HeaderComponent,
    BodyComponent,
    FooterComponent,
    AccountComponent,
    AdminPanelComponent,
    AnnouncementComponent,
    AuxiliaryComponent,
    ExchangeComponent,
    HomeComponent,
    // LandingPageComponent,
    PreSaleComponent,
    AuctionComponent,
    SignUpComponent,
    SignUpSuccessfulComponent,
    UserChangePasswordComponent,

    AdvancedComponent,
    BasicComponent,

    BalanceComponent,
    DashboardComponent,
    UserInfoComponent,
    AccountAuctionComponent,
    DepositComponent,
    NavigationSideBarComponent,
    SecurityComponent,
    WithdrawalComponent,

    WithdrawalConfirmedComponent,
    WithdrawalExpiredComponent,
    WithdrawalAlreadyConfirmedComponent,

    HeaderMobileComponent,
    AdminPanelMobileComponent,
    AnnouncementMobileComponent,
    AuxiliaryMobileComponent,
    HomeMobileComponent,
    // LandingPageMobileComponent,
    PreSaleMobileComponent,
    FooterMobileComponent,

    BalanceMobileComponent,
    DashboardMobileComponent,
    DepositMobileComponent,
    WithdrawalMobileComponent,
    NavigationSideBarMobileComponent,
    SecurityMobileComponent,

    OperationHistoryMobileComponent,
    OrderHistoryMobileComponent,
    SummaryMobileComponent,
    DepositFiatMobileComponent,
    DepositCryptocurrencyMobileComponent,
    DepositHistoryMobileComponent,
    WithdrawalFiatMobileComponent,
    WithdrawalHistoryMobileComponent,
    WithdrawalCryptocurrencyMobileComponent,
    AuthenticatorMobileComponent,
    ChangePasswordMobileComponent,
    IdentityVerificationMobileComponent,
    OverviewMobileComponent,

    ArtMarketMobileComponent,
    CurrencyConverterMobileComponent,
    HowItWorksMobileComponent,
    WhatIsArtioxMobileComponent,
    ForgotPasswordMobileComponent,
    WithdrawalConfirmedMobileComponent,
    WithdrawalExpiredMobileComponent,
    WithdrawalAlreadyConfirmedMobileComponent,
    LoginMobileComponent,
    CaptchaMobileComponent,
    AddBankAccountMobileComponent,
    AddCryptoAccountMobileComponent,
    // WithdrawalRequestMobileComponent,
    FiatWithdrawalRequestMobileComponent,
    CryptoWithdrawalRequestMobileComponent,
    // DepositRulesMobileComponent,
    FiatDepositRulesMobileComponent,
    CryptoDepositRulesMobileComponent,
    TestEnvironmentWarningMobileComponent,
    AnnouncementSliderMobileComponent,
    ConnectionFailMobileComponent,
    AuctionBidReportMobileComponent,
    AuctionBidRulesMobileComponent,
    AuctionDurationRulesMobileComponent,
    AuctionShareMobileComponent,
    AuctionBidApprovedMobileComponent,
    AuctionPriceEstimationMobileComponent,
    OnePagerMobileComponent,

    AdvancedMobileComponent,
    BasicMobileComponent,

    OrderBookBalanceMobileComponent,
    PairHeaderMobileComponent,
    PairListMobileComponent,
    TradeMobileComponent,
    TradeChartMobileComponent,
    TradeHistoryMobileComponent,
    UserTradeHistoryMobileComponent,
    UserOpenOrdersMobileComponent,
    OrderBookMobileComponent,
    UserBalanceMobileComponent,
    ArtProductMobileComponent,
    ArtProductInfoMobileComponent,
    ArtProductReportListMobileComponent,
    LastTradeMobileComponent,
    BasicPairHeaderMobileComponent,
    BasicPairListMobileComponent,
    BasicTradeMobileComponent,
    BasicUserBalanceMobileComponent,
    BasicArtProductCanvasMobileComponent,

    LimitTradeMobileComponent,
    MarketTradeMobileComponent,

    SliderMobileComponent,

    // GreeterMobileComponent,
    // FaqMobileComponent,

    SignUpSuccessfulMobileComponent,
    UserChangePasswordMobileComponent,

    FormerSalesMobileComponent,
    LeftInfoMobileComponent,
    PreSaleArtProductMobileComponent,
    PreSaleArtProductCanvasMobileComponent,
    PreSaleArtProductInfoMobileComponent,
    PreSaleArtProductReportListMobileComponent,
    PreSaleHeaderMobileComponent,
    PreSaleNavigatorMobileComponent,
    PreSaleTradeMobileComponent,

    AuctionMobileComponent,

    AnnouncementCategoryListMobileComponent,
    ArtistAnnouncementListMobileComponent,
    OperationAnnouncementListMobileComponent,

    HeaderDesktopComponent,
    AdminPanelDesktopComponent,
    AnnouncementDesktopComponent,
    AuxiliaryDesktopComponent,
    HomeDesktopComponent,
    // LandingPageDesktopComponent,
    PreSaleDesktopComponent,
    FooterDesktopComponent,

    BalanceDesktopComponent,
    DashboardDesktopComponent,
    DepositDesktopComponent,
    WithdrawalDesktopComponent,
    NavigationSideBarDesktopComponent,
    SecurityDesktopComponent,

    OperationHistoryDesktopComponent,
    OrderHistoryDesktopComponent,
    SummaryDesktopComponent,
    DepositCryptocurrencyDesktopComponent,
    DepositFiatDesktopComponent,
    DepositHistoryDesktopComponent,
    WithdrawalCryptocurrencyDesktopComponent,
    WithdrawalFiatDesktopComponent,
    WithdrawalHistoryDesktopComponent,
    AuthenticatorDesktopComponent,
    ChangePasswordDesktopComponent,
    IdentityVerificationDesktopComponent,
    OverviewDesktopComponent,

    ArtMarketDesktopComponent,
    CurrencyConverterDesktopComponent,
    HowItWorksDesktopComponent,
    WhatIsArtioxDesktopComponent,
    ForgotPasswordDesktopComponent,
    WithdrawalConfirmedDesktopComponent,
    WithdrawalExpiredDesktopComponent,
    WithdrawalAlreadyConfirmedDesktopComponent,
    LoginDesktopComponent,
    CaptchaDesktopComponent,
    AddBankAccountDesktopComponent,
    AddCryptoAccountDesktopComponent,
    // WithdrawalRequestDesktopComponent,
    FiatWithdrawalRequestDesktopComponent,
    CryptoWithdrawalRequestDesktopComponent,
    // DepositRulesDesktopComponent,
    FiatDepositRulesDesktopComponent,
    CryptoDepositRulesDesktopComponent,
    AuctionBidRulesDesktopComponent,
    AuctionDurationRulesDesktopComponent,
    AuctionShareDesktopComponent,
    AuctionBidApprovedDesktopComponent,
    AuctionPriceEstimationDesktopComponent,
    AuctionBidReportDesktopComponent,
    OnePagerDesktopComponent,

    TestEnvironmentWarningDesktopComponent,
    AnnouncementSliderDesktopComponent,
    ConnectionFailDesktopComponent,

    AdvancedDesktopComponent,
    BasicDesktopComponent,

    OrderBookBalanceDesktopComponent,
    PairHeaderDesktopComponent,
    PairListDesktopComponent,
    TradeChartDesktopComponent,
    TradeHistoryDesktopComponent,
    TradeDesktopComponent,
    UserOpenOrdersDesktopComponent,
    UserTradeHistoryDesktopComponent,
    OrderBookDesktopComponent,
    UserBalanceDesktopComponent,
    ArtProductInfoDesktopComponent,
    ArtProductDesktopComponent,
    ArtProductRepostListDesktopComponent,
    BasicArtProductCanvasDesktopComponent,
    BasicPairHeaderDesktopComponent,
    BasicPairListDesktopComponent,
    BasicTradeDesktopComponent,
    BasicUserBalanceDesktopComponent,
    LastTradeDesktopComponent,

    LimitTradeDesktopComponent,
    MarketTradeDesktopComponent,

    SliderDesktopComponent,

    // GreeterDesktopComponent,
    // FaqDesktopComponent,

    SignUpSuccessfulDesktopComponent,
    UserChangePasswordDesktopComponent,

    FormerSalesDesktopComponent,
    LeftInfoDesktopComponent,
    PreSaleArtProductDesktopComponent,
    PreSaleArtProductCanvasDesktopComponent,
    PreSaleArtProductInfoDesktopComponent,
    PreSaleArtProductReportListDesktopComponent,
    PreSaleHeaderDesktopComponent,
    PreSaleNavigatorDesktopComponent,
    PreSaleTradeDesktopComponent,

    AuctionDesktopComponent,
    AuctionArtProductDesktopComponent,
    AuctionBidDesktopComponent,
    AuctionArtistBidHistoryDesktopComponent,
    AuctionCatalogDesktopComponent,
    AuctionCatalogListDesktopComponent,
    AuctionCatalogTypeDesktopComponent,

    AnnouncementCategoryListDesktopComponent,
    ArtistAnnouncementListDesktopComponent,
    OperationAnnouncementListDesktopComponent,
    AnnouncementNavigationBarDesktopComponent,
    AnnouncementNavigationBarMobileComponent,

    CorporateComponent,
    AboutComponent,
    AboutDesktopComponent,
    AboutMobileComponent,
    CareerComponent,
    CareerDesktopComponent,
    CareerMobileComponent,
    ContactComponent,
    ContactMobileComponent,
    ContactDesktopComponent,
    CorporateHeaderComponent,
    CorporateHeaderDesktopComponent,
    CorporateHeaderMobileComponent,
    AboutArtistComponent,
    AboutArtistMobileComponent,
    AboutArtistDesktopComponent,

    ServiceComponent,
    RulesComponent,
    RulesMobileComponent,
    RulesDesktopComponent,
    CommissionsComponent,
    CommissionsDesktopComponent,
    CommissionsMobileComponent,
    ServiceHeaderComponent,
    ServiceHeaderDesktopComponent,
    ServiceHeaderMobileComponent,

    // GuidebookComponent,
    // GuidebookDesktopComponent,
    // GuidebookMobileComponent,
    // AccountLevelComponent,
    // AccountLevelDesktopComponent,
    // AccountLevelMobileComponent,

    // WheelOfFortuneDesktopComponent,

    PageNotFoundComponent,
    PageNotFoundDesktopComponent,
    PageNotFoundMobileComponent,

    AuctionCatalogListMobileComponent,
    AuctionCatalogMobileComponent,
    AuctionBidMobileComponent,
    AuctionArtistBidHistoryMobileComponent,
    AuctionArtProductMobileComponent,
    AuctionCatalogTypeMobileComponent,
    AccountAuctionComponent,
    UserInfoComponent,
    AccountAuctionDesktopComponent,
    AccountAuctionMobileComponent,
    UserInfoDesktopComponent,
    UserInfoMobileComponent,
    MyBidsDesktopComponent,
    MyWonsDesktopComponent,
    MyFollowingsDesktopComponent,
    MyFollowingsMobileComponent,
    MyWonsMobileComponent,
    MyBidsMobileComponent,
    UserInfoProfileDesktopComponent,
    AuctionPaymentComponent,
    AddDeliveryAddressDesktopComponent,
    ApprovePaymentDesktopComponent,
    DeleteAddressDesktopComponent,
    AddBillingAddressDesktopComponent,
    AddDeliveryAddressMobileComponent,
    AddBillingAddressMobileComponent,

    AwardComponent,
    AwardDesktopComponent,
    AwardMobileComponent,
    LoginComponent,
    LoginPageMobileComponent,
    LoginPageDesktopComponent,

    LegalComponent,
    LegalDesktopComponent,
    LegalMobileComponent,
    LegalUserAgreementDesktopComponent,
    LegalProtectionLawPolicyDesktopComponent,
    LegalInformationSecurityPolicyDesktopComponent,
    LegalDataDeletionPolicyDesktopComponent,
    LegalKvkkApplicationFormDesktopComponent,
    LegalCookieClarificationTextDesktopComponent,
    LegalUserAgreementMobileComponent,
    LegalProtectionLawPolicyMobileComponent,
    LegalInformationSecurityPolicyMobileComponent,
    LegalDataDeletionPolicyMobileComponent,
    LegalKvkkApplicationFormMobileComponent,
    LegalCookieClarificationTextMobileComponent,
    LegalForeignConsentMobileComponent,
    LegalMarketingConsentMobileComponent,
    LegalKvkkClarificationMobileComponent,
    LegalForeignConsentDesktopComponent,
    LegalMarketingConsentDesktopComponent,
    LegalKvkkClarificationDesktopComponent,
    UserInfoProfileMobileComponent,
    AuctionPaymentDesktopComponent,
    AuctionPaymentMobileComponent,
    ApprovePaymentMobileComponent,
    DeleteAddressMobileComponent,
    ForgotPasswordComponent,
    ForgotPasswordPageMobileComponent,
    ForgotPasswordPageDesktopComponent,
    SignUpDesktopComponent,
    SignUpMobileComponent,
    UserInfoAuctionAddressDesktopComponent,
    AuctionProductInformationDesktopComponent,
    AuctionProductInformationMobileComponent,
    AccountLevelMobileComponent,
    AccountLevelDesktopComponent,
    AuctionAgreementDesktopComponent,
    AuctionAgreementMobileComponent,
    LegalDistanceSellingContractDesktopComponent,
    LegalDistanceSellingContractMobileComponent,
    LegalAuctionCancellationAndRefundAgreementDesktopComponent,
    LegalAuctionCancellationAndRefundAgreementMobileComponent,
    LegalAuctionUserAgreementMobileComponent,
    LegalAuctionUserAgreementDesktopComponent,
    LegalAuctionRulesDesktopComponent,
    LegalAuctionRulesMobileComponent,
    LegalAuctionPreliminaryInformationFormMobileComponent,
    LegalAuctionPreliminaryInformationFormDesktopComponent,
    UserAgreementDesktopComponent,
    UserAgreementMobileComponent,
    SupportComponent,
    SupportDesktopComponent,
    SupportMobileComponent,
    SupportCategoryComponent,
    SupportCategoryDesktopComponent,
    SupportCategoryMobileComponent,
    AccountVerificationComponent,
    AccountVerificationDesktopComponent,
    AccountVerificationMobileComponent,
    DelistingDesktopComponent,
    DelistingMobileComponent,
    LegalCommissionsMobileComponent,
    LegalCommissionsDesktopComponent,
    LegalLimitsDesktopComponent,
    LegalLimitsMobileComponent,
    WithdrawalEthereumDesktopComponent,
    DepositEthereumDesktopComponent,
    WithdrawalEthereumMobileComponent,
    DepositEthereumMobileComponent,
    EthereumDepositRulesDesktopComponent,
    EthereumDepositRulesMobileComponent,
    EthereumWithdrawalRequestDesktopComponent,
    EthereumWithdrawalRequestMobileComponent,
    DownloadAppComponent,
    DownloadAppDesktopComponent,
    DownloadAppMobileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    SnackBarModule,
    TooltipModule,
    HammerModule,
    ClipboardModule,
    NgOptimizedImage,
    SupportCardComponent,
    SupportDrawerComponent,


  ],
  providers,
  exports: [
    ManagerPipe,
    WithdrawalHistoryDesktopComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
