<div class="d-flex flex-column justify-content-center align-items-center"
     [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT">

  <div class="header exo">
    Sanat Yatırımına Başla
  </div>

  <div class="description barlow mt-3">
    QR kodu taratarak Artiox App'i indir.
  </div>

  <div class="qr-code barlow mt-5">
    <img src="https://framerusercontent.com/images/R5L9o4BDD1YYZGrZEylo6rTn38.png" alt="qr_code" width="324px" #qr_code
         (error)="qr_code.src = qr_code.src.replace('.webp', '.jpg')">
  </div>

  <div class="d-flex flex-row mt-5" style="width: 450px;">
    <div style="width: 180px; height: 1px; margin-top: 15px; background: #9DA5B7;border: #9DA5B7 1px solid; opacity: 0.2"></div>
    <div class="or barlow">veya</div>
    <div style="width: 180px; height: 1px; margin-top: 15px; background: #9DA5B7;border: #9DA5B7 1px solid; opacity: 0.2"></div>
  </div>

  <div class="d-flex flex-row mt-3" style="gap: 32px; margin-bottom: 8vh;">
    <!--    todo colorful badges -->
    <img ngSrc="./assets/images/google-play-store-colorful.png" alt="google-play" width="180" height="60" (click)="openGooglePlay()" style="cursor: pointer"/>
    <img ngSrc="./assets/images/apple-app-store-colorful.png" alt="app-store" width="180" height="60" (click)="openAppStore()" style="cursor: pointer"/>
  </div>
</div>
