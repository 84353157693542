import {NgModule} from '@angular/core';
import {ActivatedRoute, PreloadAllModules, Router, RouterModule, Routes} from '@angular/router';

import {ManagerService} from './services/manager.service';
import {HomeComponent} from './components/body/home/home.component';
// import {LandingPageComponent} from './components/body/landing-page/landing-page.component';
import {ExchangeComponent} from './components/body/exchange/exchange.component';
import {BasicComponent} from './components/body/exchange/basic/basic.component';
import {SignUpComponent} from './components/body/sign-up/sign-up.component';
import {AdvancedComponent} from './components/body/exchange/advanced/advanced.component';
import {AccountComponent} from './components/body/account/account.component';
import {DashboardComponent} from './components/body/account/dashboard/dashboard.component';
import {DepositComponent} from './components/body/account/deposit/deposit.component';
import {WithdrawalComponent} from './components/body/account/withdrawal/withdrawal.component';
import {BalanceComponent} from './components/body/account/balance/balance.component';
import {SecurityComponent} from './components/body/account/security/security.component';
import {PreSaleComponent} from './components/body/pre-sale/pre-sale.component';
import {UserChangePasswordComponent} from './components/body/user-change-password/user-change-password.component';
import {SignUpSuccessfulComponent} from './components/body/sign-up-successful/sign-up-successful.component';
import {WithdrawalConfirmedComponent} from './components/body/withdrawal-confirmed/withdrawal-confirmed.component';
import {WithdrawalExpiredComponent} from './components/body/withdrawal-expired/withdrawal-expired.component';
import {CorporateComponent} from './components/body/corporate/corporate.component';
import {AboutArtistComponent} from './components/body/about-artist/about-artist.component';
import {CareerComponent} from './components/body/corporate/career/career.component';
import {ServiceComponent} from './components/body/service/service.component';
import {AdminPanelComponent} from './components/body/admin-panel/admin-panel.component';
import {PageNotFoundComponent} from './components/body/page-not-found/page-not-found.component';
import {WithdrawalAlreadyConfirmedComponent} from './components/body/withdrawal-already-confirmed/withdrawal-already-confirmed.component';
import {AuctionComponent} from './components/body/auction/auction.component';
import {AccountAuctionComponent} from './components/body/account/account-auction/account-auction.component';
import {LegalComponent} from './components/body/corporate/legal/legal.component';
import {LoginComponent} from './components/body/login/login.component';
import {BodyComponent} from './components/body/body.component';
import {ForgotPasswordComponent} from './components/body/forgot-password/forgot-password.component';
import {AuctionPaymentComponent} from './components/body/account/auction-payment/auction-payment.component';
import {UserInfoComponent} from './components/body/account/user-info/user-info.component';
import {SupportComponent} from './components/body/support/support.component';
import {SupportCategoryComponent} from './components/body/support/support-category/support-category.component';
import {AccountVerificationComponent} from './components/body/account-verification/account-verification.component';
import {DownloadAppComponent} from "./components/body/download-app/download-app.component";

const routes: Routes = [
    {path: 'login', component: LoginComponent, pathMatch: 'full'},
    {path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full'},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'account-verification', component: AccountVerificationComponent},
    {path: 'forgot-password/change-password/:id', component: UserChangePasswordComponent},
    {
        path: '',
        children: [
            // redirect //
            // {path: 'v1', redirectTo: '', pathMatch: 'prefix'},
            // {path: 'v2', redirectTo: '', pathMatch: 'prefix'},
            {path: '', redirectTo: 'landing-page', pathMatch: 'full'},
            {path: 'exchange', redirectTo: 'exchange/advanced', pathMatch: 'full'},
            {path: 'account', redirectTo: 'account/dashboard', pathMatch: 'full'},

            // routing //
            {path: 'home', component: HomeComponent},
            {path: 'sss', component: SupportComponent},
            {path: 'sss/:path', component: SupportCategoryComponent},

            // {path: 'landing-page', component: LandingPageComponent},
            // {path: 'landing-page?redirect=false', component: LandingPageComponent},
            // {path: 'one-pager', component: LandingPageComponent},
            {path: 'landing-page', component: HomeComponent},
            {path: 'one-pager', component: HomeComponent},
            {path: 'presale', component: PreSaleComponent},
            {path: 'presale/:pair', component: PreSaleComponent},
            {
                path: 'exchange', component: ExchangeComponent,
                children: [
                    {path: 'basic', component: BasicComponent},
                    {path: 'basic/:pair', component: BasicComponent},
                    {path: 'advanced', component: AdvancedComponent},
                    {path: 'advanced/:pair', component: AdvancedComponent}
                ]
            },
            {
                path: 'auction', component: AuctionComponent,
                children: [
                    {path: 'catalog', component: AuctionComponent},
                    {path: 'catalog/:auction_catalog_hash', component: AuctionComponent},
                    {path: 'auction_product', component: AuctionComponent},
                    {path: 'auction_product/:auction_product_hash', component: AuctionComponent}
                ]
            },
            {
                path: 'account', component: AccountComponent,
                children: [
                    {path: 'dashboard', component: DashboardComponent},
                    {path: 'user-info', component: UserInfoComponent},
                    {path: 'deposit', component: DepositComponent},
                    {path: 'withdrawal', component: WithdrawalComponent},
                    {path: 'auction', component: AccountAuctionComponent},
                    {path: 'balance', component: BalanceComponent},
                    {path: 'security', component: SecurityComponent}
                ]
            },
            {path: 'payment/auction/:auction_product_hash', component: AuctionPaymentComponent},
            {
                path: 'corporate', component: CorporateComponent,
                children: [
                    {path: 'general-announcements', component: BasicComponent},
                    {path: 'general-announcements/:topic', component: BasicComponent}
                ]
            },
            {path: 'corporate/:category', component: CorporateComponent},
            {
                path: 'about-artist', component: AboutArtistComponent,
                children: [
                    {path: ':artist_announcement_index', component: AboutArtistComponent},
                ]
            },
            {path: 'career', component: CareerComponent, pathMatch: 'full'},
            {path: 'legal/:path', component: LegalComponent},
            {path: 'legal', redirectTo:'legal/user-agreement'},
            {path: 'announcements', redirectTo: '/corporate/announcements', pathMatch: 'full'},
            {path: 'service', redirectTo: '/service/rules', pathMatch: 'full'},
            {path: 'service/:page', component: ServiceComponent},
            {path: 'sign-up/successful', component: SignUpSuccessfulComponent},
            {path: 'withdrawal-confirmed', component: WithdrawalConfirmedComponent},
            {path: 'withdrawal-already-confirmed', component: WithdrawalAlreadyConfirmedComponent},
            {path: 'withdrawal-expired', component: WithdrawalExpiredComponent},
            {path: 'download-app', component: DownloadAppComponent},
            // {path: 'award', component: AwardComponent},
            {path: '404', component: PageNotFoundComponent},
            {path: '**', redirectTo: '404'}
        ],
        component: BodyComponent
    },
];

RouterModule.forRoot(
    routes,
    {
        preloadingStrategy: PreloadAllModules
    }
);

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(private managerService: ManagerService, private router: Router) {
        this.managerService.admin_logged_in.subscribe((event) => {
            if (event && !routes.find(x => x.path === 'admin-panel')) {
                this.addAdminPanel();
            }
            if (!event && routes.find(x => x.path === 'admin-panel')) {
                this.removeAdminPanel();
            }
        });
    }

    addAdminPanel(): void {
        const outerIndex = routes.findIndex((element) => element.path === '');
        // @ts-ignore
        routes[outerIndex].children.splice(6, 0, {path: 'admin-panel', component: AdminPanelComponent});
        this.router.resetConfig(routes);
    }

    removeAdminPanel(): void {
        const outerIndex = routes.findIndex((element) => element.path === '');
        // @ts-ignore
        routes[outerIndex].children = routes[outerIndex].children.filter(x => x.path !== 'admin-panel');
        this.router.resetConfig(routes);
    }
}
