import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-header-mobile',
    templateUrl: './header-mobile.component.html',
    styleUrls: ['./header-mobile.component.css'],
    animations: [
        trigger('navbarMenuState', [
            state('open', style({
                width: '160px'
            })),
            state('close', style({
                width: '0'
            })),
            transition('open=>close', [
                animate('500ms')]),
            transition('close=>open', [
                animate('500ms')]),
        ]),
    ]
})
export class HeaderMobileComponent implements OnInit, OnDestroy {
    subscription_list: Subscription[] = [];

    constructor(public managerService: ManagerService, public element: ElementRef) {
        this.element.nativeElement.addEventListener('click', (el: any) => {
            if (el.target.id === 'account-navbar-menu') {
                this.managerService.account_navbar_menu_display_status = false;
            }
        });
    }

    ngOnInit(): void {
    }

    accountButtonClick(): void {
        this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;
    }

    // notificationButtonClick(): void {
    //     if (this.managerService.notification_menu_state === 'open') {
    //         this.managerService.notification_menu_state = 'close';
    //     } else {
    //         this.managerService.notification_menu_state = 'open';
    //     }
    // }

    headerIconClick(): void {
        if (this.managerService.navigationService.navigation_history.length === 0) {
            if (this.managerService.logged_in || !this.managerService.utilityService.checkReleaseUrl()) {
                this.managerService.navigationManager({url: '/home', current_component_index: 0});
            } else {
                this.managerService.navigateToTrArtiox();
            }
            this.managerService.navigationService.clearNavigationHistory();
        } else {
            this.managerService.navigationService.reverseNavigation();
        }
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

}
